import React from 'react'

export const IconDate = () => {
  return (
    <svg
      width='22'
      height='12'
      viewBox='6 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2H10.75V0.75C10.75 0.335938 10.4141 0 10 0C9.58594 0 9.25 0.335938 9.25 0.75V2H4.75V0.75C4.75 0.335938 4.41406 0 4 0C3.58594 0 3.25 0.335938 3.25 0.75V2H2C0.895437 2 0 2.89538 0 4V14C0 15.1046 0.895437 16 2 16H12C13.1046 16 14 15.1046 14 14V4C14 2.89538 13.1046 2 12 2ZM12 14.5H2C1.72431 14.5 1.5 14.2758 1.5 14V6H12.5V14C12.5 14.2758 12.2757 14.5 12 14.5Z'
        fill='#5A646E'
      />
    </svg>
  )
}
