import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Release } from 'contexts'

import { Loading } from 'components/atoms'
import { Container } from 'components/pages/RegisterCompany/style'

import { EditHours } from './form'

export const Preload = () => {
  const { getById, data } = useContext(Release.LaunchHours.Context)

  const { id } = useParams()

  useEffect(() => {
    getById(Number(id))
  }, [id])

  return (
    <>
      {!data.justification ? (
        <Container>
          <Loading />
        </Container>
      ) : (
        <EditHours
          {...{
            date_release: data.date_release,
            hour_quantity: data.hour_quantity,
            justification: data.justification
          }}
        />
      )}
    </>
  )
}
