import { DefaultMetaProps } from './types'

const META: DefaultMetaProps = {
  order: 'ASC',
  search: '',
  orderField: 'id',
  pagination: {
    current_page: 1,
    last_page: 1
  },
  project_id: null,
  end_date: '',
  launch_date: ''
}

const FILTER_OPTIONS = {
  projects: []
}

export default { META, FILTER_OPTIONS }
