import { UseFormReturn } from 'react-hook-form'

import { toast } from '@stardust-ds/react'

import { FormProps } from '../Professional'

export const clearInputFields: (
  Context: UseFormReturn<FormProps>
) => void = (Context: UseFormReturn<FormProps>) => {
  const { setValue } = Context
  setValue('projects.selected.fullTime', '')
  setValue('projects.selected.partTime', '')
  setValue('projects.selected.input', '')
  setValue('job_attribution', '')
  setValue('projects.selected.project', null)
}

export const verifyValues = (Context: UseFormReturn<FormProps>) => {
  const { watch, setError } = Context
  const { projects } = watch()
  const project = watch('projects.selected.project') as any
  const start_data = watch('projects.selected.input')
  const hours =
    watch('projects.selected.fullTime') ||
    watch('projects.selected.partTime')
  const job = watch('job_attribution') as any
  const job_attribution = job?.label
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/

  if (!project || !job_attribution || !hours) {
    toast({
      type: 'warning',
      title: 'Atenção',
      description:
        'Há campos vazios, por favor preencha todos os campos'
    })
    return false
  }

  if (!projects.selected.project) {
    return false
  }

  if (!dateRegex.test(start_data)) {
    toast({
      type: 'warning',
      title: 'Atenção',
      description:
        'Ano de início de alocação inválido. Insira um ano com 4 dígitos.'
    })
    return false
  }

  if (
    Number(projects?.selected?.fullTime) > 160 ||
    Number(projects?.selected?.fullTime) < 0
  ) {
    setError('projects.selected.fullTime', {
      message: 'Limite de 160h por mês.',
      type: 'required'
    })
    return false
  }
  if (
    Number(projects?.selected?.partTime) > 80 ||
    Number(projects?.selected?.partTime) < 0
  ) {
    setError('projects.selected.partTime', {
      message: 'Limite de 80h por mês.',
      type: 'required'
    })
    setError('projects.selected.fullTime', { message: '' })
    return false
  }

  setError('projects.selected.fullTime', { message: '' })
  setError('projects.selected.partTime', { message: '' })
  return true
}

export const handleAddMockProject = (
  Context: UseFormReturn<FormProps>
) => {
  const { watch, setValue } = Context
  if (!verifyValues(Context)) {
    toast({
      type: 'warning',
      title: 'Atenção',
      description: 'Corrija os erros antes de adicionar o projeto.'
    })
    return
  }
  const project = watch('projects.selected.project') as any
  const start_data = watch('projects.selected.input')
  const hours =
    watch('projects.selected.fullTime') ||
    watch('projects.selected.partTime')
  const job = watch('job_attribution') as any
  const job_attribution = job?.label
  const { name, id } = project?.label?.value || {}
  const extra_hours_estimated = 0
  const extra_hours_performed = 0
  const hours_mounths_performed = 0

  if (!project) {
    return
  }

  const payload = {
    id: Number(id),
    name: name || '',
    date_start: '',
    hours_mounths_estimated: parseInt(hours),
    date_start_allocation: start_data,
    date_end_allocation: '',
    job_attribution: job_attribution,
    status: true,
    extra_hours_estimated: extra_hours_estimated,
    extra_hours_performed: extra_hours_performed,
    hours_mounths_performed: hours_mounths_performed
  }
  const previous_projects = watch('projects.attachment', [])
  setValue('projects.attachment', [...previous_projects, payload])
}
