import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Radio } from '@stardust-ds/react'

import { Inputs, Selects } from 'components/atoms'
import { formatCurrency } from 'components/utils/formatCurrent'
import { GenerateCurrencyMask } from 'components/utils/OptionsAplication'

import { KEYS, MASKER } from '../constants'
import { ContainerRow } from '../style'
import type { FormProps } from '../types'

export const ExtraHours = () => {
  const {
    register,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext<FormProps>()

  const disableExtraHours =
    watch('job_type')?.value === KEYS.CONTRACT_TYPE.FREELANCER

  const showLimitSection = watch('extra_hour_activated')

  const hoursFixed = watch('variable1')
  const valuePayment = watch('variable2')
  const paymentFixed = Number(valuePayment) / 100
  const formattPaymentFixed = GenerateCurrencyMask(valuePayment || '')

  const extraHourValue = (paymentFixed / hoursFixed).toFixed(2)
  const valueExtraHour =
    extraHourValue === 'NaN'
      ? 0
      : GenerateCurrencyMask(extraHourValue)

  useEffect(() => {
    hoursFixed <= 0
      ? setError('variable1', {
          type: 'manual',
          message: 'Valor Horas/mês deve ser maior que zero(0)'
        })
      : setError('variable1', {
          type: 'manual',
          message: ''
        })
  }, [hoursFixed])

  return (
    <>
      <ContainerRow>
        <h3>Horas Extras</h3>
      </ContainerRow>
      <ContainerRow gap='3rem'>
        <Selects.WithCheckbox
          options={[
            {
              label: 'Permitir horas extras',
              input: (
                <Radio
                  id='1'
                  onClick={() =>
                    setValue('extra_hour_activated', true)
                  }
                  checked={watch('extra_hour_activated') === true}
                  disabled={disableExtraHours}
                />
              ),
              active: watch('extra_hour_activated')
            },
            {
              label: 'Não permitir horas extras',
              input: (
                <Radio
                  id='2'
                  onClick={() =>
                    setValue('extra_hour_activated', false)
                  }
                  checked={watch('extra_hour_activated') === false}
                  defaultChecked
                />
              ),
              active: watch('extra_hour_activated') === false
            }
          ]}
        />
      </ContainerRow>
      {showLimitSection && (
        <ContainerRow gap='1rem'>
          <Inputs.Default
            {...register('variable1')}
            type='number'
            width={220}
            placeholder='Horas'
            value={watch('variable1')}
            label='Variável 1 (Horas mensais)'
            error={errors.variable1?.message}
          />
          <Inputs.Default
            {...register('variable2', {
              setValueAs: (value) => {
                return value ? value.replace(/[^\d]/g, '') : ''
              }
            })}
            value={formattPaymentFixed}
            type='text'
            iconLeft='R$'
            placeholder='00,00'
            width={220}
            label='Variável 2 (Salário fixo)'
          />
          <Inputs.Default
            {...register('extra_hour_value', {
              setValueAs: (value) => {
                if (typeof value === 'string') {
                  return value.replace(/[^\d]/g, '')
                } else {
                  return ''
                }
              }
            })}
            error={errors.extra_hour_value?.message}
            label='Valor da hora extra'
            iconLeft='R$'
            value={valueExtraHour}
            bColor='white'
            type='text'
          />
        </ContainerRow>
      )}
    </>
  )
}
