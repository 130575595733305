import React from 'react'

export const IconReleaseHours = ({
  width = '18px',
  height = '24px',
  color = '#3B454F',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='5.5 1 24 37'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.0002 10.3334C15.0002 9.77921 15.446 9.33337 16.0002 9.33337C16.5543 9.33337 17.0002 9.77921 17.0002 10.3334V15.4667L20.5543 17.8334C21.0127 18.1417 21.1377 18.7625 20.796 19.2209C20.5252 19.6792 19.9043 19.8042 19.446 19.4625L15.446 16.7959C15.1668 16.6459 15.0002 16.3334 15.0002 15.9625V10.3334ZM16.0002 5.33337C21.8918 5.33337 26.6668 10.1084 26.6668 16C26.6668 21.8917 21.8918 26.6667 16.0002 26.6667C10.1085 26.6667 5.3335 21.8917 5.3335 16C5.3335 10.1084 10.1085 5.33337 16.0002 5.33337ZM7.3335 16C7.3335 20.7875 11.2127 24.6667 16.0002 24.6667C20.7877 24.6667 24.6668 20.7875 24.6668 16C24.6668 11.2125 20.7877 7.33337 16.0002 7.33337C11.2127 7.33337 7.3335 11.2125 7.3335 16Z'
        fill={color}
      />
      <path
        d='M23.3982 21.5005L23.3969 21.5005L23.3982 21.5005ZM19.2929 19.2929C17.2453 21.3405 17.2453 24.6595 19.2929 26.7071C21.3404 28.7547 24.6595 28.7547 26.7071 26.7071C28.7546 24.6595 28.7546 21.3405 26.7071 19.2929C24.6595 17.2453 21.3404 17.2453 19.2929 19.2929Z'
        fill={color}
        stroke='white'
        stroke-width='2'
      />
    </svg>
  )
}
