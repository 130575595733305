import React from 'react'

import {
  ContainerShelf,
  ContainerShelfColumn,
  Text,
  TextShelf
} from '../style'
import { ShelfProps } from '../types'

export const ShelfSome = ({ props, config }: ShelfProps<any>) => {
  const { name, hours_mounths_estimated } = props

  return (
    <ContainerShelf template={config.template}>
      <ContainerShelfColumn justify='start'>
        <TextShelf>{name}</TextShelf>
      </ContainerShelfColumn>
      <ContainerShelfColumn justify='start' left='0.5em'>
        <Text>{hours_mounths_estimated}</Text>
      </ContainerShelfColumn>
    </ContainerShelf>
  )
}
