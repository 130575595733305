import { ChangeEvent, useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, DatePicker, Input } from '@stardust-ds/react'
import { Select } from '@stardust-ds/react'
import { Release } from 'contexts'

import { convertDateFormat } from 'components/organisms/Forms/Team/logic'
import { formatDatePicker } from 'components/utils/formatValueDatePicker'

import { IFormProps } from '../form'

export const Dividers = () => {
  const { jobs, projects, data } = useContext(
    Release.LaunchHours.Context
  )

  const { watch, setValue } = useFormContext<IFormProps>()
  const { date_release, hour_quantity } = watch()

  const dateRelease = useMemo(() => {
    return formatDatePicker(new Date(date_release))
  }, [])

  return (
    <Box
      width='100%'
      height='6em'
      bgColor='#F2F5F8'
      bRadius='sm'
      display='flex'
      bWidth='hairline'
      bColor='#CCD1D6'
      flexDirection='row'
      alignItems='center'
      px='xs'
      gap={10}
    >
      <Select
        value={projects.find(
          (prop) => Number(prop.value) === data.project_id
        )}
        options={projects}
        width='31%'
        label='Projeto'
        disabled
        placeholder='Projetos'
      />
      <Select
        value={jobs.find(
          (prop) => Number(prop.value) === data.job.id
        )}
        options={jobs}
        disabled
        width='30%'
        label='Papel'
        placeholder='Cargos'
      />
      <Input
        width={100}
        label='Qtd de Horas'
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue('hour_quantity', Number(e.target.value))
        }
        value={hour_quantity}
      />
      <DatePicker
        inputStartProps={{
          label: 'Período',
          width: '8em'
        }}
        value={{ start: dateRelease, end: '' }}
        onChange={(date) =>
          date.length > 0 &&
          setValue(
            'date_release',
            convertDateFormat(String(date[0]), '-')
          )
        }
      />
    </Box>
  )
}
