import { useContext, useRef } from 'react'

import { List } from 'contexts'
import { PendingProps } from 'contexts/List/ExtraHoursRh/types'

import { Badge } from 'components/atoms'
import {
  IHandleModalPropsExtrasHoursRh,
  Modal
} from 'components/molecules/Modais'
import { formatDate } from 'components/utils/formatDate'

import {
  ContainerShelf,
  ContainerShelfColumn,
  Text,
  TextProfessional,
  TextDisabled
} from '../style'
import { ShelfProps } from '../types'

export const Shelf = ({
  props,
  config
}: ShelfProps<PendingProps>) => {
  const { handleDetails } = useContext(List.ExtraHoursRh.Context)

  const {
    id,
    user_name,
    status_name,
    launch_date,
    hour_quantity,
    project_name,
    status_id
  } = props

  const modalRef = useRef<IHandleModalPropsExtrasHoursRh>(null)

  const handleModal = async () => {
    await handleDetails(id)
    modalRef.current?.open()
    return
  }

  const notAllowed = status_name != 'Pendente - RH'

  return (
    <>
      <ContainerShelf template={config.template}>
        {status_name === 'Pendente - RH' ? (
          <ContainerShelfColumn onClick={handleModal}>
            <TextProfessional>{user_name}</TextProfessional>
          </ContainerShelfColumn>
        ) : (
          <ContainerShelfColumn>
            <Text>
              <TextDisabled disabled={notAllowed}>
                {user_name}
              </TextDisabled>
            </Text>
          </ContainerShelfColumn>
        )}
        <ContainerShelfColumn>
          <Text>{hour_quantity}h</Text>
        </ContainerShelfColumn>

        <ContainerShelfColumn>
          <Text>{project_name}</Text>
        </ContainerShelfColumn>

        <ContainerShelfColumn>
          <Text>{formatDate(launch_date)}</Text>
        </ContainerShelfColumn>

        <ContainerShelfColumn width='auto'>
          <Badge.Hours
            status={{ id: status_id, name: status_name }}
          />
        </ContainerShelfColumn>
      </ContainerShelf>
      <Modal.OvertimeReleaseRh
        ref={modalRef}
        text={'Lançamento' + ' #' + id}
        placeholder='Lançamento'
        EventOne={() => handleDetails(id)}
      />
    </>
  )
}
