import { useContext, useMemo } from 'react'

import { List } from 'contexts'

import { Loading } from 'components/atoms'
import { TableHeader } from 'components/molecules'
import { NotFoundFilter } from 'components/organisms/Filters/NotFoundFilter'

import { LoadingWrapper, Main, NotFoundWrapper } from '../style'
import { GRID_TEMPLATE, HEADERS } from './constants'
import { Shelf } from './shelf'

export const PostHoursReleases = () => {
  const { hoursReleases, isLoading, handleOrder } = useContext(
    List.PostHoursReleases.Context
  )

  const POPOVER_OPTIONS = (id: number) => []

  const Table = useMemo(() => {
    if (isLoading)
      return (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )
    if (hoursReleases.length === 0)
      return (
        <NotFoundWrapper>
          <NotFoundFilter text='Nenhuma hora lançada.' />
        </NotFoundWrapper>
      )

    return hoursReleases.map((props) => (
      <Shelf
        key={props.id}
        config={{
          template: GRID_TEMPLATE,
          options: POPOVER_OPTIONS(props.id)
        }}
        {...{ props }}
      />
    ))
  }, [isLoading])

  return (
    <Main>
      <TableHeader
        headers={HEADERS}
        template={GRID_TEMPLATE}
        handleOrder={handleOrder}
      />
      {Table}
    </Main>
  )
}
