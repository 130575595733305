import { useContext, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { toast } from '@stardust-ds/react'
import { List } from 'contexts'

import { Button } from 'components/atoms'
import {
  IHandleModalPropsCommission,
  IHandleModalPropsConfirmationList,
  Modal
} from 'components/molecules/Modais'
import { FormOrderProps } from 'components/organisms'
import OnPrice from 'components/organisms/Tables/OrderFormTable/OnPrice'
import { AuthTemplate } from 'components/templates'

import OrderForm from '../OrdeForm'
import {
  ContainerButton,
  ContainerCompany,
  ContainerFixed
} from './style'

const RegisterOrderOfServiceWrap = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    navigateTo,
    professionalsHaveCommission,
    selectSendProfessionals
  } = useContext(List.OrderOfServiceprofessionalOS.Context)

  const modalRefCommissionList =
    useRef<IHandleModalPropsCommission>(null)
  const modalRfConfirmationList =
    useRef<IHandleModalPropsConfirmationList>(null)

  const methods = useForm<FormOrderProps['OrderOfService']>({
    defaultValues: {},
    shouldFocusError: true
  })

  const commissionedPresent = selectSendProfessionals.some(
    (professional) => professional.isCommission
  )

  const closeCommissionModal = () => {
    if (!commissionedPresent && modalRefCommissionList.current) {
      modalRefCommissionList.current.close()
    }
  }

  const closeConfirmationModal = () => {
    if (
      (commissionedPresent ||
        professionalsHaveCommission.length === 0) &&
      selectSendProfessionals.length === 0 &&
      !isLoading
    ) {
      modalRfConfirmationList.current?.close()
    }
  }

  const handleProfessionals = async () => {
    setIsLoading(true)

    const unfilledProfessionals = professionalsHaveCommission.filter(
      (professional) => professional.commission === undefined
    )

    const hasUnfilledCommission = unfilledProfessionals.length > 0

    if (hasUnfilledCommission) {
      modalRefCommissionList.current?.open()
    }
    setIsLoading(false)
    return hasUnfilledCommission
  }

  const handleCreateOs = async () => {
    if (selectSendProfessionals.length === 0) {
      toast({
        type: 'warning',
        title: 'Aviso.',
        description: 'Não há profissionais selecionados.',
        position: 'bottom-right'
      })
      return
    }
    const hasUnfilledCommission = await handleProfessionals()

    if (!hasUnfilledCommission) {
      modalRfConfirmationList.current?.open()
    }
  }

  useEffect(() => {
    closeCommissionModal()
    closeConfirmationModal()
  }, [
    professionalsHaveCommission,
    selectSendProfessionals,
    isLoading
  ])

  return (
    <>
      <AuthTemplate>
        <FormProvider {...methods}>
          <form>
            <OrderForm />
            <ContainerFixed>
              <ContainerCompany>
                <OnPrice />
              </ContainerCompany>
              <ContainerButton>
                <Button.Updade
                  onSave={handleCreateOs}
                  onCancel={() => navigateTo('/orderOfService')}
                  type='button'
                  saveButtonName='Criar O.S'
                  cancelButtonName='cancelar'
                  disabled={isLoading}
                  isLoading={isLoading}
                />
              </ContainerButton>
            </ContainerFixed>
          </form>
        </FormProvider>
      </AuthTemplate>

      <Modal.Commission
        ref={modalRefCommissionList}
        placeholder='Confirmar Comissões'
        text='Confirmar Comissões'
      />
      <Modal.ConfirmationList
        ref={modalRfConfirmationList}
        text='Confirmar Profissionais'
      />
    </>
  )
}
export default RegisterOrderOfServiceWrap
