import React from 'react'

export const IconRightArrow = () => {
  return (
    <svg
      width='17'
      height='14'
      viewBox='0 0 17 14'
      fill='none'
      cursor='pointer'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.3056 0.379198L16.5915 6.37941C16.7612 6.54124 16.8572 6.7656 16.8572 6.99997C16.8572 7.23434 16.7612 7.4587 16.5915 7.62053L10.3056 13.6207C9.96297 13.9466 9.42052 13.9344 9.09351 13.5917C8.7665 13.2524 8.77875 12.7078 9.12251 12.3796L13.8608 7.85714L1.71435 7.85714C1.24112 7.85714 0.857177 7.4732 0.857177 6.99997C0.857177 6.52674 1.24112 6.1428 1.71435 6.1428L13.8608 6.1428L9.12254 1.62031C8.77878 1.2933 8.7665 0.74864 9.09351 0.408235C9.42052 0.0655796 9.96297 0.0532938 10.3056 0.379198Z'
        fill='#5A646E'
      />
    </svg>
  )
}
