import { useContext } from 'react'

import { DatePicker } from '@stardust-ds/react'
import { List } from 'contexts'

import { IconGlass, Inputs } from 'components/atoms'
import { convertDateFormat } from 'components/organisms/Forms/Team/logic'

import { Container, Main } from '../style'

export const UserNotes = () => {
  const {
    meta,
    handleDateReference,
    handleSearch,
    handleEmissionNf
  } = useContext(List.UserNotes.Context)

  const { search } = meta

  return (
    <Main>
      <Container gap='1em'>
        <Inputs.Default
          value={search}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Poppins, sans-serif'
          }}
          iconLeft={<IconGlass />}
          placeholder='Buscar...'
          onChange={(e) => handleSearch(e.target.value)}
        />
        <DatePicker
          onChange={(date) =>
            date.length > 0 &&
            handleDateReference(
              convertDateFormat(String(date[0])),
              convertDateFormat(String(date[1]))
            )
          }
          inputStartProps={{
            placeholder: 'Início do período'
          }}
          hasEndDate
          inputEndProps={{
            placeholder: 'Fim do periodo'
          }}
          weekDays={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']}
        />
        <DatePicker
          inputStartProps={{
            placeholder: 'Emissão da NF'
          }}
          onChange={(e) =>
            handleEmissionNf(convertDateFormat(String(e[0])))
          }
        />
      </Container>
    </Main>
  )
}
