import type { HeaderProps } from 'components/molecules'

export const HEADERS: HeaderProps[] = [
  { field: 'professional_name', label: 'Profissional', on: true },
  { field: 'id', label: 'Código' },
  { field: 'project.id', label: 'Projeto', on: true },
  { field: 'professional_job', label: 'Papel', on: true },
  { field: 'launch_date', label: 'Semana', on: true },
  { field: 'hour_quantity', label: 'QTD Horas', on: true },
  { field: 'created_at', label: 'Lançamento', on: true }
]

export const GRID_TEMPLATE = '1fr 1fr 1fr 1.3fr 1fr .9fr 1fr'
