import React from 'react'

export const IconLogout = () => {
  return (
    <svg
      width='16'
      height='15'
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.9978 13.4221C9.9978 13.8431 10.3323 14.1818 10.7481 14.1818H12.9989C14.6564 14.1818 16 12.8212 16 11.1429V3.03896C16 1.36057 14.6564 0 12.9989 0H10.7481C10.3323 0 9.9978 0.339984 9.9978 0.75974C9.9978 1.17918 10.3323 1.51948 10.7481 1.51948H12.9989C13.8242 1.51948 14.4995 2.20325 14.4995 3.03896V11.1429C14.4995 11.9786 13.8242 12.6623 12.9989 12.6623H10.7481C10.3323 12.6623 9.9978 13.0011 9.9978 13.4221ZM0.197342 6.57175L4.17067 2.26656C4.45496 1.95988 4.9297 1.9462 5.23075 2.23392C5.53261 2.52079 5.54711 3.00158 5.26298 3.30737L2.48255 6.33117H10.276C10.6637 6.33117 10.9982 6.67305 10.9982 7.09091C10.9982 7.50877 10.6621 7.85065 10.276 7.85065H2.51069L5.32172 10.8757C5.60601 11.1813 5.59135 11.662 5.28949 11.9491C5.11477 12.0862 4.93032 12.1558 4.77402 12.1558C4.57473 12.1558 4.37543 12.0757 4.22788 11.9165L0.25455 7.61127C-0.0746326 7.31883 -0.0746326 6.86299 0.197342 6.57175Z'
        fill='#3B454F'
      />
    </svg>
  )
}

export default IconLogout
