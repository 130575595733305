import { HeaderProps } from 'components/molecules'

export const HEADERS: HeaderProps[] = [
  { field: 'id', label: 'Codigo', on: true },
  { field: 'launch_date', label: 'Período Inicial' },
  { field: 'end_date', label: 'Período Final' },
  { field: 'hour_quantity', label: 'Horas' },
  { field: 'project_id', label: 'Projeto' },
  { field: 'status.id', label: 'Status' }
]

export const GRID_TEMPLATE = '0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.7fr'
