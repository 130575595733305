import { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { toast } from '@stardust-ds/react'
import { List } from 'contexts'
import { HoursReleaseProps } from 'contexts/List/PostHoursReleases/types'
import { theme } from 'styles'

import { Button, Loading } from 'components/atoms'
import { ButtonGeneric } from 'components/atoms/ButtonGeneric'
import { Form, FormHoursReleaseProps } from 'components/organisms'
import { AuthTemplate, CreateTemplate } from 'components/templates'

import api from 'api'
import { routes } from 'routes'

const RegisterHoursReleasesWrap = () => {
  const { addForm, mergedFormData } = useContext(
    List.PostHoursReleases.Context
  )
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const methods = useForm<FormHoursReleaseProps['PostHoursRelease']>({
    defaultValues: {},
    shouldFocusError: true,
    mode: 'onBlur'
  })

  async function onSubmit(
    data: FormHoursReleaseProps['PostHoursRelease']
  ) {
    const isValid = mergedFormData.every(
      (item) =>
        item?.project_id &&
        item?.user_id &&
        item?.job_id &&
        item?.date_week &&
        item?.hour_quantity &&
        item?.date_release
    )

    if (!isValid) {
      toast({
        type: 'warning',
        title: 'Atenção',
        description:
          'Preencha todos os campos para poder cadastrar as horas semanais.',
        position: 'bottom-right'
      })
      return
    }

    const hours_releases = mergedFormData.map((item) => ({
      project_id: item?.project_id,
      user_id: item?.user_id,
      job_id: item?.job_id,
      date_week: item?.date_week,
      hour_quantity: item?.hour_quantity,
      date_release: item?.date_release,
      justification: item?.justification
    }))
    try {
      const postData = { hours_releases }
      if (hours_releases.length > 0) {
        await api.post(
          routes.hours.castingHours.registerHours,
          postData
        )
        toast({
          type: 'success',
          title: 'Sucesso.',
          description: 'Horas cadastradas com sucesso!',
          position: 'bottom-right'
        })
        navigate('/postHoursReleases')
      }
      setIsSaving(false)
    } catch (error) {
      setIsSaving(false)
      toast({
        type: 'error',
        title: 'Erro ao cadastrar horas.',
        description: 'Horas não pôde ser cadastrada!',
        position: 'bottom-right'
      })
      console.error(error)
    }

    return hours_releases
  }

  const handleSave = async () => {
    setIsSaving(true)
    methods.handleSubmit(onSubmit)
    setIsSaving(false)
  }

  const handleAddForm = async () => {
    await addForm()
  }

  function handleCancel() {
    navigate('/postHoursReleases')
  }

  return (
    <>
      <AuthTemplate>
        <CreateTemplate title={'Lançar horas'} arrow>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form.PostHoursRelease />

              <div style={{ marginBottom: '2em' }}>
                <ButtonGeneric
                  Text='Adicionar Projeto'
                  bgColor={theme.brand.color.status.neutral1}
                  color='white'
                  width='15em'
                  bRadius='500px'
                  height='3.5em'
                  type='button'
                  onClick={handleAddForm}
                />
              </div>
              <Button.Updade
                onSave={handleSave}
                onCancel={handleCancel}
                saveButtonName='Salvar'
                cancelButtonName='cancelar'
              />
            </form>
          </FormProvider>
        </CreateTemplate>
      </AuthTemplate>
    </>
  )
}

export default RegisterHoursReleasesWrap
