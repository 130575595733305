import { useFormContext } from 'react-hook-form'

import { Select } from '@stardust-ds/react'

import { skillsTools } from 'components/utils/skillsTools'

import { ContainerRow } from '../style'
import type { FormProps } from '../types'

export const Tolls = () => {
  const { register, watch, setValue } = useFormContext<FormProps>()
  const { tools } = watch()
  const optionsSkills = tools
    ? tools.map((item: any) =>
        skillsTools.find((obj) => obj.label === item)
      )
    : null

  return (
    <>
      <ContainerRow
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <h4>Ferramentas</h4>
        <Select
          {...register('tools')}
          options={skillsTools}
          value={optionsSkills as any}
          onSelect={(selectedOptions) => {
            if (Array.isArray(selectedOptions)) {
              const selectedSkills = selectedOptions.map(
                (option) => option.label
              )
              const valueOptionsTools =
                selectedOptions.length > 0 ? selectedSkills : null

              setValue('tools', valueOptionsTools as any)
            }
          }}
          onClear={() => setValue('tools', null)}
          width='100%'
          searchable
          multiSelect
          clearable={false}
          placeholder='Digite ferramentas'
        />
      </ContainerRow>
    </>
  )
}
