import { OrderBy } from './OrderBy'

export { IconArrow } from './IconArrow'
export { IconBottomVerticalArrow } from './IconBottomVerticalArrow'
export { IconArrowPageRegistration } from './IconArrowPageRegistration'
export { IconArrowsPosition } from './IconArrowsPosition'
export { IconCompanies } from './IconCompanies'
export { IconDark } from './IconDark'
export { IconEye } from './IconEye'
export { IconEyeSlash } from './IconEyeSlash'
export { IconGlass } from './IconGlass'
export { IconHome } from './IconHome'
export { IconHours } from './IconHours'
export { IconLeftArrow } from './IconLeftArrow'
export { IconUbistart } from './IconUbistart'
export { IconUser } from './IconUser'
export { IconLua } from './IconLua'
export { IconLight } from './IconLight'
export { IconLogout } from './IconLogout'
export { IconNotes } from './IconNotes'
export { IconProfessional } from './IconProfessional'
export { IconProjects } from './IconProjects'
export { IconSetting } from './IconSetting'
export { IconServices } from './IconServices'
export { IconReports } from './IconReports'
export { IconThreePoints } from './IconThreePoints'
export { IconRightArrow } from './IconRightArrow'
export { IconClose } from './IconClose'
export { IconReleaseHours } from './IconReleaseHours'
export { IconReleaseNotes } from './IconReleaseNotes'
export { IconWarn } from './IconWarn'
export { IconDate } from './IconDate'
export { IconExport } from './IconExport'
export { IconCloseCircle } from './IconCloseCircle'
export { IconAlert } from './IconAlert'
export { IconAlertAccept } from './IconAlertAccept'
export { IconPayments } from './IconPayments'
export const Icon = Object.assign({}, { OrderBy })
