import { List } from 'contexts'

import { Filter, Table } from 'components/organisms'
import { AuthTemplate, ListTemplate } from 'components/templates'

const MonthHours = () => {
  return (
    <AuthTemplate>
      <List.MouthHours.Provider>
        <ListTemplate title='Lançamento de Horas'>
          <Filter.MonthHours />
          <Table.MonthHours />
        </ListTemplate>
      </List.MouthHours.Provider>
    </AuthTemplate>
  )
}
export default MonthHours
