import { createContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { toast } from '@stardust-ds/react'

import { PaginateContext } from 'components/molecules'
import { ProjectPropsHours } from 'components/organisms/Tables/Attachment/types'

import api from 'api'
import { routes } from 'routes'

import { useDebounce } from 'hooks'

import DEFAULT from './constants'
import type {
  ContextProps,
  ProfessionalProps,
  ReactNode
} from './types'

export const Context = createContext({} as ContextProps)

export const Provider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [professionals, setProfessionals] = useState<
    ProfessionalProps[]
  >([])
  const [meta, setMeta] = useState(DEFAULT.META_PROPS)
  const [filterOptions, setFilterOptions] = useState(
    DEFAULT.FILTER_OPTIONS
  )
  const [projectsUser, setProjectsUser] = useState<
    ProjectPropsHours[]
  >([])

  const contextProps = {
    professionals,
    projectsUser,
    isLoading,
    meta,
    navigateTo,
    paginate: { ...meta.paginate, setCurrent_page: setPage },
    filterOptions,
    handleFillJob,
    handleSearch,
    handleOrder,
    handleUpdateStatus,
    fetchProjectUser,
    bindUserAtProject,
    deleteBindProject
  }
  const { id } = useParams()
  const user_id = Number(id)

  async function fetchList() {
    setIsLoading(true)
    const { data } = await api.get(routes.professional.list, {
      params: {
        page: meta.paginate.current_page,
        job_id: meta.job_id,
        search: meta.search && meta.search,
        order: meta.order,
        orderField: meta.orderField
      }
    })
    setProfessionals(data?.data)
    setMeta((old) => ({
      ...old,
      paginate: { ...old.paginate, last_page: data.meta.last_page }
    }))
    setIsLoading(false)
  }

  async function fetchProjectUser(id: number) {
    if (id) {
      try {
        const response = await api.get(
          routes.professional.userProjects(id)
        )
        setProjectsUser(response.data)
      } catch (error) {
        console.error(error)
      }
    }
  }

  async function bindUserAtProject(
    id: number,
    payload: ProjectPropsHours
  ) {
    const existinProject = projectsUser.find(
      (project) =>
        project.id === payload.id &&
        project.job_attribution === payload.job_attribution &&
        project.status === payload.status
    )
    if (existinProject) {
      return toast({
        type: 'warning',
        title: 'Atenção',
        description:
          'Profissional com este cargo já vinculado ao projeto',
        position: 'bottom-right'
      })
    }
    try {
      await api.post(routes.project.projectBond(id), payload)
      toast({
        type: 'success',
        title: 'Sucesso',
        description: 'Projeto vinculado com sucesso',
        position: 'bottom-right'
      })
    } catch (error) {
      if (error instanceof Error && error.message) {
        const errorMessage = error.message
        toast({
          type: 'error',
          title: 'Erro',
          description: errorMessage,
          position: 'bottom-right'
        })
      } else {
        toast({
          type: 'error',
          title: 'Erro',
          description: 'Erro desconhecido ao vincular projeto',
          position: 'bottom-right'
        })
      }
    }
    fetchProjectUser(id)
  }

  async function deleteBindProject(
    id: number,
    user_id: number,
    projectid: number
  ) {
    try {
      await api.delete(routes.professional.userProjects(id), {
        data: {
          id: user_id,
          project_id: projectid
        }
      })
      await fetchProjectUser(id)

      return toast({
        type: 'success',
        title: 'Projeto Removido',
        description: 'Projeto removido com sucesso',
        position: 'bottom-right'
      })
    } catch (error) {
      console.error(error)
    }
    return
  }

  async function fetchFilters() {
    const { data } = await api.get(routes.job.list + '?limit=100', {
      params: { is_active: 1 }
    })

    setFilterOptions({
      job: data.data.map(
        ({ name, id }: { name: string; id: number }) => ({
          label: name,
          value: id
        })
      )
    })
  }

  function navigateTo(url: string) {
    navigate(url)
  }

  function setPage(current_page: number) {
    setMeta((old) => ({
      ...old,
      paginate: { ...old.paginate, current_page }
    }))
  }

  function handleFillJob(job_id: number | null) {
    setMeta((old) => ({
      ...old,
      job_id,
      paginate: { ...old.paginate, current_page: 1 }
    }))
  }

  function handleSearch(search: string) {
    setMeta((old) => ({
      ...old,
      search,
      paginate: { ...old.paginate, current_page: 1 }
    }))
  }

  function handleOrder(_: string) {
    setMeta((old) => ({
      ...old,
      order: old.order === 'ASC' ? 'DESC' : 'ASC'
    }))
  }

  async function handleUpdateStatus(id: number) {
    await api.put(routes.professional.updateStatus(id))
    fetchList()
  }

  useDebounce({
    fn: fetchList,
    listener: [
      meta.paginate.current_page,
      meta.search,
      meta.job_id,
      meta.order
    ]
  })

  useDebounce({
    fn: fetchFilters,
    delay: 0,
    listener: []
  })

  useDebounce({
    fn() {
      fetchProjectUser(Number(user_id))
    }
  })

  return (
    <Context.Provider value={contextProps}>
      <PaginateContext.Provider
        value={{ paginate: contextProps.paginate }}
      >
        {children}
      </PaginateContext.Provider>
    </Context.Provider>
  )
}
