import { Release as R } from 'contexts'

import { Release } from 'components/organisms'
import { AuthTemplate, CreateTemplate } from 'components/templates'

export const EditHours = () => {
  return (
    <AuthTemplate>
      <R.LaunchHours.Provider>
        <CreateTemplate title='Editar Lançamento de Horas' arrow>
          <Release.EditHours />
        </CreateTemplate>
      </R.LaunchHours.Provider>
    </AuthTemplate>
  )
}
