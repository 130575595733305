import { TechLeadProps } from 'contexts/List/Hours/Techlead/types'

import { Badge } from 'components/atoms'
import { formatDate } from 'components/utils/formatDate'

import {
  ContainerShelf,
  ContainerShelfColumn,
  HoverText,
  Text,
  TextDisabled
} from '../style'
import { ShelfProps } from '../types'

export const Shelf = ({
  config,
  props
}: ShelfProps<TechLeadProps>) => {
  const {
    hour_quantity,
    launch_date,
    project_name,
    status_id,
    status_name,
    user_name
  } = props

  const notAllowed = status_name != 'Pendente - Líder Técnico'

  return (
    <ContainerShelf template={config.template}>
      {status_name === 'Pendente - Líder Técnico' ? (
        <ContainerShelfColumn cursor='pointer'>
          <HoverText onClick={() => config.options[0].callback()}>
            {user_name}
          </HoverText>
        </ContainerShelfColumn>
      ) : (
        <ContainerShelfColumn>
          <Text>
            <TextDisabled disabled={notAllowed}>
              {user_name}
            </TextDisabled>
          </Text>
        </ContainerShelfColumn>
      )}
      <ContainerShelfColumn>{hour_quantity}</ContainerShelfColumn>
      <ContainerShelfColumn>{project_name}</ContainerShelfColumn>
      <ContainerShelfColumn>
        {formatDate(launch_date)}
      </ContainerShelfColumn>
      <ContainerShelfColumn width='200px'>
        <Badge.Hours status={{ id: status_id, name: status_name }} />
      </ContainerShelfColumn>
    </ContainerShelf>
  )
}
