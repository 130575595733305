import { useNavigate } from 'react-router-dom'

import { List } from 'contexts'

import { Filter, Table } from 'components/organisms'
import {
  AuthTemplate,
  ListTemplate,
  ReleaseTemplate
} from 'components/templates'

export const TechLead = () => {
  const navigate = useNavigate()
  return (
    <AuthTemplate>
      <List.TechLeadHours.Provider>
        <ListTemplate title='Aprovação de Horas - Tech Lead'>
          <Filter.TechLead />
          <Table.TechLead />
        </ListTemplate>
      </List.TechLeadHours.Provider>
    </AuthTemplate>
  )
}
