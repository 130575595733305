import { MouthHoursProps } from 'contexts/List/MouthHours/types'

import { Popover } from 'components/molecules'
import { formatDate } from 'components/utils/formatDate'

import {
  ContainerShelf,
  ContainerShelfColumn,
  Image,
  Text
} from '../style'
import { ShelfProps } from '../types'

export const Shelf = ({
  config,
  props
}: ShelfProps<MouthHoursProps>) => {
  const {
    id,
    project,
    hour_quantity,
    user,
    job,
    date_release,
    date_week
  } = props
  return (
    <ContainerShelf template={config.template}>
      <ContainerShelfColumn gap='.5em'>
        <Image src={user.avatar} />
        <Text>{user.name}</Text>
      </ContainerShelfColumn>
      <ContainerShelfColumn>{id}</ContainerShelfColumn>
      <ContainerShelfColumn>{project.name}</ContainerShelfColumn>
      <ContainerShelfColumn>{job.name}</ContainerShelfColumn>
      <ContainerShelfColumn left='.3em'>
        {formatDate(date_week)}
      </ContainerShelfColumn>
      <ContainerShelfColumn left='.3em'>
        {hour_quantity}
      </ContainerShelfColumn>
      <ContainerShelfColumn>
        <ContainerShelfColumn width='5.5em'>
          {formatDate(date_release)}
        </ContainerShelfColumn>
        <Popover options={config.options} />
      </ContainerShelfColumn>
    </ContainerShelf>
  )
}
