import { Section } from './Sections'
import { Main } from './style'

export function PostHoursRelease() {
  return (
    <Main>
      <Section.CastingHours />
    </Main>
  )
}

export type { FormHoursReleaseProps } from './types'
