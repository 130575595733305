import { DefaultMetaProps } from './types'

const META_PROPS = {
  search: null,
  project_id: null,
  date_start: '',
  date_end: '',
  orderField: 'date_release',
  order: 'DESC',
  pagination: {
    current_page: 1,
    last_page: 1
  }
} as DefaultMetaProps

const FILTER_OPTIONS_PROJECT = {
  project: []
}

const FILTER_OPTIONS_JOB = {
  job: []
}

export default {
  META_PROPS,
  FILTER_OPTIONS_PROJECT,
  FILTER_OPTIONS_JOB
}
