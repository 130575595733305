import React from 'react'

export const IconReports = ({
  width = '18px',
  height = '16px',
  color = 'black',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.0492 6.58276C14.8058 3.05839 11.9908 0.268383 8.4664 0C8.48409 0.000693077 8.47229 0 8.46345 0C8.20907 0 7.99864 0.22149 7.99864 0.47955L7.99746 7.07823L14.595 7.0782C14.8663 7.07823 15.0934 6.85409 15.0492 6.58276ZM9.41311 5.66259V1.57786C11.4039 2.09398 12.9817 3.67183 13.4978 5.66259H9.41311ZM6.58182 8.49388V1.49675C6.58182 1.235 6.37186 1.0175 6.1176 1.0175C6.09548 1.0175 6.07708 1.01806 6.05496 1.02174C2.54239 1.51958 -0.144394 4.56381 0.00601854 8.26809C0.108771 12.0183 3.31108 15.1002 7.05665 15.1002C7.08614 15.1002 7.11198 15.1 7.14147 15.1C8.62731 15.0816 9.97571 14.6022 11.1289 13.8023C11.3648 13.6364 11.3792 13.2939 11.1764 13.0913L6.58182 8.49388ZM7.12743 13.6846H7.05665C4.08969 13.6846 1.49434 11.1813 1.4206 8.22255C1.3106 5.70388 2.87459 3.49193 5.16617 2.68737V9.08138L9.30104 13.2192C8.61976 13.5135 7.88244 13.6728 7.12743 13.6846ZM15.5387 8.49388L8.54308 8.49386L13.1764 13.1537C13.2722 13.2496 13.3984 13.2971 13.52 13.2971C13.6342 13.2971 13.7436 13.2565 13.832 13.1753C14.9713 12.0989 15.7561 10.6505 15.9885 9.01984C16.0549 8.74162 15.8248 8.49388 15.5387 8.49388Z'
        fill='#3B454F'
      />
    </svg>
  )
}
