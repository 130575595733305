import React from 'react'

export const IconPayments = ({
  width = '18px',
  height = '16px',
  color = '#3B454F',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.9406 11.1821C8.64885 12.6791 7.25985 13.5896 5.25503 13.7241V15.25C5.25503 15.6641 4.9191 16 4.50503 16C4.09097 16 3.75503 15.6641 3.75503 15.25V13.6803C3.64828 13.6689 3.55153 13.6728 3.44253 13.6577C2.59782 13.5449 1.6945 13.2554 0.897627 13L0.526534 12.8818C0.132002 12.7573 -0.0877477 12.3364 0.0362835 11.9409C0.161284 11.5464 0.577315 11.3247 0.97769 11.4512L1.35466 11.5713C2.09294 11.8076 2.92888 12.0757 3.64566 12.1719C5.3781 12.4121 7.2316 12.1113 7.46794 10.895C7.68569 9.77978 6.87125 9.375 4.3156 8.72462L3.8146 8.59619C2.32241 8.20703 -0.454935 7.48194 0.0636273 4.81787C0.355315 3.31969 1.74741 2.411 3.75503 2.27747V0.75C3.75503 0.335938 4.09097 0 4.50503 0C4.9191 0 5.25503 0.335938 5.25503 0.75V2.31647C5.35891 2.32763 5.45566 2.32756 5.56169 2.34228C6.1066 2.41797 6.73356 2.56788 7.53532 2.81447C7.93082 2.93603 8.15347 3.35597 8.03141 3.75147C7.91031 4.14747 7.49235 4.37013 7.09391 4.24806C6.37125 4.02588 5.8185 3.89209 5.35466 3.82763C3.6271 3.58838 1.77263 3.88869 1.53628 5.10497C1.34488 6.08691 1.96597 6.56347 4.1935 7.14503L4.68569 7.271C6.70231 7.78419 9.465 8.48681 8.9406 11.1821Z'
        fill='#3B454F'
      />
    </svg>
  )
}
