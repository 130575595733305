import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'

import { Button } from '@stardust-ds/react'
import { List } from 'contexts'
import { OrderProps } from 'contexts/List/OrderOfServiceProfessional/types'
import { theme } from 'styles'

import Close from 'components/atoms/Buttons/Close'
import { IconTrash } from 'components/atoms/Icons/IconTrash'
import TotalPayment from 'components/organisms/Tables/OrderFormTable/TotalPayment'

import PaginateCommission from '../PaginateCommission/paginateCommission'
import {
  Columns,
  ContainerSome,
  ContainerWrapper,
  ContainerAbsolute,
  ContainerFooter,
  ContainerLabelProfessional,
  ContainerModal,
  ContainerWap,
  Footer,
  IconButton,
  Overlay,
  Row,
  RowButtons
} from './style'

interface IModalProps {
  text: string
  placeholder?: string
  defaultOpened?: boolean
}

export interface IHandleModalPropsConfirmationList {
  open(): void
  close(): void
}

const ConfirmationList = forwardRef<
  IHandleModalPropsConfirmationList,
  IModalProps
>((props, ref) => {
  const { text } = props
  const {
    onCreateOs,
    metaCommission,
    setMetaCommission,
    selectSendProfessionals,
    deleteUserListConfirmation
  } = useContext(List.OrderOfServiceprofessionalOS.Context)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const currentPage = metaCommission.paginate.current_page
  const itemsPerPage = 14
  const totalItems = selectSendProfessionals.length
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const paginatedProfessionals = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    return selectSendProfessionals.slice(startIndex, endIndex)
  }, [currentPage, totalPages, selectSendProfessionals])

  function handlePaginate() {
    setMetaCommission((old) => {
      const current_page = old.paginate.current_page
      const total_pages = Math.ceil(
        selectSendProfessionals.length / itemsPerPage
      )
      if (selectSendProfessionals.length === 0) {
        return {
          ...old,
          paginate: { ...old.paginate, current_page: 1 }
        }
      }

      if (current_page > total_pages) {
        return {
          ...old,
          paginate: {
            ...old.paginate,
            current_page: current_page - 1
          }
        }
      }

      return old
    })
  }

  useEffect(() => {
    handlePaginate()
  }, [selectSendProfessionals.length])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setIsOpen(true)
      },
      close
    }),
    []
  )
  if (!isOpen) return null

  return (
    <>
      <ContainerModal>
        <Columns>
          <Row>
            <h2>{text}</h2>
            <Close onClick={() => close()} />
          </Row>
          <ContainerAbsolute>
            <ContainerWrapper>
              {paginatedProfessionals.map(
                (item: OrderProps, index: number) => (
                  <ContainerWap key={index}>
                    <ContainerLabelProfessional>
                      {item.name}
                      <IconButton>
                        <IconTrash
                          onClick={() =>
                            deleteUserListConfirmation(
                              item.professional_id
                            )
                          }
                        />
                      </IconButton>
                    </ContainerLabelProfessional>
                  </ContainerWap>
                )
              )}
            </ContainerWrapper>
          </ContainerAbsolute>

          <ContainerFooter>
            <ContainerSome>
              <TotalPayment />
            </ContainerSome>

            <Footer>
              <PaginateCommission
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
              />
            </Footer>
          </ContainerFooter>

          <RowButtons>
            <Button
              style={{ borderRadius: '500px' }}
              bgColor='#E9EBEE'
              labelColor={theme.neutrals.gray7}
              onClick={close}
            >
              Cancelar
            </Button>
            <Button
              style={{
                borderRadius: '500px',
                boxShadow: '0px 5px 10px 0px #0066FF40'
              }}
              bgColor='#0066FF'
              onClick={() => {
                setIsLoading(true)
                onCreateOs()
              }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Confirmar
            </Button>
          </RowButtons>
        </Columns>
      </ContainerModal>
      <Overlay />
    </>
  )
})

export default ConfirmationList
