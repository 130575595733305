import { useContext, useEffect, useState } from 'react'

import { List } from 'contexts'

import { formatCurrency } from 'components/utils/formatCurrent'

const TotalPayment = () => {
  const { professionalOS, selectSendProfessionals, checked } =
    useContext(List.OrderOfServiceprofessionalOS.Context)
  const [totalPayment, setTotalPayment] = useState(0)

  const calculateTotalPayment = () => {
    let total = 0

    selectSendProfessionals.forEach((item) => {
      const professional = professionalOS.find(
        (e) => e.id === item.professional_id
      )

      if (professional && checked[item.professional_id]) {
        const hourQuantity = professional?.extrahour_release
          .map((prop) => prop.hour_quantity)
          .reduce((acc, cc) => acc + cc, 0)

        const commissionUser = item.commission ?? 0
        const extraHours = (
          professional.extra_hour_value / 100
        ).toFixed(2)
        const payment =
          professional.fixed_payment_value +
          hourQuantity * Number(extraHours) +
          commissionUser

        total += payment
      }
    })
    setTotalPayment(total)
  }

  useEffect(() => {
    calculateTotalPayment()
  }, [selectSendProfessionals, professionalOS])

  return (
    <>
      <p>
        Valor total: {formatCurrency(totalPayment, 'BRL', 'pt-BR')}{' '}
      </p>
    </>
  )
}

export default TotalPayment
