import { UseFormReturn } from 'react-hook-form'

import { toast } from '@stardust-ds/react'
import axios from 'axios'

import { FormProps, getUfOption } from 'components/organisms'
import { COMPANY } from 'components/organisms/Forms/Company/constants'
import { BANK_OPTIONS } from 'components/organisms/Forms/Company/constants'
import { LegalNature } from 'components/organisms/Forms/Company/Objects'
import {
  ProfessionalProps,
  SelectOption
} from 'components/organisms/Forms/Professional/types'
import { getDateInput } from 'components/utils/formatDate'
import { generateOpitionsFromBackend } from 'components/utils/OptionsAplication'

import api from 'api'
import { routes } from 'routes'
import { externRoutes } from 'routes'

import { CNPJResponse, IResponseCnae } from './types'

export async function handleCnpj(
  cnpj: string,
  methods: UseFormReturn<FormProps['Company'], any>
) {
  if (!cnpj) return
  const sanitizeData = cnpj.replace(/\D/g, '')

  axios
    .get(externRoutes.cnpj(sanitizeData))
    .then((e) => {
      const { data } = e
      const fieldsData: Record<string, CNPJResponse> = {
        cnpj: data.cnpj,
        opening_date: data.data_inicio_atividade,
        razao_social: data.razao_social,
        fantasy_name: data.nome_fantasia,
        size: COMPANY.SIZE.find(
          (item) =>
            item.label.toLowerCase() === data.porte.toLowerCase()
        ),
        cep: data.cep,
        code_and_description_of_the_legal_status: LegalNature.find(
          (item) =>
            item.value === String(data.codigo_natureza_juridica)
        ),
        phone_number: data.ddd_telefone_1,
        house_number: data.numero,
        complement: data.complemento,
        date_of_registration_status: data.data_situacao_cadastral,
        responsible_federative_entity:
          data.ente_federativo_responsavel,
        registration_status: COMPANY.CADASTRATION.find((item) =>
          item.label.includes(
            data.descricao_identificador_matriz_filial.toLowerCase()
          )
        ),
        main_cnae: [
          {
            label: data.cnae_fiscal_descricao,
            value: String(data.cnae_fiscal)
          }
        ],
        secondary_cnae:
          data.cnaes_secundarios[0].codigo === 0
            ? null
            : data.cnaes_secundarios.map((item: IResponseCnae) => ({
                label: item.descricao,
                value: String(item.codigo)
              })),
        street_name: data.logradouro,
        neighborhood_name: data.bairro
      }
      Object.entries(fieldsData).forEach(([key, value]) => {
        /* @ts-expect-error */
        return methods.setValue(key, value)
      })
    })
    .catch((err) =>
      toast({
        type: 'error',
        title: 'error',
        description: err.response.data.message,
        position: 'bottom-right'
      })
    )
}

export async function fetchProps(
  methods: UseFormReturn<FormProps['Company'], any>
) {
  const [
    { data: owners },
    { data: banks },
    { data: cnae },
    { data: uf }
  ] = await Promise.all([
    await api.get(routes.professional.list + '?limit=50'),
    await api.get(externRoutes.banks),
    await api.get(externRoutes.cnae),
    await api.get(externRoutes.uf)
  ])

  methods.setValue('options', {
    owners: await owners.data
      .filter(
        (own: any) => own.job.name.substring(0, 7) === 'Diretor'
      )
      .map((own: ProfessionalProps) => ({
        label: own.name,
        value: String(own.id)
      })),
    banks: banks
      ?.filter((bank: any) => bank.ispb !== '')
      .map((bank: any) => ({
        label: `${bank.ispb} ${bank.name}`,
        value: bank.name
      })),
    uf: uf.map((uf: any) => ({
      label: uf.nome,
      value: uf.sigla
    })),
    cnae: cnae.map((cnae: any) => ({
      label: cnae.descricao,
      value: cnae.id
    }))
  })
}

export function handleCEP(
  methods: UseFormReturn<FormProps['Company'], any>,
  CEP: string
) {
  if (!CEP) return
  if (CEP.length < 9) return
  axios
    .get(externRoutes.cep(CEP))
    .then(({ data }) => {
      methods.setValue('complement', data?.complemento)
      methods.setValue('city_name', data?.localidade)
      methods.setValue('uf', getUfOption(data?.uf) as any)
      methods.clearErrors('cep')
    })
    .catch((error) => {
      methods.setError('cep', { message: 'CEP inválido' })
    })
}

export async function fetchCompany(id: string) {
  const [{ data: props }, { data: BANKS }] = await Promise.all([
    await api.get(routes.company.getCompany(+id)),
    await api.get(externRoutes.banks)
  ])

  const banks = BANKS.filter((bank: any) => bank.ispb !== '').map(
    (bank: any) => ({
      label: `${bank.ispb} ${bank.name}`,
      value: bank.name
    })
  )

  if (props.length === 0) throw new Error('Empresa não encontrada')

  return HandlePopulateFields(props, banks)
}

export function HandlePopulateFields(
  props: any,
  banks: SelectOption[]
) {
  const director = props.userCompanies.find(
    (prop: any) => prop.type_of_subscribes === 'DIRECTOR'
  )

  const code = props.code_and_description_of_the_legal_status.map(
    (item: any) => ({ label: item.name, value: item.id })
  )

  const witnesses = props.userCompanies
    .filter((prop: any) => prop.type_of_subscribes === 'WITNESSES')
    .map((v: any) => ({ label: v.name, value: v.id }))

  return {
    id: props.id,
    razao_social: props.razao_social,
    fantasy_name: props.fantasy_name,
    director: {
      label: String(director.name),
      value: String(director.id)
    },
    witnesses: witnesses,
    opening_date: getDateInput(props.opening_date),
    state_registration: props.state_registration,
    municipal_registration: props.municipal_registration,
    size: generateOpitionsFromBackend(props.size, COMPANY.SIZE),
    code_and_description_of_the_legal_status: code[0],
    cep: props.cep,
    street_name: props.street_name,
    house_number: props.house_number,
    complement: props.complement,
    neighborhood_name: props.neighborhood_name,
    city_name: props.city_name,
    uf: getUfOption(props.uf),
    responsible_federative_entity:
      props.responsible_federative_entity,
    registration_status: generateOpitionsFromBackend(
      props.registration_status,
      COMPANY.CADASTRATION
    ),
    date_of_registration_status: getDateInput(
      props.date_of_registration_status
    ),
    reason_for_registration_status:
      props.reason_for_registration_status,
    special_situation: props.special_situation,
    date_of_special_situation: getDateInput(
      props.date_of_special_situation
    ),
    main_cnae: props.main_cnae.map((item: any) => ({
      label: item.description,
      value: item.id
    })),
    secondary_cnae: props.secondary_cnae.map((item: any) => ({
      label: item.description,
      value: item.id
    })),
    is_matriz: props.is_matriz,
    secondary_email: props.secondary_email,
    main_email: props.main_email,
    cnpj: props.cnpj,
    phone_number: props.phone_number,
    type_company: props.type_company,
    bank: generateOpitionsFromBackend(props.bank, banks),
    account_type: generateOpitionsFromBackend(
      props.account_type,
      BANK_OPTIONS.TYPE_ACCOUNT
    ),
    agency: props.agency,
    account_number: props.account_number
  }
}

export async function OnSubmit(
  data: FormProps['Company'],
  id: string | undefined
) {
  const payload = {
    id: data.id,
    razao_social: data.razao_social,
    fantasy_name: data.fantasy_name,
    opening_date: data.opening_date,
    director: data.director?.value,
    witnesses: data.witnesses?.map((item) => item.value),
    state_registration: data.state_registration,
    municipal_registration: data.municipal_registration,
    size: data.size?.value,
    code_and_description_of_the_legal_status: [
      {
        id: data.code_and_description_of_the_legal_status?.value,
        name: data.code_and_description_of_the_legal_status?.label
      }
    ],
    cep: data.cep,
    street_name: data.street_name,
    house_number: data.house_number,
    complement: data.complement,
    neighborhood_name: data.neighborhood_name,
    city_name: data.city_name,
    uf: data.uf?.value,
    responsible_federative_entity: data.responsible_federative_entity,
    registration_status: data.registration_status?.value,
    date_of_registration_status: data.date_of_registration_status,
    reason_for_registration_status:
      data.reason_for_registration_status,
    special_situation: data.special_situation,
    date_of_special_situation: data.date_of_special_situation,
    main_cnae: data.main_cnae?.map((item) => ({
      id: item.value,
      description: item.label
    })),
    secondary_cnae: data.secondary_cnae?.map((item) => ({
      id: item.value,
      description: item.label
    })),
    is_matriz: data.is_matriz,
    secondary_email: data.secondary_email,
    main_email: data.main_email,
    cnpj: data.cnpj,
    phone_number: data.phone_number,
    type_company: data.type_company,
    bank: data.bank?.value,
    account_type: data.account_type?.value,
    agency: data.agency.replace('-', ''),
    account_number: data.account_number.replace('-', '')
  }

  id
    ? await api.put(routes.company.getCompany(Number(id)), payload)
    : await api.post(routes.company.list, payload).catch((err) =>
        toast({
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
          position: 'bottom-right'
        })
      )

  return toast.success({
    type: 'success',
    title: id
      ? 'Empresa Editada com Sucesso'
      : 'Empresa Criada com Sucesso',
    position: 'bottom-right'
  })
}
