import styled from 'styled-components'
import { theme } from 'styles'

export const Main = styled.div<{ w: string }>`
  display: flex;
  width: ${({ w }) => w};
  color: ${theme.neutrals.gray6};
  margin-bottom: 0.4rem;
  gap: 1rem;
  align-items: center;

  input {
    :checked {
      background: ${theme.brand.color.status.neutral1};
      border-color: ${theme.brand.color.status.neutral1};
    }
  }
`
export const TypographyWrapper = styled.div`
  position: relative;
`

export const TooltipText = styled.span`
  min-width: 300px;
  max-height: 300px;
  visibility: hidden;
  background-color: #343535;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 8px 12px;
  position: absolute;
  left: 50%;
  bottom: 100%;
  z-index: 1;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  overflow-wrap: break-word;
  white-space: normal;

  line-height: 15px;

  ${TypographyWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

export const Container = styled.div`
  display: inline-block;
`
