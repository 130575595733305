import { useContext } from 'react'

import { List } from 'contexts'

import { formatDate } from 'components/utils/formatDate'

import { ContainerShelf, ContainerShelfColumn, Text } from '../style'
import { ShelfProps } from '../types'
import { HoursRelaesesUsers } from './types'

export const Shelf = ({
  props,
  config
}: ShelfProps<HoursRelaesesUsers>) => {
  const {
    project,
    project_id,
    date_week,
    date_release,
    job,
    hour_quantity
  } = props

  return (
    <>
      <ContainerShelf template={config.template}>
        <ContainerShelfColumn>
          <Text>{project_id}</Text>
        </ContainerShelfColumn>

        <ContainerShelfColumn>
          <Text>{project.name}</Text>
        </ContainerShelfColumn>

        <ContainerShelfColumn>
          <Text>{job.name}</Text>
        </ContainerShelfColumn>

        <ContainerShelfColumn>
          <Text>{formatDate(date_week)}</Text>
        </ContainerShelfColumn>

        <ContainerShelfColumn>
          <Text>{hour_quantity}h</Text>
        </ContainerShelfColumn>
        <ContainerShelfColumn>
          <Text> {formatDate(date_release)}</Text>
        </ContainerShelfColumn>
      </ContainerShelf>
    </>
  )
}
