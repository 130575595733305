import { AuthTemplate, CreateTemplate } from 'components/templates'

const Home = () => {
  return (
    <AuthTemplate>
      <CreateTemplate title='Home'></CreateTemplate>
    </AuthTemplate>
  )
}

export default Home
