import { useContext } from 'react'

import { DatePicker } from '@stardust-ds/react'
import { List } from 'contexts'

import { IconGlass, Inputs, Selects } from 'components/atoms'
import { convertDateFormat } from 'components/organisms/Forms/Team/logic'

import { Container, Main } from '../style'
import { Option } from 'types'

type ValueProps = Option | null | undefined

export const PostHoursReleases = () => {
  const {
    meta,
    handleSearch,
    filterOptionsProject,
    handleFilterProject,
    handleReleaseDate
  } = useContext(List.PostHoursReleases.Context)
  const { search } = meta

  return (
    <Main>
      <Container gap='1em'>
        <Inputs.Default
          value={search || ''}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Poppins, sans-serif'
          }}
          iconLeft={<IconGlass />}
          placeholder='Buscar...'
          onChange={(e) => handleSearch(e.target?.value)}
        />
        <Selects.Default
          placeholder='Projetos'
          width={230}
          searchable
          options={filterOptionsProject?.project}
          onSelect={
            ((option: ValueProps) =>
              option &&
              handleFilterProject(Number(option?.value))) as any
          }
          onClear={() => handleFilterProject(null as any)}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: 'inherit',
            fontWeight: '500'
          }}
        />
        <DatePicker
          onChange={(date) =>
            date.length > 0 &&
            handleReleaseDate(
              convertDateFormat(String(date[0])),
              convertDateFormat(String(date[1]))
            )
          }
          inputStartProps={{
            placeholder: 'Data de lançamento'
          }}
          weekDays={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']}
        />
      </Container>
    </Main>
  )
}
