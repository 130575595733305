import {
  ReactNode,
  createContext,
  useCallback,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'

import { SelectOption, toast } from '@stardust-ds/react'
import { MouthHoursProps } from 'contexts/List/MouthHours/types'

import { IFormProps } from 'components/organisms/Releases/EditHours/form'
import { TODAY } from 'components/utils/dateNow'

import api from 'api'
import { routes } from 'routes'

import { useDebounce } from 'hooks'

import { ContextLaunchHourProps } from './types'

export const Context = createContext({} as ContextLaunchHourProps)

export const Provider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const [projects, setProjects] = useState<SelectOption[]>([])
  const [jobs, setJobs] = useState<SelectOption[]>([])
  const [data, setData] = useState<MouthHoursProps>(
    {} as MouthHoursProps
  )

  const getById = useCallback(async (id: number) => {
    const abort = new AbortController()
    try {
      const { data } = await api.get(
        routes.hours.Mouth.showLaunch(id)
      )
      setData(data)
      abort.abort()
    } catch (error) {
      console.error(error)
    }
  }, [])

  async function fetchFilters() {
    const [{ data: projects }, { data: job }] = await Promise.all([
      api.get(routes.usersProjects.list),
      api.get(routes.job.listAll)
    ])
    setProjects(
      projects.map((item: any) => ({
        label: item.name,
        value: item.id
      }))
    )
    setJobs(
      job.data.map((item: any) => ({
        label: item.name,
        value: item.id
      }))
    )
  }

  function verifyValues(hour: number, justify: string, week: string) {
    const date = new Date(week).toISOString().split('T')[0]
    if (hour <= 0 || !hour) {
      toast({
        type: 'warning',
        title: 'Aviso',
        description:
          'Preencha a quantidade de horas com numeros inteiros',
        position: 'bottom-right'
      })
      return false
    }
    if (!justify) {
      toast({
        type: 'warning',
        title: 'Aviso',
        description: 'Preencha a justificativa',
        position: 'bottom-right'
      })
      return false
    }
    if (date > TODAY) {
      toast({
        type: 'warning',
        title: 'Aviso',
        description: 'O periodo não pode ser maior que hoje',
        position: 'bottom-right'
      })
      return false
    }
    return true
  }

  async function handleEditHours(id: number, data: IFormProps) {
    if (
      verifyValues(
        data.hour_quantity,
        data.justification,
        data.date_release
      )
    ) {
      await api.put(routes.hours.Mouth.showLaunch(id), data)
      toast({
        type: 'success',
        title: 'Lançamento Editado com Sucesso',
        position: 'bottom-right'
      })
      navigate('/HoursMonth')
    }
  }

  const contextProps = {
    handleEditHours,
    getById,
    projects,
    data,
    jobs
  }

  useDebounce({
    fn: fetchFilters,
    listener: []
  })

  return (
    <Context.Provider value={contextProps}>
      {children}
    </Context.Provider>
  )
}
