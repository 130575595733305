import React from 'react'

export const IconProjects = ({
  width = '18px',
  height = '16px',
  color = 'black',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.2222 0H1.77778C0.795833 0 0 0.795833 0 1.77778V9.77778C0 10.7597 0.795833 11.5556 1.77778 11.5556H5.89722L5.63056 12.8889H4.22222C3.85556 12.8889 3.55556 13.1889 3.55556 13.5556C3.55556 13.9222 3.85556 14.2222 4.22222 14.2222H11.7778C12.1458 14.2222 12.4444 13.9236 12.4444 13.5556C12.4444 13.1875 12.1458 12.8889 11.7778 12.8889H10.3686L10.1028 11.5556H14.2222C15.2042 11.5556 16 10.7597 16 9.77778V1.77778C16 0.795833 15.2028 0 14.2222 0ZM6.99167 12.8889L7.25833 11.5556H8.74333L9.01 12.8889H6.99167ZM14.6667 9.77778C14.6667 10.0228 14.4673 10.2222 14.2222 10.2222H1.77778C1.53272 10.2222 1.33333 10.0228 1.33333 9.77778V1.77778C1.33333 1.53272 1.53272 1.33333 1.77778 1.33333H14.2222C14.4673 1.33333 14.6667 1.53272 14.6667 1.77778V9.77778ZM10.0583 3.83056C9.84133 3.61356 9.48972 3.61356 9.27278 3.83056C9.05583 4.04756 9.05578 4.4 9.27278 4.61694L10.2133 5.55694L9.27278 6.49694C9.05578 6.71394 9.05578 7.06639 9.27278 7.28333C9.38333 7.39167 9.525 7.44444 9.66667 7.44444C9.80833 7.44444 9.95083 7.39061 10.0594 7.28211L11.3928 5.94878C11.6098 5.73178 11.6098 5.37933 11.3928 5.16239L10.0583 3.83056ZM6.725 3.83056C6.508 3.61356 6.15639 3.61356 5.93944 3.83056L4.60611 5.16389C4.38911 5.38089 4.38911 5.73333 4.60611 5.95028L5.93944 7.28361C6.05 7.39167 6.19167 7.44444 6.33333 7.44444C6.475 7.44444 6.61667 7.39167 6.725 7.28333C6.942 7.06633 6.942 6.71389 6.725 6.49694L5.78611 5.55556L6.725 4.61667C6.91944 4.4 6.91944 4.04722 6.725 3.83056Z'
        fill='#3B454F'
      />
    </svg>
  )
}
