import { useContext, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { List } from 'contexts'

import { Loading } from 'components/atoms'
import { TableHeader } from 'components/molecules'
import {
  IHandleDeleteLauching,
  Modal
} from 'components/molecules/Modais'
import { NotFoundFilter } from 'components/organisms/Filters/NotFoundFilter'

import { LoadingWrapper, Main, NotFoundWrapper } from '../style'
import { GRID_TEMPLATE, HEADERS } from './constants'
import { Shelf } from './shelf'

export const MonthHours = () => {
  const ref = useRef<IHandleDeleteLauching>(null)
  const navigate = useNavigate()
  const { isLoading, handleOrder, payload, handleDelete } =
    useContext(List.MouthHours.Context)
  const POPOVER_OPTIONS = (id: number) => [
    {
      label: 'Editar',
      callback: () => navigate(`/LaunchHours/${id}`)
    },
    { label: 'Excluir', callback: () => ref.current?.open(id) }
  ]

  const Table = useMemo(() => {
    if (isLoading)
      return (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )

    if (payload.length === 0) {
      return (
        <NotFoundWrapper>
          <NotFoundFilter text='Nenhum lançamento encontrado.' />
        </NotFoundWrapper>
      )
    }

    return payload.map((props) => (
      <Shelf
        key={props.id}
        config={{
          template: GRID_TEMPLATE,
          options: POPOVER_OPTIONS(props.id)
        }}
        {...{ props }}
      />
    ))
  }, [isLoading])

  return (
    <Main>
      <TableHeader
        headers={HEADERS}
        template={GRID_TEMPLATE}
        handleOrder={handleOrder}
      />
      <Modal.DeleteLaunching action={handleDelete} ref={ref} />
      {Table}
    </Main>
  )
}
