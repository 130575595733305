import styled from 'styled-components'
import { theme } from 'styles'

export const Container = styled.div`
  width: 60rem;
  height: 60vh;
  margin-bottom: 2rem;
  border: 1px solid ${theme.neutrals.gray3};
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: ${theme.neutrals.gray6};

  h3 {
    color: ${theme.neutrals.gray9};
  }
`

export const ContainerFixed = styled.div`
  width: 100%;
  max-width: 96%;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  margin: 0 auto;
  padding-top: 2em;
  margin-bottom: 2em;
  position: static;
  border-radius: 8px;
  background-color: ${theme.neutrals.pureWhite};
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    padding: 2rem 2rem 1rem;
    height: auto;
    height: 100%;
  }
`

export const ContainerCompany = styled.div`
  width: 600px;
  display: flex;
`
export const ContainerButton = styled.div`
  width: 305px;
  display: flex;
  padding-right: 2em;
  margin-top: 2.5em;
`
