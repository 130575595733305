import type { HeaderProps } from 'components/molecules'

export const HEADERS = [
  { field: 'project_id', label: 'Código' },
  { field: 'project_id', label: 'Projeto', on: true },
  { field: 'job_id', label: 'Papel', on: true },
  { field: 'date_week', label: 'Semana', on: true },
  { field: 'hour_quantity', label: 'QNT Horas', on: true },
  { field: 'date_release', label: 'Lançamento', on: true }
] as HeaderProps[]

export const GRID_TEMPLATE = '1.3fr 1fr 1fr 1fr 1fr 1fr'
