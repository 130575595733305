import { SVGProps } from 'react'

export const IconTrash = ({
  width = '15',
  height = '16',
  color = '#FF3541',

  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.5 14.5C1.5 15.3281 2.17188 16 3 16H12C12.8281 16 13.5 15.3281 13.5 14.5V4H1.5V14.5ZM10 6.5C10 6.22266 10.2227 6 10.5 6C10.7773 6 11 6.22266 11 6.5V13.5C11 13.7773 10.7773 14 10.5 14C10.2227 14 10 13.7773 10 13.5V6.5ZM7 6.5C7 6.22266 7.22266 6 7.5 6C7.77734 6 8 6.22266 8 6.5V13.5C8 13.7773 7.77734 14 7.5 14C7.22266 14 7 13.7773 7 13.5V6.5ZM4 6.5C4 6.22266 4.22266 6 4.5 6C4.77734 6 5 6.22266 5 6.5V13.5C5 13.7773 4.77734 14 4.5 14C4.22266 14 4 13.7773 4 13.5V6.5ZM14 1H10.5L10.1382 0.276375C10.0535 0.107 9.88037 0 9.69097 0H5.30903C5.11963 0 4.94653 0.107 4.86181 0.276375L4.5 1H1C0.723875 1 0.5 1.22381 0.5 1.5V2.5C0.5 2.77612 0.723875 3 1 3H14C14.2762 3 14.5 2.77612 14.5 2.5V1.5C14.5 1.22381 14.2762 1 14 1Z'
        fill={color}
      />
    </svg>
  )
}
