import React from 'react'

const IconLoginTopBlue = ({
  width = '375px',
  height = '287px',
  color = 'black',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 375 287'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M276.186 -66.4997C247.994 -82.9484 214.755 -95.3086 183.04 -87.7022C161.706 -82.5679 142.753 -68.2109 131.991 -49.1C120.467 -28.5628 129.038 -7.26483 121.229 12.2265C114.943 28.0097 91.3227 38.6584 76.8461 44.3631C58.5598 51.6843 39.0352 56.9138 23.1299 68.6086C7.22468 80.3034 -4.39454 100.745 1.60565 119.571C5.8915 132.882 17.8918 142.295 24.4635 154.655C43.5117 190.12 15.1299 240.703 41.226 271.318C59.8933 293.187 95.3228 289.574 120.847 276.358C146.372 263.141 168.087 242.414 195.516 233.667C220.089 225.87 246.661 228.532 272.281 225.395C298.186 222.257 323.806 212.844 343.331 195.73C362.95 178.521 375.903 153.134 374.951 127.082C373.808 96.2767 354.95 79.5426 341.521 53.3006C329.997 30.8619 323.235 7.47224 318.187 -17.1534C313.044 -42.1593 298.567 -53.4738 276.186 -66.4997Z'
        fill='#0066FF'
      />
    </svg>
  )
}

export default IconLoginTopBlue
