export const HEADERS = [
  {
    field: 'name',
    label: 'Projeto e Cargo',
    on: false
  },
  {
    field: 'hours_mounths_estimated',
    label: 'Horas/ mês estimadas',
    on: false
  },
  {
    field: 'date_start_allocation',
    label: 'Início do alocação',
    on: false
  },
  {
    field: 'date_end_allocation',
    label: 'Fim da Alocação'
  },
  {
    field: 'Status',
    label: 'status'
  }
]

export const GRID_TEMPLATE = '1.5fr 1.2fr 1.2fr 1.2fr 1fr'
