export const IconAlert = ({
  width = '39',
  height = '28',
  color = '#FF3541',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 62 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M60.7697 46.1666L35.3768 2.83325C33.4328 -0.500081 28.5268 -0.500081 26.5697 2.83325L1.18873 46.1666C-0.764839 49.488 1.66838 53.6666 5.58397 53.6666H56.3697C60.2697 53.6666 62.7102 49.4999 60.7697 46.1666ZM28.1149 16.5237C28.1149 14.9463 29.3947 13.6666 30.9721 13.6666C32.5494 13.6666 33.8292 14.9523 33.8292 16.5237V31.7618C33.8292 33.3392 32.5494 34.619 31.0792 34.619C29.609 34.619 28.1149 33.3452 28.1149 31.7618V16.5237ZM30.9721 46.0475C28.9054 46.0475 27.2292 44.3713 27.2292 42.3047C27.2292 40.238 28.9042 38.5618 30.9721 38.5618C33.0399 38.5618 34.7149 40.238 34.7149 42.3047C34.7102 44.369 33.0435 46.0475 30.9721 46.0475Z'
        fill={color}
      />
    </svg>
  )
}
