import { useContext } from 'react'

import { DatePicker } from '@stardust-ds/react'
import { List } from 'contexts'

import { IconGlass, Inputs, Selects } from 'components/atoms'
import { convertDateFormat } from 'components/organisms/Forms/Team/logic'

import { Container, Main } from '../style'
import { Option } from 'types'

export const HoursProfessional = () => {
  const {
    meta,
    handleDate,
    handleSearch,
    handleStatus,
    handleProject,
    filterOptionsStatus,
    filterOptionsProject
  } = useContext(List.ProfessionalHours.Context)
  const { search } = meta

  return (
    <Main>
      <Container gap='1em'>
        <Inputs.Default
          value={search || ''}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Poppins, sans-serif'
          }}
          iconLeft={<IconGlass />}
          placeholder='Buscar...'
          onChange={(e) => handleSearch(e.target?.value)}
        />
        <Selects.Default
          width={200}
          options={filterOptionsProject.project}
          placeholder='Projeto'
          onSelect={
            ((value: Option | null) =>
              value && handleProject(Number(value.value))) as any
          }
          onClear={() => handleProject(null)}
        />
        <Selects.Default
          width={200}
          options={filterOptionsStatus.status}
          placeholder='Status'
          onSelect={
            ((value: Option | null) =>
              value && handleStatus(Number(value.value))) as any
          }
          onClear={() => handleStatus(null)}
        />
        <DatePicker
          onChange={(date) =>
            date.length > 0 &&
            handleDate(
              convertDateFormat(String(date[0])),
              convertDateFormat(String(date[1]))
            )
          }
          inputStartProps={{
            placeholder: 'Início do período'
          }}
          hasEndDate
          inputEndProps={{
            placeholder: 'Fim do periodo'
          }}
          weekDays={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']}
        />
      </Container>
    </Main>
  )
}
