import { ChangeEvent, useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { Flex, Textarea } from '@stardust-ds/react'
import { List } from 'contexts'
import styled from 'styled-components'
import { theme } from 'styles'

import { Inputs, Option, Selects } from 'components/atoms'

import {
  Container,
  ContainerInputs,
  ContainerTitleJustification,
  TextTitle
} from '../style'
import { FormHoursReleaseProps } from '../types'
import { AddHoursToAnotherProject } from './addHoursToAnotherProject'

export const CastingHours = () => {
  const Context = useFormContext<FormHoursReleaseProps>()
  const {
    register,
    formState: { errors }
  } = Context
  const {
    projectUser,
    existingForms,
    formCount,
    handleExistingFormsChange,
    allProjectsOptions,
    jobs_attribution,
    validateError
  } = useContext(List.PostHoursReleases.Context)

  return (
    <>
      {formCount > 0 &&
        allProjectsOptions.length > 0 &&
        projectUser?.map((project: any, index: number) => (
          <Container key={index}>
            <ContainerInputs>
              <Flex flexDirection='column' gap={16} width='100%'>
                <Flex
                  gap='16px'
                  flexDirection='row'
                  width='100%'
                  flexWrap='wrap'
                  alignItems='flex-end'
                >
                  <Selects.Default
                    {...register('hours_release.project.name')}
                    options={
                      allProjectsOptions as unknown as Option[]
                    }
                    value={
                      {
                        label: project?.name,
                        value: project?.project_id
                      } as Option as any
                    }
                    onSelect={(e: any) =>
                      handleExistingFormsChange(
                        index,
                        'project_id',
                        e.target.value
                      )
                    }
                    required
                    clearable={false}
                    disabled={true}
                    width={200}
                    label='Projeto'
                    placeholder={'Selecionar'}
                  />
                  <Selects.Default
                    {...register('hours_release.job.name')}
                    options={jobs_attribution as unknown as Option[]}
                    onSelect={(e: any) =>
                      handleExistingFormsChange(
                        index,
                        'job_id',
                        e.target.value
                      )
                    }
                    value={
                      {
                        label: project.job_attribution,
                        value: project.job_id
                      } as Option as any
                    }
                    required
                    clearable={false}
                    width={200}
                    label='Papel'
                    disabled={true}
                    placeholder={'Selecionar'}
                  />
                  <Inputs.Default
                    value={existingForms[index]?.hour_quantity}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleExistingFormsChange(
                        index,
                        'hour_quantity',
                        Number(e.target.value)
                      )
                    }
                    error={
                      existingForms[index]?.hour_quantity.toString()
                        .length
                        ? validateError
                        : ''
                    }
                    required
                    type='number'
                    label='Quantidade de Horas'
                    placeholder='Horas'
                    width={200}
                  />

                  <Inputs.Default
                    value={existingForms[index]?.date_release || ''}
                    onChange={(e: any) =>
                      handleExistingFormsChange(
                        index,
                        'date_release',
                        e.target.value
                      )
                    }
                    required
                    label='Período'
                    type='date'
                    width='210px'
                  />
                </Flex>
              </Flex>
            </ContainerInputs>
            <Flex flexDirection='row'>
              <ContainerTitleJustification>
                <TextTitle>Comentário</TextTitle>
                <Textarea
                  placeholder='Escreva seu comentário aqui...'
                  style={{ width: '100%', height: '110px' }}
                  rows={4}
                  maxLength={200}
                  value={existingForms[index]?.justification || ''}
                  onChange={(e: any) =>
                    handleExistingFormsChange(
                      index,
                      'justification',
                      e.target.value
                    )
                  }
                  width='100%'
                />
              </ContainerTitleJustification>
            </Flex>
          </Container>
        ))}
      {formCount > 1 && <AddHoursToAnotherProject />}
    </>
  )
}
