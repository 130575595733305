import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PaginateContext } from 'components/molecules'
import { getCurrentMondayFromDate } from 'components/utils/getCurrentMondayFromDate'

import api from 'api'
import { routes } from 'routes'

import { useDebounce } from 'hooks'

import DEFAULT from './constants'
import {
  ContextPostHoursReleases,
  HoursReleaseProps,
  JobsProps,
  PostHoursReleaseProps,
  ProjectProps
} from './types'

export const Context = createContext({} as ContextPostHoursReleases)

export const Provider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [meta, setMeta] = useState(DEFAULT.META_PROPS)
  const [filterOptionsProject, setFilterOptionsProject] = useState(
    DEFAULT.FILTER_OPTIONS_PROJECT
  )
  const [filterOptionsJobs, setFilterOptionsJobs] = useState(
    DEFAULT.FILTER_OPTIONS_JOB
  )
  const [projects, setProjects] = useState<ProjectProps[]>([])
  const [projectUser, setProjectUser] = useState<ProjectProps[]>([])
  const [hoursReleases, setHoursReleases] = useState<
    PostHoursReleaseProps[]
  >([])
  const [allProjects, setAllProjects] = useState<ProjectProps[]>([])
  const [allJobs, setAllJobs] = useState<JobsProps[]>([])
  const [formCount, setFormCount] = useState(1)
  const [existingForms, setExistingForms] = useState<
    HoursReleaseProps[]
  >([])
  const [
    additionalHoursRegistrationForm,
    setAdditionalHoursRegistrationForm
  ] = useState<HoursReleaseProps[]>([])
  const TOKEN = localStorage.getItem('@UbiRH/USER')
  const user_id = JSON.parse(TOKEN as string).id
  const [validateError, setValidateError] = useState('')
  const [validateFormError, setValidateFormError] = useState('')

  const jobs_attribution = allJobs.map(
    (job: { name: string; id: number }) => ({
      label: job.name,
      value: job.id
    })
  )

  const allProjectsOptions = allProjects.map(
    (project: { name: string; id: number }) => ({
      label: project.name,
      value: project.id
    })
  )

  const addForm = async () => {
    const newForm = {
      id: formCount,
      user_id: user_id,
      project_id: 0,
      job_id: 0,
      hour_quantity: 0,
      date_release: '',
      justification: ''
    }
    setAdditionalHoursRegistrationForm([
      ...additionalHoursRegistrationForm,
      newForm
    ] as HoursReleaseProps[])

    setFormCount(formCount + 1)
  }

  useEffect(() => {
    if (projectUser.length === 0) {
      addForm()
    } else {
      setAdditionalHoursRegistrationForm([])
    }
  }, [projectUser.length])

  const mergedFormData = [
    ...existingForms,
    ...additionalHoursRegistrationForm
  ].filter((item) => item !== undefined)

  const deleteFormProject = (id: number, event: React.MouseEvent) => {
    event.preventDefault()
    setAdditionalHoursRegistrationForm((prevForm) =>
      prevForm.filter((item) => item.id !== id)
    )
    setFormCount(formCount - 1)
  }

  const handleExistingFormsChange = (
    index: string | number,
    fieldName: string,
    value: number
  ) => {
    const updatedFormStates = [...existingForms]
    const user_id = projectUser.map((item) => item.user_id)
    const project_id = projectUser.map((item) => item.id)
    const job_id = jobs_attribution.map((item) => item.value)

    let error = ''

    const numberValue = Number(value)

    if (fieldName === 'hour_quantity' && numberValue < 0) {
      error = 'Valor deve ser maior que zero 0'
    }

    updatedFormStates[index as number] = {
      ...existingForms[index as number],
      [fieldName]: value,
      user_id: user_id.includes(value)
        ? value
        : user_id[index as number],
      project_id: project_id.includes(value)
        ? value
        : project_id[index as number],
      job_id: job_id.includes(value)
        ? value
        : job_id[index as number],
      date_week: getCurrentMondayFromDate(
        fomatDateWeek[index as number]
      ) as unknown as string
    }
    setValidateError(error)
    setExistingForms(updatedFormStates)
  }

  const handleFormChange = (
    index: string | number,
    fieldName: string,
    value: number
  ) => {
    const updatedFormState = [...additionalHoursRegistrationForm]
    const user_id = JSON.parse(TOKEN as string).id
    let error = ''
    const numberValue = Number(value)

    if (fieldName === 'hour_quantity' && numberValue < 0) {
      error = 'Valor deve ser maior que zero 0'
    }

    updatedFormState[index as number] = {
      ...additionalHoursRegistrationForm[index as number],
      [fieldName]: value,
      user_id: user_id,
      date_week: getCurrentMondayFromDate(
        fomatDateWeekFormAdd[index as number]
      ) as unknown as string
    }

    setValidateFormError(error)
    setAdditionalHoursRegistrationForm(updatedFormState)
  }

  const ContextPostHoursReleases = {
    hoursReleases,
    projects,
    projectUser,
    existingForms,
    mergedFormData,
    validateError,
    validateFormError,
    additionalHoursRegistrationForm,
    allProjectsOptions,
    jobs_attribution,
    formCount,
    setFormCount,
    filterOptionsProject,
    filterOptionsJobs,
    navigateTo,
    handleSearch,
    handleOrder,
    isLoading,
    meta,
    paginate: { ...meta.pagination, setCurrent_page: setPage },
    fetchList,
    handleFilterProject,
    handleReleaseDate,
    addForm,
    handleExistingFormsChange,
    handleFormChange,
    deleteFormProject
  }

  async function fetchList() {
    setIsLoading(true)
    try {
      const { data } = await api.get(
        routes.hours.castingHours.listHours + '?limit=5',
        {
          params: {
            page: meta.pagination.current_page,
            search: meta.search,
            order: meta.order,
            orderField: meta.orderField,
            project_id: meta.project_id,
            date_start: meta.date_start,
            date_end: meta.date_end
          }
        }
      )

      setHoursReleases(data.data)

      setMeta((old) => ({
        ...old,
        pagination: {
          ...old.pagination,
          last_page: data.meta.last_page
        }
      }))
      setIsLoading(false)

      return data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async function fetchUserProject() {
    const { data } = await api.get(routes.projectUsers.list)
    setProjects(data)
    setFilterOptionsProject({
      project: data.map(
        ({ name, id }: { name: string; id: number }) => ({
          label: name,
          value: id
        })
      )
    })
    setProjectUser(data)
    setFilterOptionsJobs({
      job: data.map(
        ({
          job_attribution,
          id
        }: {
          job_attribution: string
          id: number
        }) => ({
          label: job_attribution,
          value: id
        })
      )
    })
  }

  async function fetchProjectUser() {
    const { data } = await api.get(routes.usersProjects.list)
    setAllProjects(data)
  }

  async function fetchAllJobs() {
    const { data } = await api.get(routes.job.listAll, {
      params: { is_active: 1 }
    })
    setAllJobs(data.data)
  }

  function setPage(current_page: number) {
    setMeta((old) => ({
      ...old,
      pagination: { ...old.pagination, current_page }
    }))
  }

  function handleSearch(search: string) {
    setMeta((old) => ({
      ...old,
      search,
      pagination: { ...old.pagination, current_page: 1 }
    }))
  }

  function handleOrder(field: string) {
    setMeta((old) => ({
      ...old,
      orderField: field,
      order: old.order === 'ASC' ? 'DESC' : 'ASC'
    }))
  }

  function handleFilterProject(project_id: number) {
    setMeta((old) => ({
      ...old,
      project_id,
      pagination: { ...old.pagination, current_page: 1 }
    }))
  }

  function handleReleaseDate(start: string, end: string) {
    setMeta((old) => ({
      ...old,
      date_start: start,
      date_end: start,
      pagination: { ...old.pagination, current_page: 1 }
    }))
  }

  function navigateTo(url: string) {
    navigate(url)
  }

  const fomatDateWeek = existingForms.map(
    (item) => item?.date_release
  )
  const fomatDateWeekFormAdd = additionalHoursRegistrationForm.map(
    (item) => item?.date_release
  )

  useDebounce({
    fn: fetchList,
    listener: [
      meta.pagination.current_page,
      meta.search,
      meta.order,
      meta.orderField,
      meta.project_id,
      meta.date_start,
      meta.date_end
    ]
  })

  useDebounce({
    fn: fetchUserProject,
    delay: 0,
    listener: []
  })
  useDebounce({
    fn: fetchProjectUser,
    delay: 0,
    listener: []
  })

  useDebounce({
    fn: fetchAllJobs,
    delay: 0,
    listener: []
  })

  return (
    <Context.Provider value={ContextPostHoursReleases}>
      <PaginateContext.Provider
        value={{ paginate: ContextPostHoursReleases.paginate }}
      >
        {children}
      </PaginateContext.Provider>
    </Context.Provider>
  )
}
