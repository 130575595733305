import { useContext } from 'react'

import { DatePicker } from '@stardust-ds/react'
import { List } from 'contexts'

import { IconGlass, Inputs, Selects } from 'components/atoms'
import { convertDateFormat } from 'components/organisms/Forms/Team/logic'

import { Container, Main } from '../style'
import { Option } from 'types'

export const TechLead = () => {
  const {
    meta,
    handleDate,
    filterOptions,
    handleFilterProject,
    handleFilterStatus,
    handleSearch
  } = useContext(List.TechLeadHours.Context)

  const { search } = meta

  return (
    <Main>
      <Container gap='1em'>
        <Inputs.Default
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Poppins, sans-serif'
          }}
          value={search}
          iconLeft={<IconGlass />}
          placeholder='Buscar ...'
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Selects.Default
          width={230}
          height={42}
          options={filterOptions.project}
          placeholder='Projeto'
          onSelect={
            ((value: Option | null) =>
              value &&
              handleFilterProject(Number(value.value))) as any
          }
          onClear={() => handleFilterProject(null)}
        />
        <Selects.Default
          width={230}
          height={42}
          options={filterOptions.status}
          placeholder='Status'
          onSelect={
            ((value: Option | null) =>
              value && handleFilterStatus(Number(value.value))) as any
          }
          onClear={() => handleFilterStatus(null)}
        />
        <DatePicker
          onChange={(date) =>
            date.length > 0 &&
            handleDate(
              convertDateFormat(String(date[0])),
              convertDateFormat(String(date[1]))
            )
          }
          inputStartProps={{
            placeholder: 'Início do período'
          }}
          hasEndDate
          inputEndProps={{
            placeholder: 'Fim do periodo'
          }}
          weekDays={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']}
        />
      </Container>
    </Main>
  )
}
