export const IconEyeSlash = ({
  width = '18px',
  height = '16px',
  color = 'black',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.7702 11.7275L13.1842 9.70068C13.9679 8.90215 14.6278 7.93047 15.1132 6.82562C15.1609 6.7149 15.2 6.52393 15.2 6.4C15.2 6.27558 15.1609 6.08505 15.1132 5.97433C13.757 2.88813 11.0734 0.8 7.99997 0.8C6.43327 0.8 4.97302 1.35165 3.73357 2.29345L0.970348 0.127725C0.860173 0.0418 0.729723 0 0.600798 0C0.422673 0 0.246123 0.0789 0.128149 0.2297C-0.0765264 0.490625 -0.0312264 0.867925 0.229723 1.07223L15.0296 12.6721C15.2921 12.8772 15.6687 12.8303 15.8718 12.5701C16.0765 12.3092 16.0312 11.9318 15.7702 11.7275ZM9.85984 7.09512L7.81852 5.49518C7.92419 5.2831 7.99997 5.05375 7.99997 4.8C7.99997 4.663 7.9781 4.53192 7.9454 4.40552C7.96395 4.40497 7.98127 4.4 7.99997 4.4C9.10277 4.4 9.99997 5.29722 9.99997 6.40137C9.99997 6.64775 9.94192 6.8772 9.85984 7.09512ZM10.8301 7.85563C11.0596 7.4191 11.2 6.9297 11.2 6.40137V6.4C11.2 4.6337 9.76667 3.2 7.99997 3.2C7.1948 3.2 6.46717 3.5043 5.90822 3.9979L4.70785 3.05713C5.70037 2.37583 6.82815 2 7.99997 2C10.487 2 12.7791 3.68745 13.9894 6.39985C13.5379 7.40948 12.9291 8.2607 12.2242 8.94825L10.8301 7.85563ZM8.0014 9.6C8.33684 9.6 8.65392 9.53393 8.95779 9.43808L7.57082 8.35102C6.97635 8.22022 6.48635 7.82213 6.21937 7.29175L4.82062 6.19545C4.8162 6.2644 4.79997 6.32993 4.79997 6.4C4.79997 8.1663 6.23332 9.6 7.99997 9.6H8.0014ZM7.99997 10.8C5.51302 10.8 3.22087 9.11255 2.01057 6.4001C2.28142 5.79448 2.61287 5.24938 2.98095 4.75358L2.03192 4.00978C1.58772 4.60333 1.20027 5.26065 0.886698 5.97433C0.839048 6.08505 0.799973 6.27603 0.799973 6.4C0.799973 6.52443 0.839048 6.7149 0.886698 6.82562C2.24295 9.91182 4.92655 12 7.99997 12C9.13644 12 10.2191 11.7128 11.2016 11.1967L10.1301 10.3569C9.45074 10.6359 8.73644 10.8 7.99997 10.8Z'
        fill='#3B454F'
      />
    </svg>
  )
}
