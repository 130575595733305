export const LegalNature = [
  {
    label: 'Órgão Público do Poder Executivo Federal',
    value: '1015'
  },
  {
    label:
      'Órgão Público do Poder Executivo Estadual ou do Distrito Federal',
    value: '1023'
  },
  {
    label: 'Órgão Público do Poder Executivo Municipal',
    value: '1031'
  },
  {
    label: 'Órgão Público do Poder Legislativo Federal',
    value: '1040'
  },
  {
    label:
      'Órgão Público do Poder Legislativo Estadual ou do Distrito Federal',
    value: '1058'
  },
  {
    label: 'Órgão Público do Poder Legislativo Municipal',
    value: '1066'
  },
  {
    label: 'Órgão Público do Poder Judiciário Federal',
    value: '1074'
  },
  {
    label: 'Órgão Público do Poder Judiciário Estadual',
    value: '1082'
  },
  { label: 'Autarquia Federal', value: '1104' },
  {
    label: 'Autarquia Estadual ou do Distrito Federal',
    value: '1112'
  },
  { label: 'Autarquia Municipal', value: '1120' },
  {
    label: 'Fundação Pública de Direito Público Federal',
    value: '1139'
  },
  {
    label:
      'Fundação Pública de Direito Público Estadual ou do Distrito Federal',
    value: '1147'
  },
  {
    label: 'Fundação Pública de Direito Público Municipal',
    value: '1155'
  },
  { label: 'Órgão Público Autônomo Federal', value: '1163' },
  {
    label: 'Órgão Público Autônomo Estadual ou do Distrito Federal',
    value: '1171'
  },
  { label: 'Órgão Público Autônomo Municipal', value: '1180' },
  { label: 'Comissão Polinacional', value: '1198' },
  {
    label:
      'Consórcio Público de Direito Público (Associação Pública)',
    value: '1210'
  },
  { label: 'Consórcio Público de Direito Privado', value: '1228' },
  { label: 'Estado ou Distrito Federal', value: '1236' },
  { label: 'Município', value: '1244' },
  {
    label: 'Fundação Pública de Direito Privado Federal',
    value: '1252'
  },
  {
    label:
      'Fundação Pública de Direito Privado Estadual ou do Distrito Federal',
    value: '1260'
  },
  {
    label: 'Fundação Pública de Direito Privado Municipal',
    value: '1279'
  },
  {
    label: 'Fundo Público da Administração Indireta Federal',
    value: '1287'
  },
  {
    label:
      'Fundo Público da Administração Indireta Estadual ou do Distrito Federal',
    value: '1295'
  },
  {
    label: 'Fundo Público da Administração Indireta Municipal',
    value: '1309'
  },
  {
    label: 'Fundo Público da Administração Direta Federal',
    value: '1317'
  },
  {
    label:
      'Fundo Público da Administração Direta Estadual ou do Distrito Federal',
    value: '1325'
  },
  {
    label: 'Fundo Público da Administração Direta Municipal',
    value: '1333'
  },
  { label: 'União', value: '1341' },
  { label: 'Empresa Pública', value: '2011' },
  { label: 'Sociedade de Economia Mista', value: '2038' },
  { label: 'Sociedade Anônima Aberta', value: '2046' },
  { label: 'Sociedade Anônima Fechada', value: '2054' },
  { label: 'Sociedade Empresária Limitada', value: '2062' },
  { label: 'Sociedade Empresária em Nome Coletivo', value: '2070' },
  {
    label: 'Sociedade Empresária em Comandita Simples',
    value: '2089'
  },
  {
    label: 'Sociedade Empresária em Comandita por Ações',
    value: '2097'
  },
  { label: 'Sociedade em Conta de Participação', value: '2127' },
  { label: 'Empresário (Individual)', value: '2135' },
  { label: 'Cooperativa', value: '2143' },
  { label: 'Consórcio de Sociedades', value: '2151' },
  { label: 'Grupo de Sociedades', value: '2160' },
  {
    label: 'Estabelecimento, no Brasil, de Sociedade Estrangeira',
    value: '2178'
  },
  {
    label:
      'Estabelecimento, no Brasil, de Empresa Binacional Argentino Brasileira',
    value: '2194'
  },
  { label: 'Empresa Domiciliada no Exterior', value: '2216' },
  { label: 'Clube/Fundo de Investimento', value: '2224' },
  { label: 'Sociedade Simples Pura', value: '2232' },
  { label: 'Sociedade Simples Limitada', value: '2240' },
  { label: 'Sociedade Simples em Nome Coletivo', value: '2259' },
  { label: 'Sociedade Simples em Comandita Simples', value: '2267' },
  { label: 'Empresa Binacional', value: '2275' },
  { label: 'Consórcio de Empregadores', value: '2283' },
  { label: 'Consórcio Simples', value: '2291' },
  {
    label:
      'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)',
    value: '2305'
  },
  {
    label:
      'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)',
    value: '2313'
  },
  {
    label: 'Sociedade Unipessoal de Advogados',
    value: '2321'
  },
  { label: 'Cooperativas de Consumo', value: '2330' },
  {
    label: 'Inova Simples',
    value: '2348'
  },
  { label: 'Investidor Não Residente', value: '2356' },
  {
    label: 'Serviço Notarial e Registral (Cartório)',
    value: '3034'
  },
  { label: 'Fundação Privada', value: '3069' },
  { label: 'Serviço Social Autônomo', value: '3077' },
  { label: 'Condomínio Edilício', value: '3085' },
  { label: 'Comissão de Conciliação Prévia', value: '3107' },
  { label: 'Entidade de Mediação e Arbitragem', value: '3115' },
  { label: 'Entidade Sindical', value: '3131' },
  {
    label:
      'Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras',
    value: '3204'
  },
  {
    label: 'Fundação ou Associação Domiciliada no Exterior',
    value: '3212'
  },
  { label: 'Organização Religiosa', value: '3220' },
  { label: 'Comunidade Indígena', value: '3239' },
  { label: 'Fundo Privado', value: '3247' },
  {
    label: 'Órgão de Direção Nacional de Partido Político',
    value: '3255'
  },
  {
    label: 'Órgão de Direção Regional de Partido Político',
    value: '3263'
  },
  {
    label: 'Órgão de Direção Local de Partido Político',
    value: '3271'
  },
  { label: 'Comitê Financeiro de Partido Político', value: '3280' },
  { label: 'Frente Plebiscitária ou Referendária', value: '3298' },
  { label: 'Organização Social (OS)', value: '3301' },
  { label: 'Demais Condomínios', value: '3310' },
  {
    label: 'Plano de Benefícios de Previdência Complementar Fechada',
    value: '3328'
  },
  { label: 'Associação Privada', value: '3999' },
  { label: 'Empresa Individual Imobiliária', value: '4014' },
  { label: 'Segurado Especial', value: '4022' },
  { label: 'Contribuinte individual', value: '4081' },
  { label: 'Candidato a Cargo Político Eletivo', value: '4090' },
  { label: 'Leiloeiro', value: '4111' },
  { label: 'Produtor Rural (Pessoa Física)', value: '4120' },
  { label: 'Organização Internacional', value: '5010' },
  { label: 'Representação Diplomática Estrangeira', value: '5029' },
  { label: 'Outras Instituições Extraterritoriais', value: '5037' }
]
