export const IconEye = ({
  width = '18px',
  height = '16px',
  color = 'black',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.9036 6.63817C14.3967 3.20906 11.4149 0.888916 8 0.888916C4.58506 0.888916 1.60244 3.21058 0.0963611 6.63817C0.0433889 6.76119 0 6.97339 0 7.11114C0 7.24939 0.0433889 7.46103 0.0963611 7.58405C1.60331 11.0132 4.58506 13.3334 8 13.3334C11.4149 13.3334 14.3976 11.0116 15.9036 7.58405C15.9566 7.46103 16 7.24883 16 7.11114C16 6.97289 15.9566 6.76119 15.9036 6.63817ZM8 12C5.23669 12 2.68989 10.1251 1.34511 7.11125C2.69472 4.0935 5.23919 2.22225 8 2.22225C10.7633 2.22225 13.3101 4.09719 14.6549 7.11097C13.3053 10.1288 10.7608 12 8 12ZM8 3.55558C6.03706 3.55558 4.44444 5.14858 4.44444 7.11114C4.44444 9.07369 6.03706 10.6667 8 10.6667H8.00158C9.96375 10.6667 11.5556 9.07447 11.5556 7.11267V7.11114C11.5556 5.14858 9.963 3.55558 8 3.55558ZM8 9.33336C6.77469 9.33336 5.77778 8.33644 5.77778 7.11114C5.77778 7.09036 5.78331 7.07111 5.78392 7.05047C5.92436 7.08683 6.07 7.11114 6.22222 7.11114C7.20406 7.11114 8 6.31519 8 5.33336C8 5.18114 7.97569 5.0355 7.93933 4.89505C7.95997 4.89444 7.97922 4.88892 8 4.88892C9.22531 4.88892 10.2222 5.88583 10.2222 7.11267C10.2222 8.33717 9.22603 9.33336 8 9.33336Z'
        fill='#3B454F'
      />
    </svg>
  )
}
