export default () => {
  return (
    <div>
      <svg
        width='672'
        height='595'
        viewBox='0 0 672 595'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M657.403 548.498H604.516V548.898H657.403V548.498Z'
          fill='#EBEBEB'
        />
        <path
          d='M467.89 552.842H454.013V553.241H467.89V552.842Z'
          fill='#EBEBEB'
        />
        <path
          d='M602.918 533.68H572.275V534.079H602.918V533.68Z'
          fill='#EBEBEB'
        />
        <path
          d='M91.7225 536.362H22.755V536.762H91.7225V536.362Z'
          fill='#EBEBEB'
        />
        <path
          d='M116.058 536.362H105.95V536.762H116.058V536.362Z'
          fill='#EBEBEB'
        />
        <path
          d='M298.513 543.101H148.921V543.5H298.513V543.101Z'
          fill='#EBEBEB'
        />
        <path
          d='M317.436 451.586H9.10199C6.68652 451.582 4.37144 450.619 2.66494 448.91C0.958444 447.2 -3.70423e-06 444.884 0 442.468V9.03811C0.0210271 6.6365 0.988753 4.34018 2.69298 2.64791C4.39721 0.955642 6.70029 0.00411408 9.10199 0H317.436C319.854 0 322.173 0.960639 323.883 2.67059C325.593 4.38053 326.554 6.69972 326.554 9.11795V442.468C326.554 444.886 325.593 447.206 323.883 448.916C322.173 450.626 319.854 451.586 317.436 451.586V451.586ZM9.10199 0.319369C6.7924 0.323599 4.57885 1.24404 2.94722 2.87866C1.3156 4.51329 0.399206 6.72852 0.39921 9.03811V442.468C0.399206 444.778 1.3156 446.993 2.94722 448.628C4.57885 450.262 6.7924 451.183 9.10199 451.187H317.436C319.747 451.183 321.962 450.263 323.596 448.629C325.23 446.994 326.15 444.779 326.154 442.468V9.03811C326.15 6.72705 325.23 4.51186 323.596 2.87769C321.962 1.24352 319.747 0.32359 317.436 0.319369H9.10199Z'
          fill='#EBEBEB'
        />
        <path
          d='M662.848 451.586H354.498C352.081 451.582 349.765 450.62 348.056 448.911C346.347 447.202 345.385 444.885 345.38 442.468V9.03811C345.406 6.63505 346.377 4.33874 348.084 2.64691C349.79 0.955083 352.095 0.00406921 354.498 0H662.848C665.247 0.00831074 667.546 0.961695 669.247 2.65351C670.947 4.34533 671.913 6.63926 671.934 9.03811V442.468C671.934 444.881 670.978 447.195 669.275 448.904C667.572 450.613 665.261 451.578 662.848 451.586V451.586ZM354.498 0.319369C352.187 0.32359 349.972 1.24352 348.338 2.87769C346.704 4.51186 345.784 6.72705 345.78 9.03811V442.468C345.784 444.779 346.704 446.994 348.338 448.629C349.972 450.263 352.187 451.183 354.498 451.187H662.848C665.159 451.183 667.374 450.263 669.008 448.629C670.643 446.994 671.562 444.779 671.567 442.468V9.03811C671.562 6.72705 670.643 4.51186 669.008 2.87769C667.374 1.24352 665.159 0.32359 662.848 0.319369H354.498Z'
          fill='#EBEBEB'
        />
        <path
          d='M287.751 267.646L597.537 267.646V27.5454L287.751 27.5454L287.751 267.646Z'
          fill='#ACB4BA'
        />
        <path
          d='M281.299 267.646L591.086 267.646V27.5454L281.299 27.5454L281.299 267.646Z'
          fill='#F2F5F8'
        />
        <path
          d='M266.369 281.539L597.553 281.539V267.646L266.369 267.646V281.539Z'
          fill='#ACB4BA'
        />
        <path
          d='M259.087 281.539L575.996 281.539V267.646L259.087 267.646V281.539Z'
          fill='#F2F5F8'
        />
        <path
          d='M579.525 255.143V40.0168L292.892 40.0168V255.143L579.525 255.143Z'
          fill='#E9EBEE'
        />
        <path
          d='M399.37 255.143L479.643 40.0168H397.789L317.531 255.143H399.37Z'
          fill='white'
        />
        <path
          d='M458.548 255.143L538.805 40.0168H493.711L413.454 255.143H458.548Z'
          fill='white'
        />
        <path
          d='M442.66 255.143V40.0168H429.757V255.143H442.66Z'
          fill='#F2F5F8'
        />
        <path
          d='M297.747 255.143V40.0168H292.876V255.143H297.747Z'
          fill='#ACB4BA'
        />
        <path
          d='M447.514 255.143V40.0168H442.644V255.143H447.514Z'
          fill='#ACB4BA'
        />
        <path
          d='M83.0835 276.796H204.332L204.332 27.5454L83.0835 27.5454L83.0835 276.796Z'
          fill='#ACB4BA'
        />
        <path
          d='M65.9654 276.796H199.781L199.781 27.5454L65.9654 27.5454L65.9654 276.796Z'
          fill='#F2F5F8'
        />
        <path
          d='M178.095 255.111V49.2306L87.6505 49.2306L87.6505 255.111H178.095Z'
          fill='white'
        />
        <path
          d='M157.097 198.647V105.679C157.097 100.908 153.229 97.0399 148.458 97.0399H117.304C112.533 97.0399 108.665 100.908 108.665 105.679V198.647C108.665 203.418 112.533 207.286 117.304 207.286H148.458C153.229 207.286 157.097 203.418 157.097 198.647Z'
          fill='#F2F5F8'
        />
        <path
          d='M67.179 325.58H56.9113C46.3284 325.58 37.7492 334.159 37.7492 344.742V476.385C37.7492 486.968 46.3284 495.547 56.9113 495.547H67.179C77.7619 495.547 86.341 486.968 86.341 476.385V344.742C86.341 334.159 77.7619 325.58 67.179 325.58Z'
          fill='#F2F5F8'
        />
        <path
          d='M77.5584 331.248H74.3807C66.1878 331.248 59.5461 337.89 59.5461 346.083V475.028C59.5461 483.221 66.1878 489.862 74.3807 489.862H77.5584C85.7514 489.862 92.3931 483.221 92.3931 475.028V346.083C92.3931 337.89 85.7514 331.248 77.5584 331.248Z'
          fill='#EBEBEB'
        />
        <path
          d='M102.485 325.58H92.2175C81.6346 325.58 73.0554 334.159 73.0554 344.742V476.385C73.0554 486.968 81.6346 495.547 92.2175 495.547H102.485C113.068 495.547 121.647 486.968 121.647 476.385V344.742C121.647 334.159 113.068 325.58 102.485 325.58Z'
          fill='#F2F5F8'
        />
        <path
          d='M112.849 331.248H109.671C101.478 331.248 94.8362 337.89 94.8362 346.083V475.028C94.8362 483.221 101.478 489.862 109.671 489.862H112.849C121.042 489.862 127.683 483.221 127.683 475.028V346.083C127.683 337.89 121.042 331.248 112.849 331.248Z'
          fill='#EBEBEB'
        />
        <path
          d='M137.775 325.58H127.508C116.925 325.58 108.345 334.159 108.345 344.742V476.385C108.345 486.968 116.925 495.547 127.508 495.547H137.775C148.358 495.547 156.937 486.968 156.937 476.385V344.742C156.937 334.159 148.358 325.58 137.775 325.58Z'
          fill='#F2F5F8'
        />
        <path
          d='M148.139 331.248H144.961C136.768 331.248 130.126 337.89 130.126 346.083V475.028C130.126 483.221 136.768 489.862 144.961 489.862H148.139C156.332 489.862 162.973 483.221 162.973 475.028V346.083C162.973 337.89 156.332 331.248 148.139 331.248Z'
          fill='#EBEBEB'
        />
        <path
          d='M173.065 325.58H162.798C152.215 325.58 143.636 334.159 143.636 344.742V476.385C143.636 486.968 152.215 495.547 162.798 495.547H173.065C183.648 495.547 192.227 486.968 192.227 476.385V344.742C192.227 334.159 183.648 325.58 173.065 325.58Z'
          fill='#F2F5F8'
        />
        <path
          d='M183.429 331.248H180.251C172.058 331.248 165.417 337.89 165.417 346.083V475.028C165.417 483.221 172.058 489.862 180.251 489.862H183.429C191.622 489.862 198.263 483.221 198.263 475.028V346.083C198.263 337.89 191.622 331.248 183.429 331.248Z'
          fill='#EBEBEB'
        />
        <path
          d='M208.356 325.58H198.088C187.505 325.58 178.926 334.159 178.926 344.742V476.385C178.926 486.968 187.505 495.547 198.088 495.547H208.356C218.939 495.547 227.518 486.968 227.518 476.385V344.742C227.518 334.159 218.939 325.58 208.356 325.58Z'
          fill='#F2F5F8'
        />
        <path
          d='M218.719 331.248H215.557C207.364 331.248 200.723 337.89 200.723 346.083V475.028C200.723 483.221 207.364 489.862 215.557 489.862H218.719C226.912 489.862 233.554 483.221 233.554 475.028V346.083C233.554 337.89 226.912 331.248 218.719 331.248Z'
          fill='#EBEBEB'
        />
        <path
          d='M243.662 325.58H233.394C222.811 325.58 214.232 334.159 214.232 344.742V476.385C214.232 486.968 222.811 495.547 233.394 495.547H243.662C254.245 495.547 262.824 486.968 262.824 476.385V344.742C262.824 334.159 254.245 325.58 243.662 325.58Z'
          fill='#F2F5F8'
        />
        <path
          d='M254.009 331.248H250.847C242.655 331.248 236.013 337.89 236.013 346.083V475.028C236.013 483.221 242.655 489.862 250.847 489.862H254.009C262.202 489.862 268.844 483.221 268.844 475.028V346.083C268.844 337.89 262.202 331.248 254.009 331.248Z'
          fill='#EBEBEB'
        />
        <path
          d='M278.952 325.58H268.684C258.101 325.58 249.522 334.159 249.522 344.742V476.385C249.522 486.968 258.101 495.547 268.684 495.547H278.952C289.535 495.547 298.114 486.968 298.114 476.385V344.742C298.114 334.159 289.535 325.58 278.952 325.58Z'
          fill='#F2F5F8'
        />
        <path
          d='M301.371 476.657H259.087C258.24 476.657 257.428 476.32 256.829 475.721C256.23 475.122 255.893 474.31 255.893 473.463C255.893 472.616 256.23 471.804 256.829 471.205C257.428 470.606 258.24 470.269 259.087 470.269H301.371C302.218 470.269 303.031 470.606 303.63 471.205C304.229 471.804 304.565 472.616 304.565 473.463C304.565 474.31 304.229 475.122 303.63 475.721C303.031 476.32 302.218 476.657 301.371 476.657Z'
          fill='#F2F5F8'
        />
        <path
          d='M301.371 355.025H259.087C258.24 355.025 257.428 354.689 256.829 354.09C256.23 353.491 255.893 352.679 255.893 351.832C255.893 350.985 256.23 350.172 256.829 349.573C257.428 348.974 258.24 348.638 259.087 348.638H301.371C302.218 348.638 303.031 348.974 303.63 349.573C304.229 350.172 304.565 350.985 304.565 351.832C304.565 352.679 304.229 353.491 303.63 354.09C303.031 354.689 302.218 355.025 301.371 355.025Z'
          fill='#F2F5F8'
        />
        <path
          d='M416.951 386.531H535.292C538.352 386.531 541.286 385.317 543.45 383.155C545.615 380.993 546.833 378.061 546.838 375.002V375.002C546.833 371.943 545.615 369.01 543.45 366.848C541.286 364.687 538.352 363.473 535.292 363.473H416.951C413.893 363.473 410.96 364.687 408.798 366.849C406.636 369.012 405.421 371.944 405.421 375.002C405.421 376.516 405.72 378.015 406.299 379.414C406.878 380.813 407.728 382.084 408.798 383.154C409.869 384.225 411.14 385.074 412.539 385.653C413.937 386.233 415.437 386.531 416.951 386.531Z'
          fill='#EBEBEB'
        />
        <path
          d='M478.717 324.286C474.351 323.369 470.527 320.757 468.084 317.024C465.641 313.291 464.779 308.741 465.686 304.373L482.485 224.021C482.935 221.857 483.808 219.803 485.053 217.977C486.298 216.15 487.891 214.587 489.74 213.377C491.589 212.167 493.659 211.333 495.831 210.924C498.003 210.514 500.234 210.537 502.398 210.99V210.99C504.563 211.439 506.618 212.31 508.447 213.554C510.275 214.798 511.839 216.391 513.051 218.24C514.263 220.09 515.098 222.161 515.509 224.333C515.919 226.506 515.897 228.739 515.444 230.903L498.661 311.256C498.209 313.422 497.334 315.478 496.087 317.305C494.839 319.133 493.243 320.697 491.391 321.907C489.538 323.117 487.465 323.95 485.291 324.359C483.116 324.767 480.882 324.742 478.717 324.286V324.286Z'
          fill='#CCD1D6'
        />
        <path
          d='M468.561 322.562C466.692 322.847 464.785 322.761 462.95 322.309C461.114 321.856 459.386 321.046 457.864 319.925C456.342 318.803 455.056 317.393 454.08 315.774C453.105 314.155 452.458 312.359 452.177 310.489L441.686 241.059C441.402 239.191 441.49 237.286 441.943 235.453C442.397 233.619 443.207 231.893 444.329 230.372C445.45 228.852 446.86 227.568 448.478 226.593C450.096 225.618 451.89 224.972 453.758 224.691C455.627 224.406 457.533 224.491 459.369 224.944C461.204 225.396 462.932 226.206 464.454 227.328C465.976 228.449 467.262 229.86 468.238 231.479C469.214 233.098 469.861 234.894 470.141 236.763L480.633 306.178C480.918 308.047 480.832 309.953 480.38 311.789C479.927 313.624 479.117 315.353 477.996 316.875C476.875 318.396 475.464 319.682 473.845 320.658C472.226 321.634 470.43 322.281 468.561 322.562V322.562Z'
          fill='#CCD1D6'
        />
        <path
          d='M488.984 245.322C487.833 245.826 486.53 245.857 485.356 245.409C484.182 244.961 483.231 244.07 482.709 242.927L473.255 221.817C472.756 220.669 472.725 219.372 473.17 218.202C473.614 217.032 474.499 216.083 475.635 215.557V215.557C476.786 215.054 478.089 215.023 479.262 215.471C480.436 215.919 481.387 216.81 481.91 217.953L491.363 239.063C491.863 240.21 491.894 241.508 491.449 242.677C491.005 243.847 490.12 244.797 488.984 245.322V245.322Z'
          fill='#CCD1D6'
        />
        <path
          d='M517.583 247.35C518.371 248.325 518.746 249.57 518.626 250.818C518.507 252.066 517.903 253.217 516.945 254.025L499.076 268.732C498.099 269.518 496.853 269.89 495.605 269.768C494.357 269.645 493.207 269.038 492.401 268.077V268.077C491.615 267.101 491.243 265.854 491.366 264.606C491.488 263.358 492.095 262.208 493.056 261.403L510.909 246.712C511.883 245.924 513.128 245.549 514.376 245.669C515.624 245.788 516.776 246.392 517.583 247.35V247.35Z'
          fill='#CCD1D6'
        />
        <path
          d='M466.357 221.961C467.012 222.254 467.526 222.794 467.786 223.464C468.046 224.133 468.032 224.879 467.746 225.538L462.429 237.626C462.135 238.281 461.595 238.794 460.925 239.055C460.256 239.315 459.511 239.3 458.852 239.015V239.015C458.196 238.721 457.683 238.181 457.423 237.512C457.163 236.842 457.177 236.097 457.462 235.438L462.78 223.35C463.074 222.694 463.614 222.181 464.283 221.921C464.953 221.661 465.698 221.675 466.357 221.961V221.961Z'
          fill='#CCD1D6'
        />
        <path
          d='M437.901 246.312C438.425 245.82 439.122 245.555 439.841 245.576C440.559 245.597 441.24 245.902 441.734 246.424L450.804 256.005C451.296 256.529 451.561 257.226 451.54 257.945C451.519 258.663 451.214 259.344 450.692 259.838C450.169 260.33 449.471 260.595 448.753 260.574C448.034 260.553 447.353 260.248 446.86 259.726L437.789 250.145C437.297 249.621 437.032 248.924 437.053 248.206C437.074 247.487 437.379 246.806 437.901 246.312Z'
          fill='#CCD1D6'
        />
        <path
          d='M507.396 363.473H444.863L431.817 301.803H520.442L507.396 363.473Z'
          fill='#ACB4BA'
        />
        <path
          d='M514.566 312.438H437.694C434.873 312.438 432.168 311.317 430.174 309.323C428.179 307.328 427.059 304.623 427.059 301.803C427.063 298.985 428.185 296.284 430.179 294.293C432.173 292.302 434.876 291.184 437.694 291.184H514.566C517.383 291.184 520.086 292.302 522.08 294.293C524.074 296.284 525.196 298.985 525.2 301.803V301.803C525.2 304.623 524.08 307.328 522.086 309.323C520.091 311.317 517.386 312.438 514.566 312.438Z'
          fill='#F2F5F8'
        />
        <path
          d='M402.883 522.805L428.879 386.531H450.644L411.138 522.805H402.883Z'
          fill='#CCD1D6'
        />
        <path
          d='M549.376 522.805L523.396 386.531H501.615L541.121 522.805H549.376Z'
          fill='#CCD1D6'
        />
        <path
          d='M501.615 522.805L482.725 386.531H460.96L493.36 522.805H501.615Z'
          fill='#CCD1D6'
        />
        <path
          d='M338.195 594.919C509.188 594.919 647.806 586.826 647.806 576.842C647.806 566.859 509.188 558.766 338.195 558.766C167.201 558.766 28.5834 566.859 28.5834 576.842C28.5834 586.826 167.201 594.919 338.195 594.919Z'
          fill='#F2F5F8'
        />
        <path
          d='M159.971 439.658H86.932C85.6615 439.658 84.443 439.153 83.5446 438.255C82.6462 437.356 82.1415 436.138 82.1415 434.867C82.1415 433.597 82.6462 432.378 83.5446 431.48C84.443 430.581 85.6615 430.077 86.932 430.077H159.971C161.242 430.077 162.46 430.581 163.359 431.48C164.257 432.378 164.762 433.597 164.762 434.867C164.762 436.138 164.257 437.356 163.359 438.255C162.46 439.153 161.242 439.658 159.971 439.658V439.658Z'
          fill='#0066FF'
        />
        <path
          opacity='0.8'
          d='M159.971 439.658H86.932C85.6615 439.658 84.443 439.153 83.5446 438.255C82.6462 437.356 82.1415 436.138 82.1415 434.867C82.1415 433.597 82.6462 432.378 83.5446 431.48C84.443 430.581 85.6615 430.077 86.932 430.077H159.971C161.242 430.077 162.46 430.581 163.359 431.48C164.257 432.378 164.762 433.597 164.762 434.867C164.762 436.138 164.257 437.356 163.359 438.255C162.46 439.153 161.242 439.658 159.971 439.658V439.658Z'
          fill='white'
        />
        <path
          d='M225.745 562.918L236.508 557.983L231.318 530.198L220.571 535.149L225.745 562.918Z'
          fill='#7F3E3B'
        />
        <path
          d='M235.997 555.029L223.909 560.57C223.701 560.655 223.536 560.819 223.449 561.026C223.363 561.232 223.361 561.465 223.446 561.672L227.246 571.509C227.476 572.012 227.895 572.404 228.412 572.601C228.928 572.798 229.502 572.785 230.009 572.563C234.192 570.551 236.077 569.369 241.394 566.942C244.668 565.441 258.736 559.405 263.239 557.361C267.742 555.317 265.586 550.878 263.478 551.357C254.073 553.48 242.288 555.86 238.153 554.87C237.436 554.678 236.677 554.735 235.997 555.029V555.029Z'
          fill='#2C2E33'
        />
        <path
          opacity='0.2'
          d='M220.555 535.149L231.318 530.214L234.001 544.538L223.222 549.472L220.555 535.149Z'
          fill='#15161A'
        />
        <path
          d='M149.863 384.247C149.863 384.247 241.969 390.842 238.248 424.584C232.915 473.08 237.306 534.43 237.306 534.43L217.25 542.702C217.25 542.702 199.685 474.341 204.364 436.544C181.385 426.963 119.076 436.145 111.747 416.296C108.164 406.011 107.219 394.992 109 384.247H149.863Z'
          fill='#2C2E33'
        />
        <path
          opacity='0.3'
          d='M194.735 412.336C194.735 412.336 192.499 422.364 190.966 433.254H191.174L193.537 433.622L194.735 433.845L196.092 434.101C197.942 434.467 199.771 434.936 201.569 435.506C202 435.634 202.399 435.777 202.799 435.921C203.198 436.065 203.868 436.304 204.395 436.512C203.842 441.17 203.57 445.857 203.581 450.548C217.553 430.029 205.545 412.336 194.735 412.336Z'
          fill='#15161A'
        />
        <path
          d='M215.446 549.297L241.394 537.384L239.717 527.835L213.034 537.592L215.446 549.297Z'
          fill='#0066FF'
        />
        <path
          d='M240.787 555.157C239.805 555.57 238.783 555.88 237.737 556.083C237.686 556.095 237.633 556.09 237.584 556.07C237.535 556.05 237.494 556.016 237.466 555.971C237.436 555.927 237.42 555.874 237.42 555.82C237.42 555.766 237.436 555.713 237.466 555.668C237.69 555.269 239.781 551.804 241.33 551.708C241.508 551.695 241.686 551.73 241.846 551.808C242.006 551.887 242.142 552.007 242.241 552.155C242.424 552.353 242.55 552.597 242.607 552.862C242.663 553.126 242.647 553.4 242.56 553.656C242.178 554.361 241.546 554.897 240.787 555.157V555.157ZM238.264 555.397C240.356 554.902 241.745 554.199 242.017 553.464C242.075 553.297 242.085 553.118 242.045 552.945C242.005 552.773 241.918 552.615 241.793 552.49C241.748 552.414 241.682 552.353 241.603 552.313C241.523 552.273 241.434 552.257 241.346 552.267C240.468 552.315 239.095 554.103 238.216 555.397H238.264Z'
          fill='#0066FF'
        />
        <path
          d='M237.753 556.067H237.594C237.546 556.071 237.498 556.058 237.458 556.032C237.418 556.006 237.387 555.968 237.37 555.924C237.37 555.812 235.63 552.826 236.236 551.277C236.308 551.077 236.427 550.897 236.582 550.753C236.738 550.608 236.926 550.503 237.131 550.446C237.348 550.353 237.591 550.335 237.819 550.395C238.048 550.456 238.25 550.592 238.392 550.782C239.175 551.836 238.616 554.822 237.833 555.956L237.753 556.067ZM237.21 551.021C237.109 551.067 237.019 551.133 236.944 551.215C236.87 551.298 236.814 551.395 236.779 551.5C236.659 552.806 236.961 554.116 237.642 555.237C238.281 553.964 238.401 552.493 237.977 551.133C237.881 550.989 237.721 550.862 237.338 550.973L237.21 551.021Z'
          fill='#0066FF'
        />
        <path
          d='M154.351 288.245L165.528 320.661C167.429 326.059 169.313 331.44 171.261 336.757C173.209 342.075 175.205 347.376 177.345 352.422L176.818 351.464C176.282 350.69 175.564 350.059 174.726 349.628C173.746 349.11 172.638 348.882 171.533 348.973C170.079 349.165 170.127 349.468 170.287 349.357C171.215 348.913 172.066 348.326 172.81 347.616C175.031 345.635 177.137 343.529 179.117 341.308C181.241 338.993 183.333 336.518 185.505 334.107L191.796 326.697L199.429 331.264C198.104 334.458 196.603 337.46 195.07 340.51C193.537 343.56 191.876 346.498 189.976 349.452C188.073 352.533 185.937 355.463 183.589 358.219C182.101 359.95 180.394 361.48 178.511 362.77C176.783 363.965 174.775 364.692 172.682 364.878C170.763 365.033 168.84 364.634 167.141 363.728C165.575 362.891 164.246 361.671 163.277 360.183L163.085 359.896L162.75 359.241C160.067 353.812 157.704 348.446 155.436 343.017C153.169 337.588 151.013 332.206 148.953 326.761C146.893 321.316 144.881 315.871 142.965 310.394C141.049 304.917 139.165 299.439 137.424 293.85L154.351 288.245Z'
          fill='#803E3C'
        />
        <path
          d='M156.362 290.002C165.944 301.052 172.331 316.509 172.331 316.509L147.165 331.663C139.899 322.817 130.845 299.375 130.462 291.471C129.903 279.319 145.983 278.105 156.362 290.002Z'
          fill='#0066FF'
        />
        <path
          opacity='0.3'
          d='M147.484 301.276C152.977 305.89 156.873 315.487 158.662 324.797L147.26 331.663C143.668 327.288 139.628 319.352 136.418 311.463C138.909 303.639 142.63 297.188 147.484 301.276Z'
          fill='#15161A'
        />
        <path
          d='M151.045 561.992H162.894L168.307 534.59H156.474L151.045 561.992Z'
          fill='#7F3E3B'
        />
        <path
          d='M163.261 559.117H149.959C149.848 559.113 149.737 559.131 149.633 559.169C149.529 559.208 149.433 559.267 149.351 559.342C149.27 559.418 149.204 559.509 149.157 559.61C149.111 559.711 149.085 559.82 149.081 559.932L148.426 570.455C148.426 571.008 148.644 571.54 149.032 571.934C149.421 572.328 149.949 572.554 150.502 572.563C155.133 572.483 157.353 572.211 163.197 572.211C166.79 572.211 179.006 572.579 183.956 572.579C188.906 572.579 188.746 567.66 186.671 567.213C177.233 565.217 168.626 562.423 165.273 559.852C164.707 559.383 163.996 559.123 163.261 559.117Z'
          fill='#2C2E33'
        />
        <path
          opacity='0.2'
          d='M156.474 534.59H168.307L165.512 548.722H153.664L156.474 534.59Z'
          fill='#15161A'
        />
        <path
          d='M126.661 384.247C126.661 384.247 204.715 390.842 202.799 424.727C200.691 461.838 170.654 545.305 170.654 545.305H148.969C148.969 545.305 158.774 469.79 163.516 432.009C141.528 432.009 95.8742 436.145 88.5607 416.296C85.0043 406.004 84.0874 394.985 85.8939 384.247H126.661Z'
          fill='#2C2E33'
        />
        <path
          d='M146.638 545.305H175.189L176.818 534.047L147.021 535.388L146.638 545.305Z'
          fill='#0066FF'
        />
        <path
          d='M167.444 561.177C166.368 561.153 165.298 561.014 164.251 560.762C164.197 560.752 164.148 560.726 164.11 560.688C164.072 560.649 164.046 560.6 164.035 560.547C164.025 560.494 164.031 560.439 164.052 560.389C164.074 560.339 164.109 560.296 164.155 560.267C164.538 559.996 167.876 557.712 169.329 558.271C169.491 558.339 169.635 558.446 169.746 558.582C169.858 558.719 169.934 558.881 169.967 559.054C170.042 559.312 170.051 559.585 169.992 559.847C169.934 560.11 169.81 560.353 169.632 560.555C169.004 561.024 168.225 561.245 167.444 561.177ZM165.049 560.363C167.173 560.778 168.738 560.714 169.281 560.171C169.401 560.04 169.484 559.879 169.521 559.705C169.557 559.531 169.546 559.35 169.488 559.181C169.476 559.094 169.439 559.012 169.383 558.945C169.326 558.877 169.252 558.826 169.169 558.798C168.403 558.495 166.423 559.485 165.049 560.363Z'
          fill='#0066FF'
        />
        <path
          d='M164.363 560.762C164.322 560.778 164.276 560.778 164.235 560.762C164.193 560.732 164.162 560.69 164.145 560.641C164.128 560.593 164.126 560.54 164.139 560.491C164.139 560.347 163.852 556.946 165.049 555.796C165.203 555.649 165.387 555.538 165.589 555.472C165.79 555.406 166.004 555.385 166.215 555.413C166.451 555.421 166.677 555.507 166.859 555.657C167.041 555.807 167.168 556.013 167.221 556.243C167.492 557.536 165.72 560.012 164.554 560.714L164.363 560.762ZM165.959 555.972C165.739 555.971 165.527 556.057 165.369 556.211C164.711 557.345 164.437 558.661 164.586 559.964C165.624 559.101 166.79 557.217 166.614 556.371C166.614 556.211 166.486 556.019 166.087 555.972H165.959Z'
          fill='#0066FF'
        />
        <path
          d='M190.024 330.721L193.697 317.595L205.529 325.404C205.529 325.404 199.142 336.773 195.134 335.847L190.024 330.721Z'
          fill='#7F3E3B'
        />
        <path
          d='M204.667 312.262L210.847 319.368L205.529 325.404L193.697 317.579L204.667 312.262Z'
          fill='#7F3E3B'
        />
        <path
          d='M149.767 384.838H86.4688C86.0217 379.185 87.3311 360.79 86.357 337.364C85.7551 320.001 83.6501 302.722 80.0654 285.722C79.778 284.445 79.4906 283.183 79.1872 281.906C79.1872 281.906 82.3809 281.251 86.9957 280.581C90.8122 280.022 95.6027 279.447 100.297 279.223C109.259 278.712 118.243 278.712 127.204 279.223C131.34 279.607 135.38 280.293 138.606 280.916C142.725 281.746 145.153 282.513 145.153 282.513C145.153 282.513 160.754 318.282 149.767 384.838Z'
          fill='#0066FF'
        />
        <path
          opacity='0.3'
          d='M86.357 337.38C85.7551 320.017 83.6501 302.738 80.0654 285.738C84.2971 285.371 91.5307 285.738 92.7284 292.621C94.0218 299.966 92.393 317.244 86.357 337.38Z'
          fill='#15161A'
        />
        <path
          d='M91.4669 297.539C90.0777 301.483 88.5767 305.731 87.2513 309.867C85.9259 314.002 84.6165 318.218 83.4348 322.402C80.979 330.488 79.2476 338.776 78.2611 347.169L78.1493 348.638V349.372C78.1493 349.532 78.1493 350.107 78.1493 349.66C78.0312 348.745 77.6792 347.876 77.1273 347.137C76.4685 346.206 75.528 345.512 74.4446 345.157C73.8378 344.997 73.6781 345.045 73.6622 345.061C73.8296 345.106 74.0058 345.106 74.1732 345.061C74.9224 345.014 75.6656 344.896 76.3928 344.71C80.3365 343.636 84.1911 342.259 87.9219 340.59C95.9061 337.173 104.002 333.212 111.986 329.284L116.873 336.694C109.687 342.672 102.053 348.091 94.0378 352.901C89.8019 355.501 85.3262 357.688 80.6723 359.433C79.2413 359.935 77.773 360.325 76.281 360.598C75.3379 360.776 74.382 360.878 73.4227 360.902C72.0213 360.928 70.6248 360.728 69.2868 360.311C67.1109 359.636 65.2155 358.267 63.8895 356.415C62.7075 354.71 62.0321 352.706 61.9414 350.634C61.9175 350.171 61.9175 349.707 61.9414 349.245V346.354C62.0678 341.45 62.5535 336.561 63.3945 331.727C64.161 326.937 65.151 322.37 66.3008 317.819C67.4505 313.268 68.696 308.765 70.1332 304.326C71.5703 299.886 73.0075 295.559 74.8279 291.056L91.4669 297.539Z'
          fill='#803E3C'
        />
        <path
          d='M79.1873 281.922C90.7164 278.281 98.0938 287.495 94.9002 303.479C92.7252 313.691 89.0328 323.52 83.9458 332.638L60.6479 321.587C62.8676 305.922 67.3866 285.643 79.1873 281.922Z'
          fill='#0066FF'
        />
        <path
          d='M100.872 249.107L108.074 255.079L117.879 263.223C117.588 264.529 117.38 265.851 117.256 267.183C116.729 272.501 118.102 277.339 125.927 279.191C125.927 279.191 133.623 286.377 118.23 286.601C102.836 286.824 99.2595 279.351 99.2595 279.351C105.056 273.523 102.868 257.73 100.872 249.107Z'
          fill='#7F3E3B'
        />
        <path
          opacity='0.2'
          d='M117.831 263.175C117.54 264.481 117.332 265.803 117.208 267.135C113.551 266.401 108.665 262.504 108.154 258.768C107.974 257.531 107.931 256.278 108.026 255.031L117.831 263.175Z'
          fill='#15161A'
        />
        <path
          d='M129.296 240.085C132.122 233.697 130.35 224.452 125.559 221.018C124.512 220.236 123.266 219.763 121.964 219.653C120.661 219.543 119.354 219.8 118.19 220.396C117.026 220.991 116.053 221.9 115.379 223.021C114.706 224.141 114.361 225.428 114.382 226.735C114.126 234.592 126.07 247.35 129.296 240.085Z'
          fill='#2C2E33'
        />
        <path
          d='M97.9022 236.588C100.409 248.101 101.288 254.935 108.09 260.189C110.281 261.918 112.902 263.015 115.671 263.362C118.44 263.709 121.251 263.293 123.801 262.159C126.35 261.025 128.542 259.215 130.138 256.926C131.734 254.637 132.674 251.955 132.857 249.171C133.751 238.456 129.52 221.418 117.4 218.16C114.795 217.388 112.033 217.314 109.39 217.945C106.747 218.576 104.317 219.891 102.343 221.757C100.368 223.624 98.9184 225.976 98.1391 228.579C97.3598 231.181 97.2781 233.943 97.9022 236.588Z'
          fill='#7F3E3B'
        />
        <path
          d='M102.789 245.562C106.445 239.59 105.87 230.2 101.591 226.176C100.656 225.246 99.4768 224.598 98.1897 224.307C96.9027 224.017 95.5598 224.095 94.3155 224.534C93.0712 224.973 91.9759 225.754 91.1556 226.787C90.3353 227.821 89.8234 229.064 89.6785 230.376C88.4649 238.152 98.6687 252.3 102.789 245.562Z'
          fill='#2C2E33'
        />
        <path
          d='M104.624 231.314C107.246 229.951 108.265 226.721 106.902 224.1C105.539 221.479 102.309 220.459 99.6876 221.822C97.0666 223.186 96.0469 226.416 97.4102 229.037C98.7735 231.658 102.003 232.677 104.624 231.314Z'
          fill='#2C2E33'
        />
        <path
          d='M102.725 224.388C102.448 225.299 101.906 226.107 101.168 226.709C100.43 227.311 99.5289 227.679 98.5805 227.767C97.6321 227.854 96.6792 227.657 95.8434 227.2C95.0076 226.744 94.327 226.048 93.8883 225.203C93.4497 224.357 93.2731 223.4 93.3809 222.454C93.4888 221.508 93.8763 220.615 94.4939 219.89C95.1115 219.165 95.9312 218.641 96.8483 218.384C97.7655 218.127 98.7384 218.149 99.6427 218.448C100.83 218.84 101.817 219.68 102.393 220.79C102.969 221.9 103.088 223.191 102.725 224.388Z'
          fill='#2C2E33'
        />
        <path
          d='M110.757 223.557C110.474 224.463 109.929 225.265 109.191 225.861C108.452 226.457 107.553 226.82 106.608 226.904C105.662 226.988 104.713 226.789 103.881 226.333C103.049 225.877 102.371 225.184 101.933 224.342C101.495 223.5 101.317 222.547 101.422 221.604C101.527 220.661 101.909 219.77 102.521 219.044C103.132 218.319 103.946 217.791 104.858 217.528C105.77 217.266 106.739 217.28 107.643 217.569C108.847 217.954 109.85 218.8 110.433 219.922C111.017 221.044 111.133 222.35 110.757 223.557V223.557Z'
          fill='#2C2E33'
        />
        <path
          d='M101.272 218.304C100.9 219.489 100.185 220.538 99.2177 221.317C98.2499 222.096 97.0727 222.57 95.8352 222.679C94.5977 222.789 93.3556 222.528 92.2662 221.931C91.1768 221.334 90.2892 220.427 89.7158 219.325C89.1424 218.223 88.9089 216.975 89.0451 215.741C89.1812 214.506 89.6808 213.339 90.4805 212.388C91.2802 211.438 92.3441 210.746 93.5374 210.4C94.7307 210.055 95.9997 210.071 97.1837 210.447C97.9693 210.693 98.6988 211.091 99.33 211.619C99.9612 212.147 100.482 212.795 100.862 213.525C101.242 214.256 101.473 215.054 101.544 215.874C101.614 216.694 101.522 217.52 101.272 218.304V218.304Z'
          fill='#2C2E33'
        />
        <path
          d='M110.677 215.014C110.301 216.222 109.576 217.291 108.592 218.086C107.608 218.882 106.41 219.367 105.151 219.48C103.891 219.594 102.626 219.33 101.516 218.724C100.406 218.118 99.5004 217.195 98.9151 216.074C98.3298 214.953 98.0905 213.683 98.2278 212.426C98.365 211.168 98.8724 209.98 99.6858 209.011C100.499 208.042 101.582 207.337 102.797 206.984C104.011 206.632 105.303 206.648 106.509 207.03C108.118 207.541 109.459 208.667 110.24 210.163C111.021 211.659 111.178 213.403 110.677 215.014Z'
          fill='#2C2E33'
        />
        <path
          d='M118.981 212.811C118.606 214.019 117.882 215.088 116.899 215.884C115.916 216.679 114.719 217.165 113.46 217.28C112.201 217.395 110.936 217.134 109.825 216.529C108.715 215.925 107.809 215.004 107.222 213.884C106.635 212.764 106.394 211.495 106.529 210.238C106.664 208.981 107.169 207.792 107.98 206.822C108.791 205.852 109.872 205.144 111.085 204.789C112.299 204.434 113.591 204.447 114.797 204.827C116.408 205.334 117.753 206.458 118.537 207.955C119.321 209.451 119.481 211.197 118.981 212.811V212.811Z'
          fill='#2C2E33'
        />
        <path
          d='M128.625 212.843C128.234 214.038 127.498 215.091 126.51 215.87C125.522 216.649 124.326 217.119 123.072 217.221C121.818 217.322 120.562 217.051 119.462 216.442C118.361 215.833 117.465 214.912 116.886 213.795C116.306 212.679 116.07 211.416 116.205 210.165C116.341 208.914 116.843 207.732 117.648 206.765C118.454 205.799 119.527 205.092 120.732 204.732C121.938 204.373 123.223 204.378 124.426 204.747C125.238 204.995 125.992 205.403 126.644 205.947C127.297 206.491 127.834 207.159 128.225 207.913C128.616 208.667 128.853 209.491 128.922 210.337C128.991 211.184 128.89 212.036 128.625 212.843Z'
          fill='#2C2E33'
        />
        <path
          d='M96.7046 225.043C96.3606 226.13 95.7018 227.091 94.8117 227.803C93.9216 228.516 92.8401 228.949 91.704 229.047C90.5679 229.145 89.4282 228.904 88.4292 228.354C87.4302 227.804 86.6166 226.97 86.0915 225.958C85.5664 224.946 85.3533 223.801 85.4792 222.667C85.6051 221.534 86.0642 220.463 86.7986 219.591C87.533 218.719 88.5097 218.084 89.605 217.766C90.7003 217.449 91.8651 217.464 92.952 217.809C94.4085 218.271 95.6219 219.293 96.3256 220.649C97.0292 222.005 97.1656 223.586 96.7046 225.043V225.043Z'
          fill='#2C2E33'
        />
        <path
          d='M116.234 219.805C115.924 220.787 115.33 221.655 114.528 222.299C113.725 222.943 112.749 223.335 111.723 223.425C110.698 223.515 109.669 223.299 108.766 222.803C107.863 222.308 107.128 221.557 106.652 220.644C106.177 219.731 105.983 218.697 106.094 217.674C106.206 216.65 106.619 215.683 107.28 214.894C107.942 214.105 108.823 213.53 109.811 213.242C110.799 212.954 111.851 212.965 112.833 213.274C113.485 213.479 114.09 213.811 114.614 214.25C115.138 214.69 115.57 215.228 115.886 215.835C116.202 216.441 116.395 217.104 116.455 217.785C116.515 218.466 116.44 219.153 116.234 219.805V219.805Z'
          fill='#2C2E33'
        />
        <path
          d='M122.621 219.326C122.36 220.161 121.856 220.9 121.174 221.449C120.493 221.997 119.663 222.332 118.792 222.409C117.92 222.487 117.045 222.304 116.277 221.883C115.509 221.463 114.883 220.825 114.479 220.049C114.074 219.273 113.908 218.394 114.003 217.524C114.098 216.654 114.449 215.832 115.011 215.161C115.573 214.491 116.322 214.002 117.162 213.757C118.002 213.512 118.896 213.522 119.731 213.785C120.848 214.137 121.78 214.919 122.322 215.958C122.864 216.996 122.972 218.208 122.621 219.326V219.326Z'
          fill='#2C2E33'
        />
        <path
          d='M132.362 215.893C132.098 216.723 131.593 217.456 130.912 217.999C130.232 218.543 129.405 218.872 128.537 218.946C127.669 219.02 126.799 218.835 126.036 218.415C125.273 217.995 124.651 217.358 124.25 216.584C123.849 215.811 123.686 214.936 123.782 214.07C123.878 213.205 124.228 212.387 124.788 211.72C125.349 211.053 126.094 210.567 126.931 210.323C127.767 210.08 128.657 210.09 129.488 210.352C130.041 210.526 130.554 210.808 130.998 211.181C131.442 211.554 131.808 212.011 132.075 212.526C132.342 213.04 132.505 213.603 132.554 214.18C132.603 214.758 132.538 215.34 132.362 215.893V215.893Z'
          fill='#2C2E33'
        />
        <path
          d='M128.418 219.502C128.167 220.3 127.685 221.007 127.033 221.532C126.38 222.057 125.587 222.376 124.753 222.45C123.919 222.524 123.082 222.349 122.347 221.946C121.613 221.544 121.014 220.933 120.627 220.19C120.241 219.448 120.083 218.607 120.174 217.775C120.265 216.943 120.601 216.156 121.139 215.515C121.677 214.873 122.394 214.406 123.198 214.172C124.002 213.938 124.857 213.948 125.655 214.2C126.186 214.365 126.678 214.634 127.104 214.99C127.53 215.347 127.882 215.784 128.139 216.276C128.396 216.769 128.552 217.308 128.6 217.861C128.648 218.415 128.586 218.972 128.418 219.502Z'
          fill='#2C2E33'
        />
        <path
          d='M136.29 213.833C136.084 214.49 135.687 215.072 135.15 215.503C134.614 215.935 133.961 216.198 133.274 216.259C132.588 216.319 131.899 216.174 131.295 215.843C130.691 215.512 130.198 215.009 129.88 214.397C129.562 213.786 129.433 213.094 129.509 212.409C129.584 211.724 129.861 211.077 130.305 210.55C130.748 210.023 131.338 209.639 132 209.447C132.662 209.256 133.366 209.265 134.023 209.473C134.459 209.61 134.863 209.831 135.213 210.124C135.563 210.417 135.852 210.776 136.063 211.181C136.274 211.587 136.402 212.029 136.441 212.484C136.48 212.939 136.429 213.398 136.29 213.833V213.833Z'
          fill='#2C2E33'
        />
        <path
          d='M138.031 217.042C137.909 217.436 137.673 217.784 137.353 218.043C137.033 218.302 136.643 218.46 136.233 218.497C135.822 218.534 135.41 218.448 135.049 218.25C134.688 218.052 134.394 217.751 134.204 217.385C134.015 217.02 133.938 216.606 133.985 216.196C134.031 215.787 134.198 215.401 134.464 215.087C134.731 214.773 135.084 214.545 135.481 214.433C135.877 214.32 136.298 214.328 136.689 214.455C136.947 214.537 137.187 214.669 137.394 214.843C137.601 215.017 137.771 215.231 137.896 215.471C138.021 215.711 138.097 215.974 138.12 216.243C138.143 216.513 138.113 216.784 138.031 217.042V217.042Z'
          fill='#2C2E33'
        />
        <path
          d='M103.571 219.613C103.448 220.005 103.211 220.351 102.891 220.609C102.571 220.866 102.182 221.022 101.773 221.058C101.364 221.095 100.954 221.009 100.594 220.811C100.233 220.614 99.9399 220.315 99.75 219.95C99.5602 219.586 99.4825 219.174 99.527 218.766C99.5714 218.358 99.7358 217.972 99.9996 217.657C100.263 217.343 100.614 217.113 101.008 216.998C101.403 216.883 101.822 216.887 102.214 217.01C102.736 217.179 103.172 217.547 103.426 218.034C103.679 218.521 103.732 219.088 103.571 219.613V219.613Z'
          fill='#2C2E33'
        />
        <path
          d='M96.0338 247.255C97.608 249.301 99.8827 250.695 102.421 251.167C105.615 251.742 106.669 248.756 105.232 245.849C103.922 243.231 100.681 239.781 97.6626 240.516C94.6446 241.25 94.1017 244.716 96.0338 247.255Z'
          fill='#7F3E3B'
        />
        <path
          d='M117.623 237.642C117.767 238.584 118.39 239.238 119.012 239.238C119.635 239.238 120.018 238.344 119.875 237.402C119.731 236.46 119.108 235.805 118.485 235.805C117.863 235.805 117.479 236.699 117.623 237.642Z'
          fill='#2C2E33'
        />
        <path
          d='M128.338 236.332C128.482 237.274 129.104 237.929 129.727 237.929C130.35 237.929 130.733 237.019 130.59 236.077C130.446 235.135 129.823 234.48 129.2 234.48C128.577 234.48 128.194 235.422 128.338 236.332Z'
          fill='#2C2E33'
        />
        <path
          d='M125.08 236.747C126.262 239.179 127.766 241.44 129.552 243.47C129.024 243.898 128.408 244.202 127.748 244.363C127.087 244.523 126.4 244.534 125.735 244.396L125.08 236.747Z'
          fill='#630F0F'
        />
        <path
          d='M121.344 249.251C120.237 249.263 119.138 249.051 118.115 248.629C117.091 248.206 116.164 247.581 115.388 246.791C115.339 246.736 115.314 246.664 115.319 246.591C115.323 246.518 115.357 246.449 115.412 246.4C115.467 246.352 115.539 246.327 115.612 246.331C115.686 246.336 115.754 246.369 115.803 246.424C116.665 247.265 117.706 247.9 118.848 248.282C119.99 248.663 121.203 248.782 122.398 248.628C122.469 248.623 122.54 248.645 122.596 248.689C122.652 248.733 122.689 248.797 122.701 248.867C122.71 248.941 122.691 249.016 122.646 249.075C122.601 249.135 122.535 249.175 122.462 249.187C122.091 249.231 121.717 249.253 121.344 249.251V249.251Z'
          fill='#2C2E33'
        />
        <path
          d='M113.312 232.196C113.224 232.17 113.143 232.126 113.072 232.069C113.019 232.017 112.977 231.955 112.948 231.886C112.919 231.817 112.904 231.744 112.904 231.669C112.904 231.595 112.919 231.522 112.948 231.453C112.977 231.384 113.019 231.322 113.072 231.27C113.654 230.588 114.397 230.061 115.233 229.738C116.069 229.414 116.973 229.304 117.863 229.418C117.936 229.429 118.006 229.456 118.069 229.495C118.132 229.535 118.186 229.587 118.228 229.648C118.27 229.71 118.299 229.779 118.313 229.852C118.327 229.925 118.326 230 118.31 230.073C118.298 230.144 118.272 230.213 118.234 230.275C118.195 230.336 118.145 230.39 118.085 230.431C118.025 230.473 117.958 230.502 117.887 230.518C117.816 230.533 117.742 230.534 117.671 230.52C116.98 230.451 116.283 230.552 115.641 230.813C114.998 231.075 114.429 231.489 113.982 232.021C113.903 232.117 113.796 232.186 113.675 232.217C113.555 232.249 113.428 232.242 113.312 232.196V232.196Z'
          fill='#2C2E33'
        />
        <path
          d='M132.202 231.542C132.115 231.568 132.022 231.571 131.932 231.551C131.843 231.532 131.76 231.49 131.691 231.43C131.147 230.96 130.505 230.618 129.811 230.43C129.117 230.242 128.39 230.213 127.683 230.344C127.613 230.366 127.539 230.373 127.466 230.365C127.393 230.358 127.322 230.335 127.258 230.3C127.194 230.264 127.137 230.215 127.092 230.157C127.048 230.099 127.015 230.032 126.997 229.961C126.957 229.819 126.975 229.667 127.047 229.539C127.119 229.41 127.238 229.315 127.38 229.274V229.274C128.259 229.085 129.169 229.103 130.04 229.328C130.91 229.553 131.716 229.977 132.394 230.568C132.509 230.664 132.581 230.801 132.596 230.951C132.611 231.1 132.567 231.249 132.474 231.366C132.398 231.444 132.305 231.504 132.202 231.542V231.542Z'
          fill='#2C2E33'
        />
        <path
          d='M109.511 330.626L113.663 320.534L122.414 329.843C122.414 329.843 118.182 339.743 113.264 337.125L109.511 330.626Z'
          fill='#7F3E3B'
        />
        <path
          d='M124.569 313.747L129.44 321.795L122.414 329.843L113.663 320.518L124.569 313.747Z'
          fill='#7F3E3B'
        />
        <path
          d='M383.289 227.214C390.997 227.214 397.246 220.966 397.246 213.258C397.246 205.55 390.997 199.302 383.289 199.302C375.582 199.302 369.333 205.55 369.333 213.258C369.333 220.966 375.582 227.214 383.289 227.214Z'
          fill='#2C2E33'
        />
        <path
          d='M386.531 227.614C383.688 227.614 380.91 226.771 378.547 225.192C376.183 223.612 374.341 221.368 373.253 218.742C372.166 216.116 371.881 213.226 372.436 210.438C372.99 207.65 374.359 205.09 376.369 203.08C378.379 201.07 380.939 199.701 383.727 199.147C386.515 198.592 389.405 198.877 392.031 199.964C394.657 201.052 396.901 202.894 398.48 205.258C400.06 207.621 400.902 210.4 400.902 213.242C400.902 217.054 399.388 220.709 396.693 223.404C393.998 226.099 390.342 227.614 386.531 227.614ZM386.531 199.717C383.849 199.717 381.227 200.512 378.997 202.003C376.768 203.493 375.03 205.611 374.004 208.09C372.979 210.568 372.711 213.294 373.235 215.925C373.76 218.555 375.053 220.971 376.95 222.866C378.848 224.761 381.265 226.051 383.896 226.573C386.527 227.094 389.253 226.823 391.73 225.795C394.207 224.766 396.323 223.026 397.811 220.794C399.299 218.563 400.091 215.94 400.088 213.258C400.084 209.665 398.654 206.221 396.112 203.682C393.57 201.143 390.124 199.717 386.531 199.717V199.717Z'
          fill='#2C2E33'
        />
        <path
          d='M355.6 235.678C352.758 235.678 349.979 234.835 347.616 233.256C345.252 231.676 343.41 229.432 342.323 226.806C341.235 224.18 340.95 221.29 341.505 218.502C342.059 215.714 343.428 213.154 345.438 211.144C347.448 209.134 350.009 207.765 352.796 207.211C355.584 206.656 358.474 206.941 361.1 208.028C363.726 209.116 365.971 210.958 367.55 213.322C369.129 215.685 369.972 218.464 369.972 221.306C369.972 225.118 368.458 228.773 365.762 231.468C363.067 234.163 359.412 235.678 355.6 235.678V235.678ZM355.6 207.765C352.919 207.765 350.298 208.56 348.068 210.05C345.839 211.539 344.101 213.657 343.075 216.134C342.049 218.611 341.78 221.337 342.304 223.967C342.827 226.597 344.118 229.012 346.014 230.908C347.91 232.804 350.325 234.096 352.955 234.619C355.585 235.142 358.311 234.873 360.788 233.847C363.266 232.821 365.383 231.083 366.873 228.854C368.362 226.625 369.157 224.003 369.157 221.322C369.157 219.542 368.807 217.779 368.125 216.134C367.444 214.489 366.445 212.995 365.187 211.736C363.928 210.477 362.433 209.478 360.788 208.797C359.143 208.116 357.381 207.765 355.6 207.765V207.765Z'
          fill='#2C2E33'
        />
        <path
          d='M356.989 235.31C364.883 235.31 371.281 228.912 371.281 221.019C371.281 213.126 364.883 206.727 356.989 206.727C349.096 206.727 342.698 213.126 342.698 221.019C342.698 228.912 349.096 235.31 356.989 235.31Z'
          fill='#2C2E33'
        />
        <path
          d='M343.129 199.381C341.388 207.589 347.345 221.961 353.604 222.743C359.864 223.526 361.093 207.893 355.664 201.074C350.235 194.256 344.438 193.409 343.129 199.381Z'
          fill='#2C2E33'
        />
        <path
          d='M354.834 261.594C355.584 266.113 355.983 270.345 356.319 274.736C356.654 279.128 356.718 283.471 356.654 287.878C356.626 296.829 355.467 305.741 353.205 314.402C350.73 322.865 347.76 330.769 344.678 338.738L339.887 350.586C338.211 354.498 336.598 358.411 334.825 362.307L329.204 360.263L332.67 348.047L336.199 335.879L339.648 323.743C340.798 319.719 341.883 315.647 342.841 311.735C344.423 303.881 345.077 295.869 344.79 287.862C344.79 283.79 344.534 279.703 344.199 275.631C343.863 271.559 343.432 267.375 342.937 263.527L354.834 261.594Z'
          fill='#FF8B7B'
        />
        <path
          d='M343.64 249.394C339.185 251.678 340.302 271.942 340.302 271.942L361.237 280.15C361.237 280.15 363.425 255.335 358.602 250.943C353.78 246.552 350.171 246.057 343.64 249.394Z'
          fill='#0066FF'
        />
        <path
          opacity='0.2'
          d='M345.061 253.658L345.891 274.145L354.371 277.451L345.061 253.658Z'
          fill='#15161A'
        />
        <path
          d='M331.552 357.229L318.234 362.658L328.486 368.088C328.486 368.088 335.32 364.798 335.528 359.912L331.552 357.229Z'
          fill='#FF8B7B'
        />
        <path
          d='M368.551 560.539H356.75L354.754 533.217H366.555L368.551 560.539Z'
          fill='#FF8B7B'
        />
        <path
          d='M434.037 542.925L422.46 545.209L413.454 519.148L425.015 516.865L434.037 542.925Z'
          fill='#FF8B7B'
        />
        <path
          d='M420.767 543.213L433.239 538.742C433.452 538.662 433.687 538.663 433.899 538.746C434.111 538.828 434.285 538.986 434.388 539.189L439.354 548.546C439.471 548.786 439.536 549.048 439.544 549.315C439.553 549.582 439.505 549.847 439.404 550.094C439.303 550.341 439.151 550.564 438.958 550.749C438.765 550.933 438.536 551.075 438.285 551.165C433.909 552.65 431.722 553.145 426.228 555.109C422.859 556.307 416.44 559.9 411.777 561.497C407.114 563.093 405.23 558.638 406.986 557.536C412.231 554.489 416.455 549.954 419.122 544.506C419.504 543.897 420.086 543.44 420.767 543.213V543.213Z'
          fill='#2C2E33'
        />
        <path
          d='M355.872 559.165H369.125C369.353 559.164 369.573 559.246 369.744 559.396C369.915 559.546 370.024 559.754 370.052 559.98L371.569 570.455C371.598 570.72 371.571 570.988 371.489 571.242C371.408 571.496 371.274 571.73 371.096 571.929C370.919 572.128 370.701 572.287 370.458 572.396C370.215 572.505 369.951 572.562 369.684 572.563C365.054 572.483 362.834 572.211 357.005 572.211C353.413 572.211 346.003 572.579 341.037 572.579C336.071 572.579 335.831 567.676 337.843 567.245C347.073 565.249 350.714 562.455 353.812 559.9C354.385 559.41 355.118 559.148 355.872 559.165Z'
          fill='#2C2E33'
        />
        <g opacity='0.2'>
          <path
            d='M354.754 533.233L355.84 548.099H367.64L366.555 533.233H354.754Z'
            fill='#15161A'
          />
          <path
            d='M425.031 516.865L413.454 519.164L418.484 533.68L429.933 531.045L425.031 516.865Z'
            fill='#15161A'
          />
        </g>
        <path
          d='M343.64 249.394C343.64 249.394 338.61 252.588 364.926 317.563L411.649 306.945C406.539 288.118 403.857 276.397 404.08 235.31C397.23 235.446 390.399 236.082 383.641 237.211C376.467 238.299 369.38 239.9 362.435 242.001C353.492 244.939 343.64 249.394 343.64 249.394Z'
          fill='#0066FF'
        />
        <path
          opacity='0.2'
          d='M404.048 241.65C400.291 244.277 397.608 248.172 396.495 252.62C395.266 258.257 403.09 267.774 404.927 269.898C404.368 262.345 404.08 253.275 404.048 241.65Z'
          fill='#15161A'
        />
        <path
          d='M376.678 216.356C376.902 223.494 377.812 233.745 383.625 237.211C380.973 246.033 375.959 253.963 369.125 260.141C357.947 254.728 362.419 242.033 362.419 242.033C369.62 238.488 368.726 235.438 366.187 230.44L376.678 216.356Z'
          fill='#FF8B7B'
        />
        <path
          opacity='0.2'
          d='M372.399 222.104L366.187 230.44C366.827 231.592 367.361 232.8 367.784 234.049C370.594 232.963 374.603 229.45 374.507 226.144C374.151 224.643 373.426 223.255 372.399 222.104V222.104Z'
          fill='#15161A'
        />
        <path
          d='M375.864 199.142C379.361 210.32 381.07 214.967 377.7 222.679C372.607 234.272 358.123 236.109 351.369 226.208C345.285 217.298 340.59 200.515 349.628 192.1C351.61 190.235 354.036 188.908 356.676 188.244C359.315 187.581 362.08 187.603 364.709 188.308C367.337 189.014 369.742 190.379 371.694 192.275C373.647 194.172 375.082 196.535 375.864 199.142Z'
          fill='#FF8B7B'
        />
        <path
          d='M364.926 317.563C364.926 317.563 338.099 385.876 338.865 421.837C339.68 459.251 353.381 544.586 353.381 544.586H369.349C369.349 544.586 366.81 461.487 368.215 424.759C369.812 384.711 399.13 309.787 399.13 309.787L364.926 317.563Z'
          fill='#0066FF'
        />
        <path
          opacity='0.5'
          d='M364.926 317.563C364.926 317.563 338.099 385.876 338.865 421.837C339.68 459.251 353.381 544.586 353.381 544.586H369.349C369.349 544.586 366.81 461.487 368.215 424.759C369.812 384.711 399.13 309.787 399.13 309.787L364.926 317.563Z'
          fill='#15161A'
        />
        <path
          d='M370.722 545.145H352.007L350.634 539.189L371.585 538.454L370.722 545.145Z'
          fill='#2C2E33'
        />
        <path
          opacity='0.2'
          d='M377.158 338.53C368.024 354.706 368.838 381.086 371.297 400.966C375.342 381.783 380.53 362.859 386.834 344.295C385.557 333.564 382.762 328.598 377.158 338.53Z'
          fill='#15161A'
        />
        <path
          d='M377.253 314.753C377.253 314.753 370.866 389.469 379.106 419.793C388.83 455.802 414.843 530.47 414.843 530.47L430.62 527.356C430.62 527.356 417.845 441.207 410.34 422.236C394.467 381.756 429.917 329.891 411.697 306.945L377.253 314.753Z'
          fill='#0066FF'
        />
        <path
          opacity='0.5'
          d='M377.253 314.753C377.253 314.753 370.866 389.469 379.106 419.793C388.83 455.802 414.843 530.47 414.843 530.47L430.62 527.356C430.62 527.356 417.845 441.207 410.34 422.236C394.467 381.756 429.917 329.891 411.697 306.945L377.253 314.753Z'
          fill='#15161A'
        />
        <path
          d='M432.536 527.819L412.959 531.684L409.669 525.951L432.488 520.346L432.536 527.819Z'
          fill='#2C2E33'
        />
        <path
          d='M359.56 208.675C359.896 209.569 359.704 210.464 359.113 210.687C358.522 210.911 357.788 210.368 357.516 209.473C357.245 208.579 357.373 207.685 357.947 207.477C358.522 207.27 359.225 207.797 359.56 208.675Z'
          fill='#2C2E33'
        />
        <path
          d='M349.516 212.507C349.852 213.402 349.66 214.296 349.069 214.519C348.478 214.743 347.744 214.2 347.472 213.306C347.201 212.412 347.329 211.517 347.903 211.294C348.478 211.07 349.165 211.613 349.516 212.507Z'
          fill='#2C2E33'
        />
        <path
          d='M348.127 211.246L345.748 211.454C345.748 211.454 347.488 212.699 348.127 211.246Z'
          fill='#2C2E33'
        />
        <path
          d='M353.221 212.923C353.204 215.252 352.865 217.568 352.215 219.805C352.789 219.958 353.39 219.983 353.975 219.878C354.56 219.773 355.115 219.541 355.6 219.198L353.221 212.923Z'
          fill='#FF5652'
        />
        <path
          d='M361.78 220.827C361.377 221.064 360.955 221.267 360.518 221.434C360.448 221.46 360.371 221.458 360.302 221.428C360.234 221.399 360.18 221.343 360.151 221.274C360.136 221.24 360.128 221.202 360.128 221.165C360.127 221.127 360.135 221.09 360.15 221.055C360.165 221.021 360.187 220.99 360.215 220.964C360.243 220.939 360.275 220.919 360.311 220.907C361.426 220.506 362.431 219.849 363.246 218.989C364.061 218.13 364.664 217.091 365.006 215.957C365.023 215.885 365.067 215.822 365.13 215.783C365.193 215.744 365.269 215.732 365.341 215.749C365.413 215.766 365.475 215.811 365.514 215.874C365.553 215.937 365.565 216.012 365.548 216.084C365.265 217.085 364.785 218.019 364.139 218.833C363.492 219.647 362.69 220.324 361.78 220.827V220.827Z'
          fill='#2C2E33'
        />
        <path
          d='M356.063 193.888C359.656 202.958 375.129 210.863 380.686 208.451C387.329 205.561 385.477 191.19 374.81 186.671C365.884 182.87 353.444 187.277 356.063 193.888Z'
          fill='#2C2E33'
        />
        <path
          d='M364.558 186.064C361.045 182.583 339.967 183.445 338.195 192.691C335.735 205.465 347.999 202.575 354.706 199.924C363.281 196.555 369.157 190.615 364.558 186.064Z'
          fill='#2C2E33'
        />
        <path
          d='M381.868 209.681C381.833 211.946 380.903 214.104 379.281 215.685C377.126 217.761 374.635 216.164 374.044 213.386C373.517 210.879 374.044 206.791 376.758 205.769C377.361 205.554 378.008 205.493 378.64 205.591C379.272 205.69 379.87 205.946 380.378 206.335C380.886 206.724 381.288 207.234 381.549 207.818C381.809 208.403 381.919 209.043 381.868 209.681V209.681Z'
          fill='#FF8B7B'
        />
        <path
          d='M360.55 204.396C360.44 204.438 360.32 204.445 360.206 204.416C360.092 204.388 359.989 204.325 359.912 204.236C359.225 203.637 358.423 203.187 357.555 202.912C356.687 202.637 355.771 202.544 354.866 202.639C354.792 202.646 354.718 202.639 354.648 202.618C354.577 202.597 354.511 202.562 354.454 202.515C354.397 202.468 354.35 202.411 354.315 202.346C354.28 202.281 354.258 202.209 354.251 202.136C354.243 202.063 354.251 201.989 354.272 201.918C354.293 201.847 354.328 201.782 354.375 201.724C354.422 201.667 354.479 201.62 354.544 201.585C354.609 201.55 354.68 201.529 354.754 201.521C355.838 201.409 356.933 201.53 357.966 201.877C358.999 202.224 359.946 202.789 360.742 203.533C360.795 203.585 360.837 203.647 360.866 203.716C360.895 203.785 360.91 203.858 360.91 203.932C360.91 204.007 360.895 204.08 360.866 204.149C360.837 204.218 360.795 204.28 360.742 204.332L360.55 204.396Z'
          fill='#2C2E33'
        />
        <path
          d='M343.448 209.186C343.366 209.234 343.272 209.259 343.177 209.259C343.081 209.259 342.988 209.234 342.905 209.186C342.776 209.112 342.68 208.991 342.639 208.848C342.597 208.705 342.612 208.551 342.682 208.42C343.102 207.637 343.704 206.967 344.438 206.467C345.172 205.966 346.015 205.65 346.897 205.545C346.971 205.541 347.044 205.551 347.114 205.575C347.183 205.6 347.247 205.637 347.302 205.686C347.357 205.735 347.402 205.794 347.434 205.861C347.466 205.927 347.484 205.999 347.488 206.072C347.493 206.146 347.482 206.219 347.458 206.289C347.434 206.358 347.396 206.422 347.347 206.477C347.298 206.532 347.239 206.577 347.173 206.609C347.107 206.64 347.035 206.659 346.961 206.663C346.265 206.757 345.602 207.016 345.027 207.419C344.452 207.821 343.982 208.356 343.656 208.978C343.608 209.066 343.536 209.138 343.448 209.186V209.186Z'
          fill='#2C2E33'
        />
        <path
          d='M415.466 546.742H415.338C415.087 546.632 414.869 546.46 414.704 546.242C414.539 546.024 414.433 545.767 414.396 545.496C414.356 545.333 414.359 545.162 414.404 545C414.448 544.838 414.534 544.69 414.651 544.57C415.737 543.532 419.889 544.49 420.352 544.57C420.406 544.585 420.454 544.613 420.493 544.652C420.533 544.692 420.561 544.74 420.576 544.794C420.585 544.849 420.577 544.906 420.555 544.957C420.532 545.008 420.495 545.051 420.448 545.081C419.17 545.896 416.871 547.189 415.466 546.742ZM419.57 544.953C417.973 544.618 415.609 544.379 415.051 544.953C414.994 545.005 414.954 545.071 414.934 545.145C414.914 545.218 414.916 545.296 414.939 545.369C414.962 545.549 415.03 545.72 415.136 545.868C415.242 546.016 415.382 546.135 415.546 546.215C416.296 546.502 417.765 546.119 419.633 544.953H419.57Z'
          fill='#0066FF'
        />
        <path
          d='M419.825 545.001C418.42 544.554 416.073 543.085 415.897 541.808C415.868 541.588 415.913 541.364 416.026 541.173C416.139 540.982 416.313 540.834 416.52 540.754C416.717 540.67 416.928 540.627 417.142 540.627C417.356 540.627 417.568 540.67 417.765 540.754C419.362 541.393 420.512 544.586 420.56 544.73C420.582 544.772 420.594 544.818 420.594 544.866C420.594 544.913 420.582 544.96 420.56 545.001C420.524 545.036 420.482 545.063 420.435 545.08C420.388 545.096 420.338 545.102 420.288 545.097L419.825 545.001ZM417.478 541.329C417.367 541.281 417.247 541.257 417.126 541.257C417.006 541.257 416.886 541.281 416.775 541.329C416.456 541.504 416.424 541.68 416.456 541.808C416.568 542.622 418.468 543.979 419.825 544.442C419.394 543.389 418.5 541.696 417.558 541.249L417.478 541.329Z'
          fill='#0066FF'
        />
        <path
          d='M351.432 561.225C350.426 561.317 349.418 561.064 348.574 560.507C348.391 560.335 348.25 560.123 348.164 559.887C348.078 559.651 348.049 559.399 348.079 559.149C348.088 558.996 348.135 558.847 348.216 558.716C348.296 558.584 348.409 558.476 348.542 558.399C349.995 557.601 354.69 559.996 355.233 560.283C355.281 560.313 355.321 560.356 355.346 560.406C355.371 560.457 355.382 560.514 355.377 560.571C355.371 560.629 355.345 560.685 355.305 560.728C355.265 560.771 355.211 560.8 355.153 560.81C353.928 561.06 352.683 561.199 351.432 561.225V561.225ZM349.372 558.798C349.191 558.782 349.009 558.815 348.846 558.894C348.79 558.922 348.744 558.965 348.712 559.019C348.681 559.073 348.666 559.135 348.67 559.197C348.641 559.361 348.655 559.53 348.711 559.687C348.767 559.844 348.863 559.983 348.989 560.092C349.692 560.73 351.64 560.842 354.259 560.411C352.734 559.598 351.082 559.052 349.372 558.798Z'
          fill='#0066FF'
        />
        <path
          d='M355.089 560.81H354.977C353.572 560.171 350.794 557.616 351.017 556.291C351.017 555.972 351.289 555.572 352.055 555.509C352.34 555.466 352.631 555.487 352.907 555.57C353.183 555.653 353.437 555.796 353.652 555.988C355.137 557.217 355.409 560.395 355.425 560.523C355.429 560.571 355.421 560.62 355.402 560.665C355.382 560.71 355.352 560.749 355.313 560.778C355.245 560.816 355.165 560.827 355.089 560.81V560.81ZM352.279 556.02H352.119C351.608 556.02 351.576 556.275 351.56 556.355C351.432 557.153 353.317 559.149 354.754 560.028C354.643 558.688 354.119 557.415 353.253 556.387C352.985 556.15 352.636 556.024 352.279 556.036V556.02Z'
          fill='#0066FF'
        />
        <path
          d='M358.171 207.414L355.76 207.589C355.76 207.589 357.532 208.867 358.171 207.414Z'
          fill='#2C2E33'
        />
        <path
          d='M411.649 304.038L413.789 307.951C414.028 308.238 413.789 308.653 413.182 308.781L365.101 319.703C364.686 319.799 364.287 319.703 364.191 319.416L362.834 315.328C362.738 315.057 363.041 314.721 363.504 314.625L410.803 303.863C410.947 303.82 411.1 303.813 411.248 303.844C411.396 303.874 411.534 303.941 411.649 304.038V304.038Z'
          fill='white'
        />
        <path
          d='M406.396 310.809L407.673 310.521C407.913 310.521 408.088 310.298 408.024 310.138L406.204 304.837C406.14 304.754 406.052 304.692 405.952 304.661C405.852 304.629 405.745 304.629 405.645 304.661L404.384 304.949C404.128 304.949 403.968 305.188 404.016 305.332L405.853 310.633C405.901 310.793 406.156 310.873 406.396 310.809Z'
          fill='#0066FF'
        />
        <path
          opacity='0.5'
          d='M406.396 310.809L407.673 310.521C407.913 310.521 408.088 310.298 408.024 310.138L406.204 304.837C406.14 304.754 406.052 304.692 405.952 304.661C405.852 304.629 405.745 304.629 405.645 304.661L404.384 304.949C404.128 304.949 403.968 305.188 404.016 305.332L405.853 310.633C405.901 310.793 406.156 310.873 406.396 310.809Z'
          fill='#15161A'
        />
        <path
          d='M368.822 319.368L370.1 319.08C370.355 319.08 370.515 318.857 370.451 318.697L368.63 313.396C368.572 313.306 368.484 313.24 368.382 313.208C368.28 313.176 368.171 313.18 368.071 313.22L366.81 313.507C366.555 313.507 366.395 313.747 366.443 313.891L368.279 319.192C368.327 319.368 368.535 319.368 368.822 319.368Z'
          fill='#0066FF'
        />
        <path
          opacity='0.5'
          d='M368.822 319.368L370.1 319.08C370.355 319.08 370.515 318.857 370.451 318.697L368.63 313.396C368.572 313.306 368.484 313.24 368.382 313.208C368.28 313.176 368.171 313.18 368.071 313.22L366.81 313.507C366.555 313.507 366.395 313.747 366.443 313.891L368.279 319.192C368.327 319.368 368.535 319.368 368.822 319.368Z'
          fill='#15161A'
        />
        <path
          d='M387.537 315.104L388.799 314.817C389.054 314.817 389.214 314.577 389.166 314.434L387.329 309.132C387.329 308.972 387.026 308.893 386.787 308.956L385.509 309.244C385.254 309.244 385.094 309.467 385.158 309.627L386.978 314.929C387.042 315.012 387.13 315.073 387.23 315.105C387.33 315.136 387.437 315.136 387.537 315.104Z'
          fill='#0066FF'
        />
        <path
          opacity='0.5'
          d='M387.537 315.104L388.799 314.817C389.054 314.817 389.214 314.577 389.166 314.434L387.329 309.132C387.329 308.972 387.026 308.893 386.787 308.956L385.509 309.244C385.254 309.244 385.094 309.467 385.158 309.627L386.978 314.929C387.042 315.012 387.13 315.073 387.23 315.105C387.33 315.136 387.437 315.136 387.537 315.104Z'
          fill='#15161A'
        />
        <path
          d='M313.715 370.291L323.12 372.622L328.486 368.087L318.234 362.658L313.715 370.291Z'
          fill='#FF8B7B'
        />
        <path
          d='M388.783 293.004L379.601 284.237L378.802 296.214C378.802 296.214 385.19 300.286 389.469 297.906L388.783 293.004Z'
          fill='#FF8B7B'
        />
        <path
          d='M411.649 246.201C412.064 246.728 412.272 247.031 412.559 247.43L413.326 248.532C413.805 249.267 414.268 250.001 414.715 250.736C415.588 252.205 416.419 253.69 417.206 255.191C418.707 258.193 420.059 261.243 421.262 264.341C422.492 267.423 423.514 270.585 424.456 273.746C424.919 275.343 425.302 276.94 425.701 278.537L425.861 279.144L426.037 279.91C426.193 280.598 426.289 281.298 426.324 282.002C426.394 283.543 426.144 285.081 425.59 286.521C424.566 288.95 422.829 291.011 420.608 292.429C418.938 293.514 417.142 294.388 415.258 295.032C411.966 296.132 408.567 296.881 405.118 297.268C398.605 298.038 392.03 298.156 385.493 297.619V291.647C388.495 291.28 391.609 290.896 394.611 290.369C397.613 289.842 400.647 289.315 403.537 288.581C406.317 287.906 409.031 286.987 411.649 285.834C412.724 285.363 413.738 284.762 414.667 284.046C415.434 283.423 415.705 282.864 415.641 282.784C415.578 282.705 415.641 282.784 415.641 282.641L415.258 281.491C414.779 280.054 414.3 278.617 413.757 277.211C412.783 274.353 411.585 271.575 410.436 268.828C409.286 266.081 407.961 263.383 406.555 260.844C405.885 259.55 405.166 258.257 404.432 257.027C404.064 256.405 403.681 255.814 403.298 255.223L402.755 254.361L402.276 253.674L411.649 246.201Z'
          fill='#FF8B7B'
        />
        <path
          d='M404.064 235.31C409.062 235.678 418.34 250.512 418.34 250.512L404.128 265.906C404.128 265.906 393.461 255.67 394.084 249.107C394.675 242.416 398.347 234.895 404.064 235.31Z'
          fill='#0066FF'
        />
        <path
          d='M369.732 283.136L369.62 293.196L378.802 296.134L379.601 284.158L369.732 283.136Z'
          fill='#FF8B7B'
        />
        <path
          d='M562.519 439.658H489.479C488.209 439.658 486.99 439.153 486.092 438.255C485.193 437.356 484.689 436.138 484.689 434.867C484.689 433.597 485.193 432.378 486.092 431.48C486.99 430.581 488.209 430.077 489.479 430.077H562.519C563.789 430.077 565.008 430.581 565.906 431.48C566.804 432.378 567.309 433.597 567.309 434.867C567.309 436.138 566.804 437.356 565.906 438.255C565.008 439.153 563.789 439.658 562.519 439.658V439.658Z'
          fill='#0066FF'
        />
        <path
          opacity='0.8'
          d='M562.519 439.658H489.479C488.209 439.658 486.99 439.153 486.092 438.255C485.193 437.356 484.689 436.138 484.689 434.867C484.689 433.597 485.193 432.378 486.092 431.48C486.99 430.581 488.209 430.077 489.479 430.077H562.519C563.789 430.077 565.008 430.581 565.906 431.48C566.804 432.378 567.309 433.597 567.309 434.867C567.309 436.138 566.804 437.356 565.906 438.255C565.008 439.153 563.789 439.658 562.519 439.658V439.658Z'
          fill='white'
        />
        <path
          d='M468.129 561.992H456.297L449.414 534.59H461.247L468.129 561.992Z'
          fill='#FFB573'
        />
        <path
          d='M455.466 559.117H468.768C468.879 559.113 468.99 559.131 469.094 559.169C469.199 559.208 469.294 559.267 469.376 559.342C469.458 559.418 469.523 559.509 469.57 559.61C469.616 559.711 469.642 559.821 469.646 559.932L470.285 570.455C470.285 571.008 470.067 571.54 469.679 571.934C469.291 572.329 468.763 572.554 468.209 572.563C463.578 572.483 461.375 572.211 455.53 572.211C451.921 572.211 436.624 572.579 431.578 572.579C426.532 572.579 426.787 567.66 428.863 567.213C438.3 565.217 450.005 562.423 453.343 559.852C453.944 559.369 454.695 559.109 455.466 559.117V559.117Z'
          fill='#2C2E33'
        />
        <path
          opacity='0.2'
          d='M461.263 534.59H449.414L452.975 548.722H464.808L461.263 534.59Z'
          fill='#15161A'
        />
        <path
          d='M499.603 384.247C499.603 384.247 403.266 390.842 405.262 424.759C407.37 461.87 447.881 545.337 447.881 545.337H469.566C469.566 545.337 449.909 474.373 445.167 436.576C468.145 426.995 530.47 436.177 537.783 416.328C541.367 406.043 542.311 395.024 540.53 384.279L499.603 384.247Z'
          fill='#0066FF'
        />
        <path
          opacity='0.3'
          d='M446.269 444.832C445.997 443.139 445.758 441.478 445.534 439.881C445.374 438.748 445.215 437.63 445.087 436.544C449.404 434.912 453.902 433.807 458.484 433.254C456.888 422.364 454.716 412.336 454.716 412.336C443.538 412.336 430.939 431.498 447.674 453.023C447.397 451.554 447.142 450.101 446.907 448.664C446.684 447.37 446.476 446.077 446.269 444.832Z'
          fill='#15161A'
        />
        <path
          d='M471.818 545.305H443.267L440.807 535.931L469.119 533.68L471.818 545.305Z'
          fill='#2C2E33'
        />
        <path
          d='M451.267 561.177C452.343 561.151 453.413 561.012 454.46 560.762C454.511 560.745 454.556 560.716 454.593 560.677C454.629 560.638 454.655 560.59 454.668 560.539C454.674 560.488 454.667 560.436 454.647 560.388C454.628 560.341 454.596 560.299 454.556 560.267C454.173 559.996 450.82 557.712 449.383 558.271C449.219 558.34 449.075 558.447 448.961 558.583C448.847 558.719 448.767 558.881 448.728 559.054C448.655 559.313 448.649 559.587 448.71 559.849C448.772 560.112 448.899 560.354 449.079 560.555C449.707 561.024 450.486 561.245 451.267 561.177ZM453.662 560.363C451.554 560.778 449.989 560.714 449.43 560.171C449.316 560.037 449.238 559.875 449.204 559.702C449.17 559.528 449.182 559.349 449.239 559.181C449.25 559.096 449.285 559.016 449.338 558.948C449.391 558.881 449.462 558.829 449.542 558.798C450.325 558.495 452.289 559.485 453.662 560.363Z'
          fill='#0066FF'
        />
        <path
          d='M454.365 560.762C454.406 560.776 454.451 560.776 454.492 560.762C454.533 560.739 454.567 560.707 454.592 560.668C454.617 560.629 454.632 560.585 454.636 560.539C454.636 560.395 454.908 556.994 453.726 555.844C453.57 555.697 453.382 555.586 453.178 555.52C452.974 555.453 452.757 555.433 452.544 555.461C452.31 555.47 452.086 555.556 451.907 555.706C451.728 555.857 451.604 556.062 451.554 556.291C451.283 557.584 453.039 560.06 454.221 560.762H454.365ZM452.768 555.972C452.994 555.969 453.212 556.055 453.375 556.211C454.02 557.35 454.288 558.663 454.141 559.964C453.119 559.101 451.937 557.217 452.113 556.371C452.113 556.211 452.241 556.02 452.64 555.972H452.768Z'
          fill='#0066FF'
        />
        <path
          d='M511.995 294.282C510.19 299.248 508.226 304.102 506.294 308.988C504.362 313.875 502.334 318.729 500.242 323.552C496.106 333.244 491.779 342.841 486.988 352.406L486.302 353.764L485.599 354.482C482.196 357.893 478.311 360.788 474.07 363.073C470.024 365.254 465.736 366.953 461.295 368.135C457.021 369.294 452.648 370.053 448.233 370.403C446.045 370.594 443.874 370.642 441.702 370.642C439.477 370.643 437.254 370.52 435.043 370.275L435.219 361.381C436.895 361.125 438.907 360.79 440.744 360.407C442.58 360.023 444.48 359.544 446.317 359.049C449.932 358.09 453.471 356.862 456.904 355.377C460.188 353.975 463.323 352.247 466.261 350.219C469.011 348.32 471.497 346.064 473.655 343.512L472.265 345.588C476.225 336.167 479.978 326.426 483.779 316.845L489.479 302.33L495.244 287.862L511.995 294.282Z'
          fill='#FFB573'
        />
        <path
          d='M493.088 290.002C483.507 301.052 477.12 316.51 477.12 316.51L502.286 331.664C509.551 322.817 518.621 299.375 518.989 291.471C519.548 279.319 503.467 278.106 493.088 290.002Z'
          fill='#2C2E33'
        />
        <path
          opacity='0.3'
          d='M501.966 301.276C496.473 305.891 492.577 315.488 490.868 324.797L502.27 331.664C505.863 327.288 509.903 319.352 513.112 311.463C510.541 303.639 506.821 297.188 501.966 301.276Z'
          fill='#15161A'
        />
        <path
          d='M514.853 554.566L503.34 557.281L491.763 531.843L503.292 529.129L514.853 554.566Z'
          fill='#FFB573'
        />
        <path
          d='M501.359 554.103L513.831 549.504C514.041 549.424 514.274 549.427 514.482 549.513C514.69 549.599 514.857 549.762 514.949 549.968L519.18 559.549C519.345 560.069 519.313 560.632 519.089 561.13C518.865 561.627 518.465 562.025 517.967 562.247C513.879 564.419 511.579 564.563 505.942 566.303C503.467 567.054 497.176 572.275 489.894 572.275H483.507C479.738 572.275 479.227 568.251 481.319 567.692C491.427 564.994 497.415 559.054 499.651 555.429C500.049 554.802 500.653 554.333 501.359 554.103Z'
          fill='#2C2E33'
        />
        <path
          opacity='0.2'
          d='M503.292 529.129L491.763 531.843L497.735 544.953L509.264 542.239L503.292 529.129Z'
          fill='#15161A'
        />
        <path
          d='M522.789 384.247C522.789 384.247 444.736 390.842 446.652 424.727C448.76 461.838 491.954 542.814 491.954 542.814L513.049 537.847C513.049 537.847 490.693 469.79 485.902 432.009C507.907 432.009 553.544 436.145 560.954 416.296C564.537 406.011 565.481 394.992 563.7 384.247H522.789Z'
          fill='#0066FF'
        />
        <path
          d='M515.316 537.305L487.531 543.852L484.577 534.51L512.713 526.238L515.316 537.305Z'
          fill='#2C2E33'
        />
        <path
          d='M498.134 557.552C499.12 557.156 500.062 556.658 500.945 556.067C500.989 556.038 501.024 555.998 501.047 555.95C501.069 555.902 501.078 555.849 501.072 555.796C501.064 555.742 501.04 555.692 501.003 555.652C500.966 555.612 500.918 555.585 500.865 555.572C500.418 555.461 496.489 554.455 495.324 555.493C495.192 555.611 495.092 555.761 495.033 555.929C494.974 556.096 494.959 556.276 494.988 556.451C494.998 556.721 495.082 556.983 495.232 557.208C495.381 557.434 495.59 557.614 495.835 557.728C496.596 557.958 497.416 557.895 498.134 557.552ZM500.098 555.956C498.262 557.073 496.777 557.552 496.058 557.233C495.902 557.149 495.771 557.024 495.679 556.873C495.586 556.721 495.535 556.548 495.531 556.371C495.518 556.286 495.526 556.2 495.554 556.12C495.582 556.039 495.629 555.966 495.691 555.908C496.282 555.349 498.501 555.604 500.098 555.956V555.956Z'
          fill='#0066FF'
        />
        <path
          d='M500.897 556.099C500.935 556.082 500.968 556.054 500.992 556.019C501.015 555.981 501.027 555.937 501.027 555.892C501.027 555.847 501.015 555.803 500.992 555.764C500.992 555.62 500.034 552.331 498.517 551.676C498.327 551.578 498.116 551.527 497.902 551.527C497.689 551.527 497.478 551.578 497.288 551.676C497.068 551.766 496.885 551.926 496.767 552.131C496.648 552.336 496.601 552.575 496.633 552.81C496.825 554.119 499.332 555.828 500.689 556.083L500.897 556.099ZM497.703 552.155C497.804 552.112 497.912 552.09 498.022 552.09C498.132 552.09 498.241 552.112 498.342 552.155C499.252 552.554 499.938 554.359 500.37 555.349C499.108 554.902 497.352 553.544 497.176 552.682C497.176 552.522 497.176 552.315 497.527 552.123L497.703 552.155Z'
          fill='#0066FF'
        />
        <path
          d='M437.326 361.141L423.961 358.411L425.637 371.409C425.637 371.409 438.572 371.968 439.546 368.024L437.326 361.141Z'
          fill='#FFB573'
        />
        <path
          d='M414.204 365.756L417.717 374.491L425.637 371.409L423.961 358.411L414.204 365.756Z'
          fill='#FFB573'
        />
        <path
          d='M499.667 384.838H562.982C563.429 379.186 562.119 360.79 563.093 337.364C563.695 320.001 565.8 302.722 569.385 285.722C569.672 284.445 569.082 283.503 569.385 282.225C567.142 281.434 564.817 280.899 562.455 280.629C558.638 280.07 553.848 279.495 549.153 279.271C540.191 278.76 531.208 278.76 522.246 279.271C518.11 279.655 514.07 280.341 510.845 280.964C506.725 281.794 504.298 282.561 504.298 282.561C504.298 282.561 488.697 318.282 499.667 384.838Z'
          fill='#2C2E33'
        />
        <path
          opacity='0.3'
          d='M563.093 337.38C563.695 320.017 565.8 302.738 569.385 285.739C565.153 285.371 557.92 285.739 556.722 292.621C555.365 299.966 557.057 317.244 563.093 337.38Z'
          fill='#15161A'
        />
        <path
          d='M574.718 291.056C576.587 295.671 578.024 300.078 579.509 304.661C580.994 309.244 582.271 313.795 583.405 318.474C584.539 323.152 585.577 327.879 586.311 332.765C586.711 335.161 586.982 337.668 587.269 340.143C587.557 342.618 587.637 345.189 587.589 347.856V349.804C587.575 351.233 587.332 352.651 586.87 354.003C586.109 356.136 584.907 358.084 583.341 359.72C581.151 361.922 578.636 363.775 575.884 365.213C571.369 367.574 566.629 369.478 561.736 370.898C552.543 373.606 543.127 375.487 533.6 376.519L531.763 367.816C535.995 366.347 540.354 364.83 544.538 363.201C548.722 361.572 552.953 360.008 556.961 358.123C560.813 356.472 564.52 354.501 568.043 352.231C569.34 351.413 570.499 350.394 571.477 349.213C571.764 348.846 571.732 348.654 571.477 349.021C571.426 349.145 571.388 349.273 571.365 349.405V349.66V349.788L571.237 348.287C570.903 344.09 570.274 339.923 569.353 335.815C568.459 331.568 567.373 327.272 566.159 323.041C564.946 318.809 563.636 314.482 562.247 310.266C560.858 306.05 559.373 301.675 557.935 297.635L574.718 291.056Z'
          fill='#FFB573'
        />
        <path
          d='M569.385 282.241C557.856 278.601 551.357 287.495 554.55 303.479C556.735 313.691 560.432 323.519 565.521 332.638L588.803 321.588C586.599 305.923 581.186 285.962 569.385 282.241Z'
          fill='#2C2E33'
        />
        <path
          d='M549.121 252.301L541.919 258.273L532.115 266.417C532.405 267.722 532.613 269.045 532.738 270.377C533.264 275.694 528.953 278.169 523.524 279.191C523.524 279.191 515.827 286.377 531.221 286.601C546.614 286.824 550.191 279.351 550.191 279.351C544.331 273.523 547.125 260.924 549.121 252.301Z'
          fill='#FFB573'
        />
        <path
          d='M548.706 279.255C543.708 282.74 538.078 285.213 532.131 286.537C535.149 289.539 537.097 296.916 537.097 296.916C537.097 296.916 553.768 293.004 554.375 279.447C553.489 279.052 552.563 278.758 551.612 278.569C551.106 278.483 550.589 278.499 550.089 278.617C549.59 278.735 549.12 278.952 548.706 279.255V279.255Z'
          fill='#0066FF'
        />
        <path
          d='M524.737 279.575C524.354 281.459 524.737 284.908 532.131 286.537C527.58 289.156 525.632 296.118 525.632 296.118C525.632 296.118 515.316 287.064 520.617 279.239C521.583 278.819 522.597 278.519 523.636 278.345C523.798 278.317 523.965 278.331 524.121 278.385C524.277 278.439 524.417 278.532 524.527 278.655C524.637 278.778 524.714 278.927 524.751 279.088C524.788 279.248 524.783 279.416 524.737 279.575Z'
          fill='#0066FF'
        />
        <path
          opacity='0.2'
          d='M532.115 266.369C532.405 267.674 532.613 268.997 532.737 270.329C538.805 269.403 542.734 265.81 543.245 262.089C543.436 260.684 542.015 259.199 541.919 258.225L532.115 266.369Z'
          fill='#15161A'
        />
        <path
          d='M522.885 248.101C517.264 243.933 514.262 235.023 516.721 229.673C517.246 228.466 518.099 227.431 519.183 226.685C520.267 225.939 521.539 225.513 522.854 225.454C524.169 225.396 525.474 225.708 526.62 226.355C527.766 227.003 528.707 227.959 529.336 229.114C533.376 235.869 529.272 252.843 522.885 248.101Z'
          fill='#2C2E33'
        />
        <path
          d='M549.616 236.987C549.712 248.772 550.383 255.638 544.906 262.265C543.152 264.439 540.837 266.092 538.212 267.045C535.587 267.998 532.751 268.215 530.011 267.672C527.271 267.129 524.732 265.848 522.668 263.966C520.604 262.084 519.095 259.673 518.302 256.995C515.108 246.744 515.428 229.21 526.51 223.35C528.878 222.018 531.555 221.331 534.273 221.36C536.99 221.388 539.652 222.13 541.992 223.511C544.332 224.892 546.269 226.864 547.607 229.229C548.945 231.594 549.638 234.27 549.616 236.987V236.987Z'
          fill='#FFB573'
        />
        <path
          d='M545.544 246.36C541.297 240.787 540.897 231.398 544.762 226.943C545.604 225.945 546.709 225.202 547.952 224.798C549.194 224.394 550.525 224.346 551.793 224.658C553.062 224.97 554.218 225.63 555.131 226.565C556.044 227.499 556.678 228.67 556.962 229.945C559.022 237.514 550.351 252.684 545.544 246.36Z'
          fill='#2C2E33'
        />
        <path
          d='M519.5 227.917C515.939 225.777 524.162 218.543 534.829 215.382C546.742 211.853 561.8 221.114 562.534 229.146C563.301 237.61 557.584 233.49 556.69 236.141C556.69 236.141 526.797 232.308 519.5 227.917Z'
          fill='#0066FF'
        />
        <path
          d='M515.891 230.871C516.056 229.149 516.76 227.523 517.903 226.224C518.51 225.665 553.832 232.787 557.504 235.949C558.191 236.54 555.716 241.586 555.173 240.931C551.309 236.332 516.035 232.148 515.891 230.871Z'
          fill='#0066FF'
        />
        <path
          opacity='0.3'
          d='M515.891 230.871C516.056 229.149 516.76 227.523 517.903 226.224C518.51 225.665 553.832 232.787 557.504 235.949C558.191 236.54 555.716 241.586 555.173 240.931C551.309 236.332 516.035 232.148 515.891 230.871Z'
          fill='#15161A'
        />
        <path
          d='M553.432 243.694C553.49 243.707 553.55 243.707 553.608 243.694C553.696 243.638 553.759 243.55 553.782 243.448C553.806 243.347 553.79 243.24 553.736 243.151C551.836 240.037 538.805 238.983 537.336 238.871C533.119 238.654 528.89 238.933 524.737 239.702C524.216 239.508 523.659 239.435 523.106 239.488C522.553 239.54 522.019 239.717 521.544 240.005C518.797 239.974 516.056 240.242 513.368 240.803C513.316 240.815 513.267 240.836 513.224 240.866C513.181 240.897 513.144 240.935 513.116 240.98C513.088 241.024 513.069 241.074 513.06 241.126C513.051 241.178 513.053 241.231 513.064 241.282C513.076 241.334 513.097 241.383 513.127 241.426C513.158 241.469 513.196 241.506 513.241 241.534C513.285 241.562 513.335 241.581 513.387 241.59C513.439 241.599 513.492 241.598 513.543 241.586C516.2 241.032 518.91 240.769 521.623 240.803C521.692 240.818 521.763 240.818 521.831 240.803C522.211 240.553 522.645 240.396 523.098 240.346C523.55 240.296 524.008 240.354 524.434 240.516C524.522 240.548 524.618 240.548 524.705 240.516C528.823 239.728 533.022 239.443 537.209 239.67C541.121 239.957 551.58 241.267 552.985 243.566C553.039 243.629 553.11 243.676 553.189 243.698C553.269 243.721 553.354 243.719 553.432 243.694V243.694Z'
          fill='#2C2E33'
        />
        <path
          d='M532.051 249.81H532.434C533.245 249.729 534.028 249.47 534.727 249.05C535.425 248.63 536.022 248.061 536.474 247.382C538.486 244.269 537.129 239.398 537.081 239.238C537.066 239.186 537.031 239.141 536.983 239.114C536.935 239.087 536.878 239.08 536.825 239.095C536.776 239.113 536.734 239.15 536.711 239.197C536.687 239.245 536.682 239.299 536.698 239.35C536.698 239.35 538.055 244.141 536.139 247.175C535.719 247.807 535.162 248.336 534.51 248.723C533.857 249.11 533.126 249.345 532.37 249.41C531.657 249.548 530.922 249.526 530.218 249.347C529.514 249.167 528.859 248.834 528.298 248.372C525.36 245.897 525.105 240.133 525.105 240.085C525.105 240.03 525.083 239.977 525.044 239.938C525.005 239.899 524.952 239.877 524.897 239.877C524.846 239.884 524.799 239.909 524.764 239.947C524.729 239.985 524.709 240.033 524.705 240.085C524.705 240.324 524.897 246.089 527.979 248.676C528.543 249.136 529.195 249.474 529.896 249.669C530.597 249.864 531.331 249.912 532.051 249.81V249.81Z'
          fill='#2C2E33'
        />
        <path
          d='M517.919 250.832C518.535 250.811 519.139 250.658 519.69 250.382C520.241 250.107 520.727 249.715 521.113 249.235C523.268 246.36 521.927 240.899 521.879 240.676C521.86 240.63 521.826 240.592 521.782 240.569C521.737 240.546 521.687 240.54 521.638 240.552C521.59 240.563 521.547 240.592 521.518 240.632C521.489 240.673 521.476 240.722 521.48 240.771C521.48 240.771 522.837 246.281 520.793 248.995C520.424 249.494 519.942 249.898 519.387 250.176C518.832 250.453 518.22 250.596 517.6 250.592C517.185 250.619 516.769 250.551 516.384 250.394C516 250.236 515.656 249.992 515.38 249.682C513.256 247.334 514.039 241.298 514.039 241.235C514.043 241.209 514.043 241.183 514.037 241.158C514.031 241.133 514.021 241.11 514.006 241.089C513.991 241.068 513.972 241.051 513.95 241.037C513.928 241.024 513.904 241.015 513.879 241.011C513.854 241.006 513.828 241.007 513.803 241.013C513.778 241.018 513.754 241.029 513.733 241.044C513.713 241.058 513.695 241.077 513.682 241.099C513.668 241.121 513.659 241.145 513.655 241.171C513.655 241.426 512.841 247.558 515.092 249.953C515.41 250.302 515.801 250.576 516.238 250.755C516.674 250.935 517.144 251.015 517.615 250.991L517.919 250.832Z'
          fill='#2C2E33'
        />
        <path
          d='M553.768 246.983C552.697 249.312 550.811 251.169 548.466 252.205C545.416 253.482 543.804 250.784 544.554 247.622C545.241 244.78 547.652 240.708 550.75 240.756C553.848 240.803 555.125 244.077 553.768 246.983Z'
          fill='#FFB573'
        />
        <path
          d='M530.614 242.384C530.614 243.326 530.23 244.157 529.608 244.221C528.985 244.285 528.442 243.566 528.362 242.624C528.282 241.682 528.745 240.835 529.368 240.771C529.991 240.708 530.55 241.426 530.614 242.384Z'
          fill='#2C2E33'
        />
        <path
          d='M519.883 243.518C519.883 244.46 519.5 245.291 518.877 245.354C518.254 245.418 517.695 244.7 517.631 243.758C517.568 242.815 518.015 241.985 518.637 241.921C519.26 241.857 519.819 242.56 519.883 243.518Z'
          fill='#2C2E33'
        />
        <path
          d='M523.428 243.997C522.701 247.089 521.562 250.07 520.043 252.86C520.759 253.21 521.544 253.399 522.341 253.413C523.139 253.426 523.929 253.265 524.657 252.939L523.428 243.997Z'
          fill='#ED893E'
        />
        <path
          d='M529.56 254.536C530.643 254.302 531.668 253.852 532.574 253.213C533.479 252.574 534.246 251.758 534.829 250.816C534.86 250.749 534.866 250.673 534.845 250.603C534.825 250.533 534.779 250.472 534.718 250.432C534.65 250.399 534.571 250.394 534.5 250.418C534.428 250.441 534.369 250.493 534.334 250.56C533.684 251.572 532.81 252.422 531.781 253.045C530.751 253.668 529.593 254.047 528.394 254.153C528.321 254.165 528.255 254.205 528.21 254.264C528.165 254.324 528.145 254.399 528.155 254.472C528.159 254.508 528.17 254.543 528.187 254.574C528.204 254.606 528.228 254.633 528.256 254.656C528.284 254.678 528.317 254.694 528.351 254.704C528.386 254.714 528.422 254.716 528.458 254.712C528.829 254.679 529.197 254.62 529.56 254.536V254.536Z'
          fill='#2C2E33'
        />
        <path
          d='M535.468 237.769C535.56 237.732 535.641 237.671 535.7 237.592C535.76 237.512 535.797 237.418 535.807 237.319C535.816 237.221 535.799 237.121 535.756 237.031C535.713 236.942 535.647 236.866 535.564 236.811C534.852 236.287 534.025 235.944 533.151 235.811C532.278 235.677 531.385 235.757 530.55 236.045C530.481 236.073 530.419 236.114 530.367 236.166C530.315 236.218 530.273 236.28 530.245 236.349C530.217 236.417 530.202 236.49 530.203 236.564C530.203 236.638 530.218 236.711 530.246 236.779C530.306 236.915 530.416 237.022 530.553 237.078C530.69 237.135 530.843 237.136 530.981 237.083C531.639 236.867 532.339 236.813 533.023 236.924C533.706 237.035 534.353 237.308 534.909 237.721C534.988 237.783 535.082 237.82 535.181 237.829C535.281 237.837 535.38 237.817 535.468 237.769Z'
          fill='#2C2E33'
        />
        <path
          d='M515.092 239.686C515.183 239.691 515.274 239.675 515.357 239.639C515.44 239.603 515.514 239.548 515.571 239.478C515.991 238.895 516.539 238.416 517.173 238.079C517.807 237.741 518.51 237.553 519.228 237.53C519.302 237.534 519.375 237.524 519.445 237.5C519.514 237.476 519.578 237.438 519.633 237.389C519.688 237.34 519.733 237.281 519.765 237.214C519.796 237.148 519.815 237.076 519.819 237.003C519.823 236.929 519.813 236.856 519.789 236.786C519.765 236.717 519.727 236.653 519.678 236.598C519.629 236.543 519.57 236.499 519.504 236.467C519.437 236.435 519.366 236.416 519.292 236.412C518.393 236.423 517.509 236.643 516.711 237.056C515.912 237.469 515.221 238.064 514.693 238.791C514.647 238.849 514.613 238.916 514.594 238.987C514.574 239.059 514.57 239.133 514.58 239.207C514.591 239.28 514.616 239.35 514.655 239.413C514.693 239.477 514.744 239.531 514.805 239.574C514.883 239.647 514.986 239.687 515.092 239.686V239.686Z'
          fill='#2C2E33'
        />
        <path
          d='M534.733 367.273L524.945 362.483L524.53 375.257C524.53 375.257 534.238 379.872 536.203 374.666L534.733 367.273Z'
          fill='#FFB573'
        />
        <path
          d='M512.266 364.479L512.442 375.8L524.53 375.146L524.945 362.355L512.266 364.479Z'
          fill='#FFB573'
        />
        <path
          d='M570.375 576.842H560.794V394.164C560.794 390.352 559.28 386.697 556.585 384.002C553.889 381.306 550.234 379.792 546.422 379.792H164.347C160.535 379.792 156.88 381.306 154.184 384.002C151.489 386.697 149.975 390.352 149.975 394.164V576.842H140.394V394.164C140.394 387.811 142.918 381.719 147.41 377.227C151.902 372.735 157.994 370.211 164.347 370.211H546.422C552.775 370.211 558.867 372.735 563.359 377.227C567.851 381.719 570.375 387.811 570.375 394.164V576.842Z'
          fill='#0066FF'
        />
        <path
          opacity='0.7'
          d='M570.375 576.842H560.794V394.164C560.794 390.352 559.28 386.697 556.585 384.002C553.889 381.306 550.234 379.792 546.422 379.792H164.347C160.535 379.792 156.88 381.306 154.184 384.002C151.489 386.697 149.975 390.352 149.975 394.164V576.842H140.394V394.164C140.394 387.811 142.918 381.719 147.41 377.227C151.902 372.735 157.994 370.211 164.347 370.211H546.422C552.775 370.211 558.867 372.735 563.359 377.227C567.851 381.719 570.375 387.811 570.375 394.164V576.842Z'
          fill='white'
        />
        <path
          d='M535.995 576.842H526.414V394.164C526.414 390.352 524.9 386.697 522.205 384.002C519.509 381.306 515.854 379.792 512.042 379.792H129.967C126.155 379.792 122.5 381.306 119.804 384.002C117.109 386.697 115.595 390.352 115.595 394.164V576.842H106.014V394.164C106.014 387.811 108.538 381.719 113.03 377.227C117.522 372.735 123.614 370.211 129.967 370.211H512.042C518.395 370.211 524.487 372.735 528.979 377.227C533.471 381.719 535.995 387.811 535.995 394.164V576.842Z'
          fill='#0066FF'
        />
        <path
          opacity='0.8'
          d='M535.995 576.842H526.414V394.164C526.414 390.352 524.9 386.697 522.205 384.002C519.509 381.306 515.854 379.792 512.042 379.792H129.967C126.155 379.792 122.5 381.306 119.804 384.002C117.109 386.697 115.595 390.352 115.595 394.164V576.842H106.014V394.164C106.014 387.811 108.538 381.719 113.03 377.227C117.522 372.735 123.614 370.211 129.967 370.211H512.042C518.395 370.211 524.487 372.735 528.979 377.227C533.471 381.719 535.995 387.811 535.995 394.164V576.842Z'
          fill='white'
        />
        <path
          d='M327.144 366.331H244.843C243.663 366.314 242.529 365.871 241.649 365.085C240.77 364.299 240.203 363.222 240.053 362.051L234.56 310.218C234.488 309.679 234.534 309.131 234.694 308.612C234.854 308.092 235.125 307.614 235.488 307.209C235.85 306.804 236.296 306.482 236.795 306.265C237.293 306.049 237.833 305.943 238.376 305.954H320.677C321.856 305.968 322.989 306.407 323.87 307.19C324.75 307.974 325.318 309.049 325.468 310.218L330.961 362.051C331.032 362.591 330.986 363.14 330.826 363.66C330.666 364.18 330.396 364.66 330.033 365.066C329.671 365.472 329.226 365.795 328.727 366.014C328.228 366.232 327.689 366.34 327.144 366.331V366.331Z'
          fill='#0066FF'
        />
        <path
          opacity='0.2'
          d='M327.144 366.331H244.843C243.663 366.314 242.529 365.871 241.649 365.085C240.77 364.299 240.203 363.222 240.053 362.051L234.56 310.218C234.488 309.679 234.534 309.131 234.694 308.612C234.854 308.092 235.125 307.614 235.488 307.209C235.85 306.804 236.296 306.482 236.795 306.265C237.293 306.049 237.833 305.943 238.376 305.954H320.677C321.856 305.968 322.989 306.407 323.87 307.19C324.75 307.974 325.318 309.049 325.468 310.218L330.961 362.051C331.032 362.591 330.986 363.14 330.826 363.66C330.666 364.18 330.396 364.66 330.033 365.066C329.671 365.472 329.226 365.795 328.727 366.014C328.228 366.232 327.689 366.34 327.144 366.331V366.331Z'
          fill='#15161A'
        />
        <path
          d='M272.405 300.365H265.746V301.962H272.405V300.365Z'
          fill='#0066FF'
        />
        <path
          d='M260.029 300.365H244.588V301.962H260.029V300.365Z'
          fill='#0066FF'
        />
        <path
          d='M324.861 366.331H242.576C241.394 366.32 240.256 365.88 239.375 365.093C238.494 364.306 237.929 363.225 237.785 362.051L232.276 310.218C232.207 309.68 232.255 309.133 232.416 308.615C232.578 308.096 232.848 307.619 233.21 307.214C233.572 306.81 234.017 306.488 234.514 306.271C235.012 306.053 235.55 305.945 236.093 305.954H318.394C319.573 305.965 320.709 306.402 321.59 307.186C322.471 307.971 323.037 309.048 323.184 310.218L328.693 362.051C328.762 362.592 328.714 363.141 328.552 363.661C328.39 364.181 328.118 364.66 327.754 365.066C327.391 365.472 326.945 365.795 326.445 366.013C325.946 366.232 325.406 366.34 324.861 366.331V366.331Z'
          fill='#0066FF'
        />
        <path
          d='M328.614 365.948H246.376C245.244 365.948 244.158 366.396 243.356 367.195C242.554 367.995 242.101 369.079 242.097 370.211H332.941C332.939 369.646 332.825 369.088 332.606 368.567C332.387 368.047 332.067 367.575 331.665 367.178C331.263 366.782 330.786 366.469 330.262 366.258C329.739 366.047 329.178 365.941 328.614 365.948V365.948Z'
          fill='#0066FF'
        />
        <path
          d='M356.83 365.948H327.432C326.302 365.952 325.22 366.402 324.422 367.201C323.623 368 323.172 369.082 323.168 370.211H361.109C361.105 369.079 360.652 367.995 359.85 367.195C359.048 366.396 357.962 365.948 356.83 365.948Z'
          fill='#0066FF'
        />
        <path
          opacity='0.2'
          d='M356.83 365.948H327.432C326.302 365.952 325.22 366.402 324.422 367.201C323.623 368 323.172 369.082 323.168 370.211H361.109C361.105 369.079 360.652 367.995 359.85 367.195C359.048 366.396 357.962 365.948 356.83 365.948Z'
          fill='#15161A'
        />
      </svg>
    </div>
  )
}
