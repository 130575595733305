export const IconGlass = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='#5A646E'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.7803 14.7197L11.5925 10.532C12.4714 9.42334 13 8.02453 13 6.5C13 2.91015 10.0898 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0898 2.91015 13 6.5 13C8.02453 13 9.4234 12.4714 10.5319 11.5925L14.7197 15.7803C14.8662 15.9267 15.0581 16 15.25 16C15.4419 16 15.6338 15.9267 15.7803 15.7803C16.0732 15.4873 16.0732 15.0127 15.7803 14.7197ZM1.5 6.5C1.5 3.74303 3.74297 1.5 6.5 1.5C9.25703 1.5 11.5 3.74303 11.5 6.5C11.5 9.25696 9.25703 11.5 6.5 11.5C3.74297 11.5 1.5 9.25696 1.5 6.5Z'
        fill='#5A646E'
      />
    </svg>
  )
}
