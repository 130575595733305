import styled from 'styled-components'
import { theme } from 'styles'

export const Main = styled.div`
  display: grid;
  width: fit-content;
  gap: 0.25rem;

  label,
  p {
    /* color: ${theme.neutrals.gray6} !important; */
    /* padding-bottom: 0.1rem; */
  }
`
