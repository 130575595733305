import React from 'react'

export const IconCompanies = ({
  width = '18px',
  height = '16px',
  color = 'black',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.25 2.625H10.5V1.75C10.5 0.783399 9.7166 0 8.75 0H5.25C4.28477 0 3.5 0.783399 3.5 1.75V2.625H1.75C0.783399 2.625 0 3.40977 0 4.375V11.375C0 12.3416 0.783399 13.125 1.75 13.125H12.25C13.2166 13.125 14 12.3416 14 11.375V4.375C14 3.40977 13.2152 2.625 12.25 2.625ZM5.25 1.3125H8.75C8.99164 1.3125 9.1875 1.50836 9.1875 1.75V2.625H4.8125V1.75C4.8125 1.50828 5.00938 1.3125 5.25 1.3125ZM1.75 3.9375H12.25C12.4906 3.9375 12.6875 4.13438 12.6875 4.375V7H1.3125V4.375C1.3125 4.13438 1.50937 3.9375 1.75 3.9375ZM12.25 11.8125H1.75C1.50937 11.8125 1.3125 11.6156 1.3125 11.375V8.3125H5.25V8.75C5.25 9.23316 5.64184 9.625 6.125 9.625H7.875C8.35816 9.625 8.75 9.23316 8.75 8.75V8.3125H12.6875V11.375C12.6875 11.6156 12.4906 11.8125 12.25 11.8125Z'
        fill='#3B454F'
      />
    </svg>
  )
}
