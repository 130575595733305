import { Typography } from '@stardust-ds/react'
import { theme } from 'styles'

export const NotFoundFilter = ({ text }: { text: string }) => {
  return (
    <Typography
      color={theme.neutrals.gray5}
      fontWeight='bold'
      type='h3'
    >
      {text || 'Nenhum resultado encontrado.'}
    </Typography>
  )
}
