import React from 'react'

export const IconInvoice = () => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.4242 19.0001H20.303V22H28.4242C28.984 22 29.4394 22.4487 29.4394 23V28C29.4394 28.5514 28.984 29 28.4242 29H4.06061C3.50088 29 3.04545 28.5514 3.04545 28V23C3.04545 22.45 3.501 22 4.06061 22H12.1818V19.0001H4.06061C1.81776 19.0001 0 20.7907 0 23V28C0 30.2094 1.81776 32 4.06061 32H28.4242C30.6671 32 32.4848 30.2094 32.4848 28V23C32.4848 20.7938 30.6639 19.0001 28.4242 19.0001ZM8.63513 11.0063L14.7197 5.12137V22C14.7197 22.8282 15.4018 23.5 16.2424 23.5C17.0831 23.5 17.7652 22.8282 17.7652 22V5.12137L23.7945 11.0607C24.0908 11.3563 24.4778 11.5001 24.8712 11.5001C25.2646 11.5001 25.6503 11.3536 25.9479 11.0607C26.5427 10.4747 26.5427 9.52566 25.9479 8.93941L17.3191 0.439451C16.7243 -0.146484 15.7609 -0.146484 15.1657 0.439451L6.53694 8.93941C5.94213 9.52535 5.94213 10.4744 6.53694 11.0607C7.13176 11.6469 8.09583 11.6438 8.63513 11.0063ZM27.4091 25.5C27.4091 24.6713 26.727 24 25.8864 24C25.0457 24 24.3636 24.6688 24.3636 25.5C24.3636 26.3281 25.0457 27 25.8864 27C26.727 27 27.4091 26.3313 27.4091 25.5Z'
        fill='#3B454F'
      />
    </svg>
  )
}
