export interface ISkillsTools {
  label: string
  value: string
}
export const skillsTools = [
  { label: 'C#', value: '1' },
  { label: 'Java', value: '2' },
  { label: 'Javascript', value: '3' },
  { label: 'PHP', value: '4' },
  { label: 'Python', value: '5' },
  { label: 'Ruby', value: '6' },
  { label: 'C++', value: '7' },
  { label: 'C', value: '8' },
  { label: 'Go', value: '9' },
  { label: 'Swift', value: '10' },
  { label: 'Kotlin', value: '11' },
  { label: 'Rust', value: '12' },
  { label: 'Typescript', value: '13' },
  { label: 'Delphi', value: '14' },
  { label: 'Assembly', value: '15' },
  { label: 'COBOL', value: '16' },
  { label: 'HTML', value: '17' },
  { label: 'CSS', value: '18' },
  { label: 'SQL', value: '19' },
  { label: 'Bootstrap(HTML e CSS)', value: '20' },
  { label: 'JQuery', value: '21' },
  { label: 'Angular', value: '22' },
  { label: 'React', value: '23' },
  { label: 'Vue', value: '24' },
  { label: 'Node', value: '25' },
  { label: 'Express', value: '26' },
  { label: 'Laravel (PHP)', value: '27' },
  { label: 'Django (Python)', value: '28' },
  { label: 'Ruby on Rails', value: '29' },
  { label: 'Spring (Java)', value: '30' },
  { label: 'Flask (Python)', value: '31' },
  { label: 'ASP.NET Core (C#)', value: '32' },
  { label: 'Codelgniter (PHP)', value: '33' },
  { label: 'MariaDB', value: '34' },
  { label: 'MySQL', value: '35' },
  { label: 'PostgreSQL', value: '36' },
  { label: 'MongoDB', value: '37' },
  { label: 'Redis', value: '38' },
  { label: 'Oracle', value: '39' },
  { label: 'SQL Server', value: '40' },
  { label: 'Firebase', value: '41' },
  { label: 'Docker', value: '42' },
  { label: 'Kubernetes', value: '43' },
  { label: 'AWS', value: '44' },
  { label: 'Azure', value: '45' },
  { label: 'Google Cloud', value: '46' },
  { label: 'Heroku', value: '47' },
  { label: 'Git', value: '48' },
  { label: 'Github', value: '49' },
  { label: 'SVN', value: '50' },
  { label: 'Mercurial', value: '51' },
  { label: 'Jenkins', value: '52' },
  { label: 'Travis CI', value: '53' },
  { label: 'Circle CI', value: '54' },
  { label: 'Jira', value: '55' },
  { label: 'Trello', value: '56' },
  { label: 'Slack', value: '57' },
  { label: 'Discord', value: '58' },
  { label: 'JSON', value: '59' },
  { label: 'Eslint', value: '60' },
  { label: 'Prettier', value: '61' },
  { label: 'Webpack', value: '62' },
  { label: 'Babel', value: '63' }
] as ISkillsTools[]
