import { useContext, useMemo } from 'react'

import { List } from 'contexts'

import { Loading } from 'components/atoms'
import { TableHeader } from 'components/molecules'
import { NotFoundFilter } from 'components/organisms/Filters/NotFoundFilter'

import { LoadingWrapper, Main, NotFoundWrapper } from '../style'
import { GRID_TEMPLATE, HEADERS } from './contants'
import { Shelf } from './shelf'

export const HoursProfessional = () => {
  const { handleOrder, isLoading, releases } = useContext(
    List.ProfessionalHours.Context
  )

  const Table = useMemo(() => {
    if (isLoading)
      return (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )

    if (releases.length === 0) {
      return (
        <NotFoundWrapper>
          <NotFoundFilter text='Nenhum Lançamento não encontrado' />
        </NotFoundWrapper>
      )
    }
    return releases.map((props) => (
      <Shelf
        key={props.user_id}
        config={{
          template: GRID_TEMPLATE,
          options: []
        }}
        {...{ props }}
      />
    ))
  }, [isLoading, releases])

  return (
    <Main>
      <TableHeader
        template={GRID_TEMPLATE}
        headers={HEADERS}
        handleOrder={() => handleOrder()}
      />
      {Table}
    </Main>
  )
}
