import React from 'react'

export const IconServices = ({
  width = '18px',
  height = '16px',
  color = 'black',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.6874 2.91812L9.29971 0.585625C8.91354 0.210687 8.39204 0 7.85134 0H2.04762C0.91663 0 0 0.895313 0 2L0.000207961 14C0.000207961 15.1044 0.916838 16 2.04783 16H10.2381C11.3643 16 12.2857 15.1 12.2857 14V4.33125C12.2857 3.80313 12.0714 3.29375 11.6874 2.91812ZM10.75 14C10.75 14.2761 10.5208 14.5 10.2381 14.5H2.04826C1.7655 14.5 1.53635 14.2761 1.53635 14L1.53571 2.00406C1.53571 1.72794 1.76492 1.50406 2.04762 1.50406H7.16667V4C7.16667 4.55219 7.62514 5 8.19048 5H10.7212V14H10.75ZM3.04199 11.2781C2.97945 11.3392 2.93683 11.417 2.91946 11.5017L2.564 13.2374C2.5327 13.3903 2.67086 13.5253 2.82751 13.4947L4.60478 13.1475C4.69145 13.1305 4.77105 13.089 4.8336 13.0279L7.95686 9.97787L6.16487 8.22756L3.04199 11.2781ZM6.88832 7.52187L8.68031 9.27219L9.46352 8.50719C9.81353 8.16563 9.81353 7.61156 9.46358 7.26969L8.93914 6.75625C8.58944 6.41469 8.02219 6.41469 7.67217 6.75625L6.88832 7.52187Z'
        fill='#3B454F'
      />
    </svg>
  )
}
