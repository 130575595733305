import { ChangeEvent, useContext } from 'react'

import { DatePicker, Select } from '@stardust-ds/react'
import { List } from 'contexts'

import { IconGlass, Inputs } from 'components/atoms'
import { convertDateFormat } from 'components/organisms/Forms/Team/logic'

import { Container, Main } from '../style'
import { Option } from 'types'

export const MonthHours = () => {
  const {
    filterOptions,
    handleDate,
    handleSearch,
    handleStatus,
    meta
  } = useContext(List.MouthHours.Context)

  return (
    <Main>
      <Container gap='1em' justify='flex-end'>
        <Inputs.Default
          placeholder='Buscar...'
          value={meta.search}
          iconLeft={<IconGlass />}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleSearch(e.target.value)
          }
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Poppins, sans-serif'
          }}
        />
        <Select
          width={230}
          options={filterOptions.projects}
          placeholder='Projetos'
          searchable
          onSelect={(opts: Option | null) =>
            opts && handleStatus(opts.value)
          }
          onClear={() => handleStatus(null)}
        />
        <DatePicker
          onChange={(date) =>
            date.length > 0 &&
            handleDate(
              convertDateFormat(String(date[0])),
              convertDateFormat(String(date[1]))
            )
          }
          hasEndDate
          inputStartProps={{
            placeholder: 'Início do período'
          }}
          inputEndProps={{
            placeholder: 'Fim do período'
          }}
          weekDays={['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']}
        />
      </Container>
    </Main>
  )
}
