import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState
} from 'react'

import { Button, Typography } from '@stardust-ds/react'
import { theme } from 'styles'

import Close from 'components/atoms/Buttons/Close'
import { IconTrash } from 'components/atoms/Icons/IconTrash'

import {
  Columns,
  ContainerModal,
  Icon,
  IconContainer,
  Overlay,
  RowButton
} from '../Delete/style'
import { Row, Text } from '../style'

export interface IHandleDeleteLauching {
  open(id: number): void
  close(): void
}

export interface IModalProps {
  action(id: number): void
}

const DeleteLaunching = forwardRef<
  IHandleDeleteLauching,
  IModalProps
>((props, ref) => {
  const [id, setId] = useState<number | null>(null)
  const { action } = props
  const close = useCallback(() => {
    setId(null)
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      open: (id) => setId(id),
      close
    }),
    []
  )

  if (!id) return null

  return (
    <>
      <ContainerModal>
        <Columns>
          <Icon>
            <Close onClick={() => close()} />
          </Icon>
          <IconContainer>
            <IconTrash />
          </IconContainer>
          <Row justify='center'>
            <h2>Excluir Lançamento</h2>
          </Row>
          <Row>
            <Typography
              type='l1'
              fontWeight='normal'
              textAlign='center'
              lineHeight='27px'
            >
              Tem certeza que deseja excluir o lançamento
              <span
                style={{
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  color: 'red'
                }}
              >
                {' '}
                {id}{' '}
              </span>
              do Lançamento de Horas?
            </Typography>
          </Row>
          <RowButton>
            <Button
              style={{ borderRadius: '500px' }}
              bgColor='#E9EBEE'
              labelColor={theme.neutrals.gray7}
              onClick={close}
              width={150}
              height={42}
            >
              Cancelar
            </Button>
            <Button
              style={{
                borderRadius: '500px',
                boxShadow: '0px 5px 10px 0px rgba(255, 53, 65, 0.25)'
              }}
              width={155}
              height={42}
              bgColor='rgba(255, 53, 65, 1)'
              onClick={() => {
                action(id)
                close()
              }}
            >
              Sim, Excluir
            </Button>
          </RowButton>
        </Columns>
      </ContainerModal>
      <Overlay />
    </>
  )
})

export default DeleteLaunching
