import { forwardRef, InputHTMLAttributes } from 'react'

import { Checkbox, Typography } from '@stardust-ds/react'
import type { CheckboxProps } from '@stardust-ds/react'
import { theme } from 'styles'

import { Container, TooltipText, TypographyWrapper } from './style'
import { Main } from './style'

type Props = CheckboxProps &
  InputHTMLAttributes<HTMLInputElement> & {
    label?: string
    tooltip?: string
  }

export default forwardRef(
  ({ label, tooltip, ...props }: Props, ref) => {
    const color = props?.checked
      ? theme.brand.color.status.neutral1
      : theme.neutrals.gray7

    return (
      <Container>
        <Main w={(props?.width as string) ?? '100%'}>
          <Checkbox
            {...props}
            style={{ marginTop: '0.3rem' }}
            ref={ref}
          />
          {label && (
            <TypographyWrapper>
              <Typography type='l5' color={color}>
                {label}
                <TooltipText>{tooltip}</TooltipText>
              </Typography>
            </TypographyWrapper>
          )}
        </Main>
      </Container>
    )
  }
)
