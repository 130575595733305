import Attachment from '../../../../pages/Attachment'
import { Bank } from './bank'
import { Company } from './company'
import { Contract } from './contract'
import { ExtraHours } from './extraHours'
import { Permission } from './permissions'
import { Personal } from './personal'
import { Tolls } from './tolls'

export const Section = Object.assign(
  {},
  {
    Personal,
    Company,
    Contract,
    ExtraHours,
    Bank,
    Permission,
    Attachment,
    Tolls
  }
)
