import { List } from 'contexts'

import { Filter, Table } from 'components/organisms'

const Attachment = () => {
  return (
    <List.Professional.Provider>
      <Filter.Attachment />
      <Table.Attachment />
    </List.Professional.Provider>
  )
}

export default Attachment
