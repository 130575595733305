import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { Flex, Textarea } from '@stardust-ds/react'
import { List } from 'contexts'

import { Inputs, Option, Selects } from 'components/atoms'
import { IconTrash } from 'components/atoms/Icons/IconTrash'

import {
  Container,
  ContainerIcons,
  ContainerInputsAddHours,
  ContainerTitleJustification,
  TextTitle
} from '../style'
import { FormHoursReleaseProps } from '../types'

export const AddHoursToAnotherProject = () => {
  const Context = useFormContext<FormHoursReleaseProps>()
  const { register } = Context
  const {
    handleFormChange,
    deleteFormProject,
    allProjectsOptions,
    jobs_attribution,
    additionalHoursRegistrationForm,
    validateFormError
  } = useContext(List.PostHoursReleases.Context)

  return (
    <>
      {additionalHoursRegistrationForm.map((item, index) => (
        <Container key={item.id}>
          <ContainerInputsAddHours>
            <ContainerIcons>
              <IconTrash
                onClick={(e) => {
                  deleteFormProject(item.id, e)
                }}
              />
            </ContainerIcons>
            <Flex flexDirection='row' gap={16}>
              <Flex align='flex-start' gap={16} width='100%'>
                <Selects.Default
                  {...register('hours_release.project.name')}
                  options={allProjectsOptions as unknown as Option[]}
                  onSelect={(option: any) =>
                    handleFormChange(
                      index,
                      'project_id',
                      option.value
                    )
                  }
                  required
                  clearable={false}
                  width={200}
                  label='Projeto'
                  placeholder={'Selecionar'}
                />

                <Selects.Default
                  {...register('hours_release.job.name')}
                  options={jobs_attribution as unknown as Option[]}
                  onSelect={(option: any) =>
                    handleFormChange(index, 'job_id', option.value)
                  }
                  required
                  clearable={false}
                  width={200}
                  label='Papel'
                  placeholder={'Selecionar'}
                />
                <Inputs.Default
                  value={item?.hour_quantity || ''}
                  error={
                    validateFormError &&
                    item.hour_quantity.toString().length
                      ? validateFormError
                      : ''
                  }
                  onChange={(e: any) =>
                    handleFormChange(
                      index,
                      'hour_quantity',
                      Number(e.target.value)
                    )
                  }
                  required
                  type='number'
                  label='Quantidade de Horas'
                  placeholder='Horas'
                  width={200}
                />

                <Inputs.Default
                  value={item?.date_release || ''}
                  onChange={(e: any) =>
                    handleFormChange(
                      index,
                      'date_release',
                      e.target.value
                    )
                  }
                  required
                  label='Período'
                  type='date'
                  width='210px'
                />
              </Flex>
            </Flex>
          </ContainerInputsAddHours>
          <Flex flexDirection='row'>
            <ContainerTitleJustification>
              <TextTitle>Comentário</TextTitle>
              <Textarea
                style={{ width: '100%', height: '110px' }}
                rows={4}
                maxLength={200}
                value={item?.justification || ''}
                onChange={(e: any) =>
                  handleFormChange(
                    index,
                    'justification',
                    e.target.value
                  )
                }
                width='100%'
                placeholder='Escreva seu comentário aqui...'
              />
            </ContainerTitleJustification>
          </Flex>
        </Container>
      ))}
    </>
  )
}
