import { ChangeEvent, useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Box, Textarea } from '@stardust-ds/react'
import { Release } from 'contexts'

import { Button } from 'components/atoms'

import { Dividers } from './Dividers'

export interface IFormProps {
  hour_quantity: number
  justification: string
  date_release: string
}

export const EditHours = (data: IFormProps) => {
  const { id } = useParams()
  const methods = useForm<IFormProps>({
    defaultValues: data
  })
  const { handleEditHours } = useContext(Release.LaunchHours.Context)
  const { watch, setValue } = methods
  const { date_release, hour_quantity, justification } = watch()
  return (
    <FormProvider {...methods}>
      <Box
        maxWidth='70%'
        minWidth='70%'
        height='auto'
        bWidth='hairline'
        bRadius='sm'
        bColor='#CCD1D6'
        display='flex'
        flexDirection='column'
        gap={16}
        py='xxxs'
        px='xxxs'
      >
        <Dividers />
        <Textarea
          value={watch('justification')}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setValue('justification', e.target.value)
          }
          label='Comentário'
          bColor='#ACB4BA'
          placeholder='Justificativa...'
        />
        <Button.Updade
          bottom='0'
          onCancel={() => window.history.back()}
          onSave={() =>
            handleEditHours(Number(id), {
              hour_quantity,
              justification,
              date_release
            })
          }
          saveButtonName='Salvar'
          cancelButtonName='Cancelar'
        />
      </Box>
    </FormProvider>
  )
}
