import { Badge } from 'components/atoms'
import { Popover } from 'components/molecules'
import {
  TeamJobName,
  TextJob
} from 'components/organisms/Forms/Project/style'
import { formatDate } from 'components/utils/formatDate'

import { ContainerShelf, ContainerShelfColumn, Text } from '../style'
import { ContainerPopover } from '../Team/style'
import { ShelfProps } from '../types'
import { ProjectPropsHours } from './types'

export const Shelf = ({
  props,
  config
}: ShelfProps<ProjectPropsHours>) => {
  const {
    name,
    hours_mounths_estimated,
    date_start_allocation,
    date_end_allocation,
    job_attribution,
    status
  } = props

  return (
    <ContainerShelf template={config.template}>
      <ContainerShelfColumn justify='start'>
        <TeamJobName>
          <Text>{name}</Text>
          <TextJob>{job_attribution}</TextJob>
        </TeamJobName>
      </ContainerShelfColumn>

      <ContainerShelfColumn justify='start' left='0.7em'>
        <Text>{hours_mounths_estimated}</Text>
      </ContainerShelfColumn>

      <ContainerShelfColumn justify='start' left='0.5em'>
        <Text>{formatDate(date_start_allocation)}</Text>
      </ContainerShelfColumn>

      <ContainerShelfColumn justify='start' left='0.5em'>
        <Text>{formatDate(date_end_allocation)}</Text>
      </ContainerShelfColumn>
      <ContainerShelfColumn justify='start' right='-1.5em' gap='1em'>
        <Badge.Status status={status} />
        <ContainerShelfColumn>
          <Popover options={config.options} />
        </ContainerShelfColumn>
      </ContainerShelfColumn>
    </ContainerShelf>
  )
}
