import { useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { List } from 'contexts'

import { TableHeader } from 'components/molecules'
import { FormProps } from 'components/organisms/Forms/Professional/types'

import { Main } from '../style'
import { GRID_TEMPLATE, HEADERS } from './constants'
import { Shelf } from './shelf'
import { ShelfSome } from './shelfSome'

export const Attachment = () => {
  const methods = useFormContext<FormProps>()
  const { projectsUser, deleteBindProject } = useContext(
    List.Professional.Context
  )
  const { id: user_id } = useParams()
  const attachmentForm = methods.watch('projects.attachment', [])

  const attachmentProject = projectsUser.filter(
    (project) => project.id
  )
  const user_projects_id = attachmentProject?.[0]?.user_projects_id

  const attachment = user_id ? projectsUser : attachmentForm

  const POPOVER_OPTIONS = (id: number) => [
    {
      label: 'Excluir',
      callback: () => {
        user_id
          ? deleteBindProject(
              Number(user_id),
              Number(user_projects_id),
              Number(id)
            )
          : methods.setValue('projects.attachment', [
              ...attachment.filter((item) => item.id !== id)
            ])
      }
    }
  ]

  const Table = useMemo(() => {
    return (
      <>
        {attachment.map((props) => (
          <Shelf
            key={props.id}
            config={{
              template: GRID_TEMPLATE,
              options: POPOVER_OPTIONS(props.id)
            }}
            {...{ props }}
          />
        ))}
        {attachment.length > 0 && (
          <ShelfSome
            config={{
              template: GRID_TEMPLATE,
              options: []
            }}
            {...{
              props: {
                name: 'Total horas/mês',
                hours_mounths_estimated: attachment.reduce(
                  (acc, cur) => acc + cur.hours_mounths_estimated,
                  0
                )
              }
            }}
          />
        )}
      </>
    )
  }, [attachment])

  return (
    <Main>
      <TableHeader
        headers={HEADERS}
        template={GRID_TEMPLATE}
        handleOrder={() => {}}
      />
      {Table}
    </Main>
  )
}
