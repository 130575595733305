import styled from 'styled-components'
import { theme } from 'styles'

export const ContainerModal = styled.div`
  width: 610px;
  height: 629px;
  border-radius: 16px;
  background-color: ${theme.neutrals.pureWhite};
  position: absolute;
  display: flex;
  padding-top: 1em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  box-shadow: 0px 5px 10px 10px ${theme.neutrals.gray3};
`
export const Columns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Row = styled.div`
  display: flex;
  height: 3em;
  align-items: center;
  flex-direction: row;
  margin: auto;
  width: 92%;
  justify-content: space-between;

  h2 {
    color: ${theme.neutrals.gray8};
  }
`
export const RowButtons = styled.div`
  width: 563px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: auto;
  justify-content: space-between;
`
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 5;
`

export const ContainerAbsolute = styled.div`
  width: 563px;
  height: 386px;
  margin-left: 1.7em;
  margin-bottom: 1em;
`
export const ContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const ContainerFooter = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 2em;
`
export const Footer = styled.div`
  display: flex;
  height: 22.7px;
`

export const ContainerWap = styled.div`
  width: calc(50% - 1px);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: 0.5px solid #ccc;
  box-sizing: border-box;
  height: 56px;
  margin-right: -0.5em;
  margin-top: -1.8px;

  &:not&:not(:first-child) {
    border-left: none;
  }

  &:nth-child(even) {
    border-left: none;
  }

  &:nth-child(odd) {
    border-top: none;
  }

  &:nth-child(even) {
    border-top: none;
  }

  &:first-child,
  &:nth-child(2) {
    border-top: 1px solid #ccc;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-child,
  &:nth-last-child(2) {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`

export const ContainerLabelProfessional = styled.div`
  width: 90%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 0.5em;
`

export const IconButton = styled.div`
  svg {
    cursor: pointer;
  }
`

export const ContainerSome = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  p {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: rgba(59, 69, 79, 1);
  }
`
