import styled from 'styled-components'
import { theme } from 'styles'

import { IContainerRowProps } from 'types'

export const Main = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
  border: 1px solid ${theme.neutrals.gray3};
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: ${theme.neutrals.gray6};

  h3 {
    color: ${theme.neutrals.gray9};
  }
`

export const ContainerRow = styled.div<IContainerRowProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: ${({ align }) => align ?? 'flex-start'};
  gap: 2%;
  white-space: nowrap;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 96%;
  gap: 2%;
`

export const ColumnContainer = styled.div`
  width: 48%;
  gap: 1rem;
  display: grid;
  padding-left: 1.5rem;
`

export const Divider = styled.hr`
  border-top: 1px solid ${theme.neutrals.gray2};
  width: 100%;
  margin: 2% 0rem;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const ContainerScroll = styled.div`
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: ${theme.neutrals.gray1};
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.neutrals.gray3};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.neutrals.gray4};
  }
`

export const ContainerInputs = styled.div`
  width: 100%;
  height: auto;
  background-color: ${theme.neutrals.gray1};
  border-radius: 8px;
  padding: 1.5em;
  border: 1px solid ${theme.neutrals.gray3};
  border-radius: 8px;
`
export const ContainerInputsAddHours = styled.div`
  width: 100%;
  height: 115px;
  background-color: ${theme.neutrals.gray1};
  border-radius: 8px;
  padding: 0 1.5em 1.5em 1.5em;
  border: 1px solid ${theme.neutrals.gray3};
  border-radius: 8px;
`
export const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 7px;
  margin-left: 1rem;
  cursor: pointer;
`

export const ContainerTitleJustification = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
`
export const TextTitle = styled.h4`
  font-family: 'Poppins';
`
