import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { SelectOption } from '@stardust-ds/react'
import { List } from 'contexts'

import { Inputs, Selects } from 'components/atoms'
import { ButtonGeneric } from 'components/atoms/ButtonGeneric'

import { FormProps } from '../Professional'
import { ContainerRow } from '../Professional/style'
import {
  clearInputFields,
  handleAddMockProject,
  verifyValues
} from './logic'
import { Options, ProfessionalSelectProps } from './types'
import { Option } from './types'

export const Attachment = () => {
  const Context = useFormContext<FormProps>()
  const { watch, setValue, register, ...methods } = Context
  const { bindUserAtProject } = useContext(List.Professional.Context)
  const { errors } = methods.formState
  const { id } = useParams()
  const selectOptions = watch('options')

  const contractType = watch('job_type') as Options & Option

  let contractTypeInput

  if (contractType?.label === 'FULLTIME') {
    contractTypeInput = (
      <Inputs.Default
        {...register('projects.selected.fullTime')}
        label='Horas/mês estimadas'
        type='number'
        error={errors.projects?.selected?.fullTime?.message}
        placeholder='Horas/mês'
        width={200}
      />
    )
  } else if (contractType?.label === 'PARTTIME') {
    contractTypeInput = (
      <Inputs.Default
        {...register('projects.selected.partTime')}
        label='Horas/mês estimadas'
        type='number'
        error={errors.projects?.selected?.partTime?.message}
        placeholder='Horas/mês'
        width={200}
      />
    )
  } else {
    contractTypeInput = (
      <Inputs.Default
        {...register('projects.selected.fullTime')}
        label='Horas/mês estimadas'
        type='number'
        error={errors.projects?.selected?.fullTime?.message}
        placeholder='Horas/mês'
        width={200}
      />
    )
  }

  const handleAddProject = () => {
    if (verifyValues(Context)) {
      const monthHours =
        watch('projects.selected.fullTime') ||
        watch('projects.selected.partTime')
      const date = watch('projects.selected.input')
      const project = watch(
        'projects.selected.project'
      ) as ProfessionalSelectProps & Option
      const projectid = project.label.value.id
      const status = watch('is_active') as boolean
      const job = watch('job_attribution') as unknown as Options
      const job_attribution = job?.label
      const extra_hours_estimated = 0
      const extra_hours_performed = 0
      const hours_mounths_performed = 0

      if (!id) return null

      const payload = {
        id: Number(projectid),
        name: project.label.value.name,
        date_start: '',
        hours_mounths_estimated: parseInt(monthHours),
        extra_hours_estimated: extra_hours_estimated,
        extra_hours_performed: extra_hours_performed,
        hours_mounths_performed: hours_mounths_performed,
        date_start_allocation: date,
        date_end_allocation: '',
        job_attribution: job_attribution,
        status: status
      }
      bindUserAtProject(Number(id), payload)
    }
    clearInputFields(Context)
  }

  return (
    <>
      <ContainerRow>
        <h3>Vincular Projetos</h3>
      </ContainerRow>
      <ContainerRow gap='1rem'>
        <Selects.Default
          value={watch('projects.selected.project.label') as any}
          onSelect={(value: any) =>
            setValue('projects.selected.project.label', value)
          }
          onClear={() => setValue('projects.selected.project', null)}
          options={selectOptions?.projects as SelectOption[]}
          label='Projeto'
          style={{ height: '41px' }}
          placeholder='Selecione'
          width={200}
        />
        <Selects.Default
          value={watch('job_attribution') as any}
          onSelect={(item: any) =>
            item && setValue('job_attribution', item)
          }
          onClear={() =>
            setValue('job_attribution', null as unknown as string)
          }
          options={selectOptions?.jobs as SelectOption[]}
          label='Cargo'
          width={190}
          placeholder='Selecione'
        />

        {contractTypeInput}

        <Inputs.Default
          {...register('projects.selected.input')}
          label='Inicio de alocação'
          type='date'
        />
        <ButtonGeneric
          top='1.5em'
          Text='Vincular'
          bgColor='#0D2551'
          color='white'
          width='290px'
          bRadius='500px'
          height='3.3em'
          type='button'
          onClick={() => {
            Number(id)
              ? handleAddProject()
              : handleAddMockProject(Context)
          }}
        />
      </ContainerRow>
    </>
  )
}
