import { useState } from 'react'

export const OrderBy = () => {
  const [ascendente, setAscendente] = useState(true)

  const toggleOrder = () => {
    setAscendente(!ascendente)
  }

  return (
    <div onClick={toggleOrder}>
      <svg
        width='12'
        height='18'
        viewBox='0 0 12 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_2309_14)'>
          <path
            d='M10.1357 10.1423H1.86428C1.09558 10.1423 0.711581 11.2058 1.25369 11.825L5.38781 16.7105C5.55674 16.9035 5.77748 17 5.99809 17C6.21855 17 6.43838 16.9035 6.60587 16.7105L10.7463 11.8243C11.2884 11.2065 10.9045 10.1423 10.1357 10.1423ZM1.86615 7.8567H10.1357C10.9044 7.8567 11.2884 6.79357 10.7462 6.17397L6.60587 1.28857C6.44088 1.09637 6.24714 0.999971 6.00028 0.999971C5.77988 0.999971 5.55905 1.09647 5.39 1.28957L1.25588 6.17497C0.713081 6.79247 1.09745 7.8567 1.86615 7.8567Z'
            fill={
              ascendente
                ? 'rgba(0, 102, 255, 0.7)'
                : 'rgba(179, 185 ,184, 0.7)'
            }
          />
        </g>

        <g clip-path='url(#clip1_2309_14)'>
          <path
            d='M1.86435 7.85772H10.1357C10.9044 7.85772 11.2884 6.79422 10.7463 6.17497L6.61219 1.28955C6.44326 1.09649 6.22252 1.00003 6.00191 1.00003C5.78145 1.00003 5.56162 1.09649 5.39413 1.28955L1.25373 6.17568C0.711634 6.7935 1.09549 7.85772 1.86435 7.85772ZM10.1338 10.1433H1.86435C1.09564 10.1433 0.711603 11.2064 1.25376 11.826L5.39413 16.7114C5.55912 16.9036 5.75286 17 5.99972 17C6.22012 17 6.44095 16.9035 6.61 16.7104L10.7441 11.825C11.2869 11.2075 10.9025 10.1433 10.1338 10.1433Z'
            fill={
              ascendente
                ? 'rgba(179, 185 ,184, 0.7)'
                : 'rgba(0, 102, 255, 0.7)'
            }
          />
        </g>

        <defs>
          <clipPath id='clip0_2309_14'>
            <rect width='12' height='9' fill='white' />
          </clipPath>

          <clipPath id='clip1_2309_14'>
            <rect
              width='12'
              height='8'
              fill='white'
              transform='matrix(-1 0 0 -1 12 18)'
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
