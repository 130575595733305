// função que retorna a data da segunda feira da semana atual para a data de lançamento de horas do colaborador
export function getCurrentMondayFromDate(
  date_release: string | number | Date
) {
  const dateRegister = new Date(date_release)

  const differenceDays = dateRegister.getDay() - 0
  dateRegister.setDate(dateRegister.getDate() - differenceDays)

  return dateRegister
}
