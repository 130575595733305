/* eslint-disable prettier/prettier */
import { createContext, useState } from 'react'
import { ReactNode } from 'react'

import { toast } from '@stardust-ds/react';

import { PaginateContext } from 'components/molecules'

import api from 'api'
import { routes } from 'routes'

import { useDebounce } from 'hooks'

import DEFAULT from './contants'
import { ContextMouthProps, MouthHoursProps } from './types'

const Context = createContext({} as ContextMouthProps)

const Provider = ({ children }: { children: ReactNode }) => {
    const [payload, setPayload] = useState<MouthHoursProps[]>([])
    const [meta, setMeta] = useState(DEFAULT.META)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [filterOptions, setFilterOptions] = useState(
        DEFAULT.FILTER_OPTIONS
    )

    const contextProps = {
        meta,
        payload,
        isLoading,
        filterOptions,
        paginate: { ...meta.pagination, setCurrent_page: setPage },
        handleSearch,
        handleOrder,
        handleDate,
        handleStatus,
        handleDelete
    }

    async function fetchList() {
        setIsLoading(true)
        try {
            const { data } = await api.get(routes.hours.Mouth.listAdmin, {
                params: {
                    page: meta.pagination.current_page,
                    project_id: meta.project_id,
                    order: meta.order,
                    orderField: meta.orderField,

                }
            })
            setPayload(data.data)
            setMeta((old) => ({
                ...old,
                pagination: { ...old.pagination, last_page: data.meta.last_page }
            }))
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    async function fetchFilter() {
        try {
            const { data } = await api.get(routes.extraHoursRH.listProject)

            setFilterOptions({
                projects: data.data.map(
                    ({ name, id }: { name: string; id: number }) => ({
                        label: name,
                        value: id
                    })
                )
            })
        } catch (error) {
            if (error instanceof Error && error.message) {
                const errorMessage = error.message
                toast({
                    type: 'error',
                    title: 'Erro',
                    description: errorMessage,
                    position: 'bottom-right'
                })
            } else {
                toast({
                    type: 'error',
                    title: 'Erro',
                    description: 'Erro desconhecido ao obter opções de filtro de projetos',
                    position: 'bottom-right'
                })
            }
        }
    }

    function setPage(page: number) {
        setMeta((old) => ({
            ...old,
            pagination: { ...old.pagination,current_page: page }
        }))
    }

    function handleSearch(text: string) {
        setMeta((old) => ({
            ...old,
            search: text,
            pagination: { ...old.pagination, current_page: 1 }
        }))
    }

    function handleOrder(field: string) {
        setMeta((old) => ({
            ...old,
            order: old.order === 'ASC' ? 'DESC' : 'ASC',
            orderField: field,
            pagination: { ...old.pagination, current_page: 1 }
        }))
    }

    function handleDate(start: string, end: string) {
        setMeta((old) => ({
            ...old,
            launch_date: start,
            end_date: end,
            pagination: { ...old.pagination, current_page: 1 }
        }))
    }

    function handleStatus(status: string) {
        setMeta((old) => ({
            ...old,
            project_id: Number(status),
            pagination: { ...old.pagination, current_page: 1 }
        }))
    }

    async function handleDelete(id: number) {
        try {
            await api.delete(routes.hours.Mouth.delete(id))
            return fetchList()
        } catch (error) {
            console.error(error)
        }
    }

    useDebounce({
        fn: fetchList,
        listener: [
            meta.end_date,
            meta.launch_date,
            meta.order,
            meta.orderField,
            meta.pagination.current_page,
            meta.project_id,
            meta.search
        ]
    })

    useDebounce({
        fn: fetchFilter,
        listener: []
    })

    return (
        <Context.Provider value={contextProps}>
            <PaginateContext.Provider value={{ paginate: contextProps.paginate }}>
                {children}
            </PaginateContext.Provider>
        </Context.Provider>
    )
}

export { Provider, Context }