import React from 'react'

import { List } from 'contexts'

import RegisterHoursReleasesWrap from './wrap'

const RegisterHoursReleases = () => {
  return (
    <List.PostHoursReleases.Provider>
      <RegisterHoursReleasesWrap />
    </List.PostHoursReleases.Provider>
  )
}

export default RegisterHoursReleases
