import { useNavigate } from 'react-router-dom'

import { List } from 'contexts'

import { Filter, Table } from 'components/organisms'
import { AuthTemplate, ReleaseTemplate } from 'components/templates'

const PostHoursReleases = () => {
  const navigate = useNavigate()
  return (
    <AuthTemplate>
      <List.PostHoursReleases.Provider>
        <ReleaseTemplate
          title='Lançamento de Horas'
          btnText='Novo Lançamento'
          event={() => navigate('/postHoursReleases/new')}
        >
          <Filter.PostHoursReleases />
          <Table.PostHoursReleases />
        </ReleaseTemplate>
      </List.PostHoursReleases.Provider>
    </AuthTemplate>
  )
}

export default PostHoursReleases
