import React from 'react'

const IconLoginTop = ({
  width = '304px',
  height = '231px',
  color = 'black',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 304 231'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M96.57 -85.8125C62.3984 -95.3805 31.4105 -116.218 -3.82237 -124.935C-44.892 -135.035 -92.2229 -126.636 -121.513 -96.1247C-150.909 -65.6133 -155.579 -11.6071 -125.97 18.6918C-106.868 38.1468 -75.5617 48.2464 -67.9208 74.5054C-62.4024 93.3226 -71.8472 112.99 -74.0758 132.552C-78.2146 167.635 -57.2023 203.462 -26.0021 219.834C5.19814 236.206 44.5696 233.973 75.5575 217.069C110.048 198.359 134.987 163.276 172.13 150.731C200.252 141.163 235.591 145.841 261.273 129.043C286.424 112.565 300.751 73.8678 303.404 45.4825C309.559 -20.2181 267.641 -91.3408 196.538 -87.9388C176.905 -86.982 157.591 -82.3043 137.958 -80.9222C123.419 -80.0717 109.729 -82.0916 96.57 -85.8125Z'
        fill='#0D2551'
      />
    </svg>
  )
}

export default IconLoginTop
